import { createRef, useEffect } from 'react';

const OutsideClickHandler = (props) => {
  const handleClickOutside = (e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      props.setIsOpen(false);
    }
  };

  const wrapperRef = createRef();
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
  });
  return <div ref={wrapperRef}>{props.children}</div>;
};

export default OutsideClickHandler;
