import { useQuery } from 'react-query';
import { useCallback, useEffect, useState } from 'react';
import Loading from '../../SharedComponents/Loading';
import { getCategories, getLanguages } from './ApiCategories';
import AddCategory from './AddCategory';
import DeleteCategory from './DeleteCategory';
import EditCategory from './EditCategory';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import TablePagination from '../../SharedComponents/TablePagination';
import TableSearch from '../../SharedComponents/TableSearch';
import LanguageList from '../../SharedComponents/LanguageList';
import TogglePrimary from './TogglePrimary';
import { TableWithSub } from '../../SharedComponents/Table/Table';
import { MinusIcon, PlusSmIcon } from '@heroicons/react/solid';
import AddSubCategory from './AddSubCategory';
import SubCategoriesTable from './SubCategoriesTable';

export default function Categories() {
  const [entries, setEntries] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [languageId, setLanguageId] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchLanguage, setSearchlanguage] = useState(0);
  const { t, i18n } = useTranslation();
  const onSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    setSearchlanguage(languageId);
  };
  // Query Data To Fetch Languages
  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });

  useEffect(() => {
    setLanguageId(languages.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [languages.data]);

  let initialState = {
    pageNumber: pageNumber,
    pageSize: entries,
    isPaging: true,
    langId: 0,
    isSearch: searchTerm ? true : false,
    searchFilter: {
      langId: searchLanguage,
      term: searchTerm,
    },
  };
  const { data, isLoading, isError } = useQuery(['categories', initialState], getCategories, {
    staleTime: 99900,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {
      pagination(data);
    },
  });
  const [paginationCount, setPaginationCount] = useState([]);

  function pagination(data) {
    let paginationCount = [];
    for (var i = 1; i <= data.paginationResult.numberOfPages; i++) {
      paginationCount.push(i);
    }
    setPaginationCount(paginationCount);
  }

  // to control how many rows to show in table
  const onChangeEntries = (e) => {
    setEntries(e.target.value);
  };

  const tabltHeader = [{ title: 'Title' }, { title: 'Options', className: 'w-64' }];
  const dataColumns = [
    {
      // Make an expander cell
      Header: () => null, // No header
      id: 'expander', // It needs an ID
      Cell: ({ row }) => (
        // Use Cell to render an expander for each row.
        // We can use the getToggleRowExpandedProps prop-getter
        // to build the expander.
        <span className=" grid justify-center items-center " {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? (
            <MinusIcon className="h-4 bg-red-500  text-white" />
          ) : (
            <PlusSmIcon className="h-4 bg-green-500  text-white" />
          )}
        </span>
      ),
    },
    {
      Header: t('Title'),
      accessor: 'pgPageCategoryLngs',
      Cell: ({ row, cell }) => {
        return (
          <div className="">
            {cell.value.map((x) => {
              return <span key={x.id}>{languageId === x.languageId && <> {x.name} </>}</span>;
            })}
          </div>
        );
      },
    },
    {
      Header: t('Options'),
      accessor: 'options',
      Cell: ({ row, cell }) => {
        return (
          <div
            className={`whitespace-nowrap flex items-center space-x-2 ${
              i18n.language === 'en' ? '' : 'space-x-reverse'
            }`}
          >
            {/* <EditRole id={user.id} name={user.name} /> */}
            {/* <DeleteRole id={user.id} /> */}
            <TogglePrimary category={row.original} />
            <EditCategory category={row.original} />
            <DeleteCategory id={row.original.id} />
            <AddSubCategory parentId={row.original.id} />
          </div>
        );
      },
    },
  ];

  const renderRowSubComponent = useCallback(({ row }) => <SubCategoriesTable languages={languages} row={row} />, []);
  return (
    <>
      <header className="flex justify-between items-center">
        <BreadCrumbs to="/categories" name={t('Categories')} />
        <div>
          <AddCategory />
        </div>
      </header>
      <div className="my-6">
        <h2 className="text-2xl font-bold">{t('Categories List')}</h2>
      </div>
      {/* Table */}
      <div className="bg-white rounded border shadow-md">
        <div className="flex justify-between relative items-center px-6 py-6">
          <TableSearch onChangeEntries={onChangeEntries} entries={entries} onSearch={onSearch} />
          <div className="flex transform  relative z-10 ">
            <LanguageList languages={languages.data?.data} languageId={languageId} setLanguageId={setLanguageId} />
          </div>
          <hr className="border w-full absolute top-0 left-0 z-0 mt-16" />
        </div>
        {isLoading && (
          <div className="relative bg-white h-72">
            <Loading />
          </div>
        )}
        {isError && 'loading'}
        {data && (
          <>
            <TableWithSub renderRowSubComponent={renderRowSubComponent} columns={dataColumns} data={data?.data} />
            <TablePagination
              data={data}
              paginationCount={paginationCount}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          </>
        )}
      </div>
    </>
  );
}
