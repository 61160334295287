import axios from 'axios';
import { DateTime } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';

import userIconBgWhite from '../../assets/img/userIconBgWhite.png';
import userIconBgGray from '../../assets/img/userIconBgGray.png';
import RecordAudio from '../../SharedComponents/custom/RecordAudio';
import SubmitForm from './components/SubmitForm';
import { DownloadIcon } from '@heroicons/react/solid';
export default function ConsultingSpecialistSingleMessage(props) {
  const { specialistId, userId } = useParams();
  const messagesEndRef = useRef(null);
  const [messageReload, setMessageReload] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  };
  useEffect(() => {
    scrollToBottom();
  }, [messageReload]);
  let initialState = {
    userId: userId,
    specialistId: specialistId,
    readDatestring: DateTime.now().toISODate(),
  };
  const { data, isLoading, isError } = useQuery(['ConsultingSpecialist', initialState], queryFunction, {
    // staleTime: 99900,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {
      setMessageReload(!messageReload);
      // pagination(data);
    },
  });

  if (isLoading) return '';
  if (isError) return '';
  return (
    <div>
      <div className="bg-white flex space-x-2 rtl:space-x-reverse items-center font-bold text-2xl py-5 px-6 ">
        <img src={userIconBgWhite} alt="userIconBgWhite" />
        <div className="">{data.data[0].userName}</div>
      </div>
      <div
        className="bg-gray-100   flex flex-col px-5 py-6 w-full overflow-x-hidden overflow-y-auto"
        style={{ height: '600px' }}
      >
        {data.data
          .sort((a, b) => a.id - b.id)
          .map((e, index) => {
            if (e.isSpecialist === true) {
              return <Me e={e} key={index} />;
            } else {
              return <Them e={e} key={index} />;
            }
          })}
        {/* {connection && <SocketMessages connection={connection} />} */}
        <div style={{ float: 'left', clear: 'both' }} ref={messagesEndRef}></div>
      </div>
      <SubmitForm />
    </div>
  );
}

const Me = (props) => {
  const { e } = props;
  const fileType = e.message.split('.').pop();
  return (
    <div className="w-full flex justify-start">
      <div className="flex items-start justify-start  pb-9 space-x-4 max-w-[250px] lg:max-w-[428px] min-w-[250px] lg:min-w-[428px] self-end flex-row-reverse ">
        <div className="w-full ">
          <div className="border-[#dfdfe0]  justify-between px-4 py-3 w-full break-words bg-white border rounded-md ">
            {e.isVoiceChat === false && e.isImage === false && e.isFile === false && e.message}
            {e.isVoiceChat && <audio className="h-8" controls src={`${window.env.REACT_APP_IMAGE_PATH}${e.message}`} />}
            {e.isImage && (
              <img className=" max-h-32 " alt="img" src={`${window.env.REACT_APP_IMAGE_PATH}${e.message}`} />
            )}
            {e.isFile && (
              <a
                className="flex space-x-2 justify-between items-center"
                href={`${window.env.REACT_APP_IMAGE_PATH}${e.message}`}
                target="_blank"
                download
                rel="noreferrer"
              >
                <span className="font-bold">
                  {fileType === 'docx' && 'Word'}
                  {fileType === 'xlsx' && 'Excel'}
                  {fileType === 'zip' && 'Zip'}
                  {fileType === 'pdf' && 'Pdf'}.{fileType}
                </span>
                <DownloadIcon className="h-6 bg-gray-200 rounded-md p-1 text-gray-700" />
              </a>
            )}{' '}
          </div>
          <div className="flex justify-between px-4 ">
            <div>{e.createdByName}</div>
            <div className="text-xs mt-2">
              {DateTime.fromISO(e.sendDate).setLocale('en-uk').toLocaleString(DateTime.DATETIME_SHORT)}
            </div>
          </div>
        </div>
        <div className="flex-col items-center justify-center">
          <img src={userIconBgGray} alt="userIconBgGray" />

          {/* <h4 className='mt-2 text-center text-xs font-medium'>12:00</h4> */}
        </div>
      </div>
    </div>
  );
};

const Them = (props) => {
  const { e } = props;
  const fileType = e.message.split('.').pop();

  return (
    <div className="w-full flex justify-end">
      <div className="flex items-start justify-start  pb-9 space-x-4 max-w-[250px] lg:max-w-[428px] min-w-[250px] lg:min-w-[428px] self-end flex-row-reverse ">
        <div className="flex-col items-center justify-center">
          <img src={userIconBgGray} alt="userIconBgGray" />

          {/* <h4 className='mt-2 text-center text-xs font-medium'>12:00</h4> */}
        </div>
        <div className="w-full">
          <div className="border-[#dfdfe0]  justify-between px-4 py-3 w-full break-words bg-white border rounded-md ">
            {e.isVoiceChat === false && e.isImage === false && e.isFile === false && e.message}
            {e.isVoiceChat && <audio className="h-8" controls src={`${window.env.REACT_APP_IMAGE_PATH}${e.message}`} />}
            {e.isImage && (
              <img className=" max-h-32 " alt="img" src={`${window.env.REACT_APP_IMAGE_PATH}${e.message}`} />
            )}
            {e.isFile && (
              <a
                className="flex space-x-2 justify-between items-center"
                href={`${window.env.REACT_APP_IMAGE_PATH}${e.message}`}
                target="_blank"
                download
                rel="noreferrer"
              >
                <span className="font-bold">
                  {fileType === 'docx' && 'Word'}
                  {fileType === 'xlsx' && 'Excel'}
                  {fileType === 'zip' && 'Zip'}
                  {fileType === 'pdf' && 'Pdf'}.{fileType}
                </span>
                <DownloadIcon className="h-6 bg-gray-200 rounded-md p-1 text-gray-700" />
              </a>
            )}{' '}
          </div>
          <div className="flex justify-between px-4 ">
            <div>
              <span className="font-bold text-xs text-[#26365e]">{e.createdByName}</span>
            </div>
            <div className="text-xs mt-2">
              {DateTime.fromISO(e.sendDate).setLocale('en-uk').toLocaleString(DateTime.DATETIME_SHORT)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const queryFunction = async (e) => {
  const query = await axios.post('ConsultingMessageUpdated/Get', e.queryKey[1]);
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};
