import axios from 'axios';
import React, { useState } from 'react';
import { useMutation } from 'react-query';

export default function SetLiveSpeaker(props) {
  const { speaker } = props;
  const [activeToggle, setActiveToggle] = useState(speaker.isLive ? speaker.isLive : false);

  const editSpeakerMutation = useMutation(editSpeaker, {
    onSuccess: async (data) => {
      if (data.isDone) {
        setActiveToggle((e) => !e);
      }
    },
  });

  const handleClick = () => {
    editSpeakerMutation.mutate({
      ...speaker,
      isLive: !activeToggle,
    });
  };
  return (
    <button
      type="button"
      onClick={handleClick}
      className="flex items-center cursor-pointer focus:outline-none  z-20 top-0 right-0"
    >
      {/* <!-- toggle --> */}
      <div className="relative">
        {/* <!-- line --> */}
        <div className={` w-12 h-6 ${activeToggle ? 'bg-lightgreen' : 'bg-gray-400'}  rounded-full shadow-inner`}></div>
        {/* <!-- dot --> */}
        <div
          className={`transition-all ease-in-out duration-700 absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 ${
            activeToggle ? 'right-0' : 'left-0'
          } `}
        ></div>
      </div>
      {/* <!-- label --> */}
    </button>
  );
}

export const editSpeaker = async (e) => {
  const { data } = await axios.post('/Talkers/Edit', e);
  return data;
};
