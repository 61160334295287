import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import Notification from '../../../../SharedComponents/Notification';
import { addSliderItem } from './ApiSlider';

export default function DuplicateSlider(props) {
  const { values, pgSliderItemIndex } = props;
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  // Mutate Data to Create New Page
  const addSliderItemMutation = useMutation(addSliderItem, {
    onSuccess: async (data) => {
      if (data.isDone) {
        // queryClient.invalidateQueries(['sections']);
        toast(<Notification message={t('Slider Item Duplicated')} />);
        queryClient.invalidateQueries(['sections']);
      }
      queryClient.invalidateQueries(['sections']);
    },
  });


  const onSubmit = () => {
    delete values.id;
    values.orderNum = pgSliderItemIndex + 1;
    const pgSliderItemLng = values.pgSliderItemLng.map((e) => {
      delete e.pgSliderItemId;
      delete e.id;
      return e;
    });
    addSliderItemMutation.mutate({ ...values, pgSliderItemLng });
    console.log('onSubmit', values);
  };
  return (
    <button
      onClick={onSubmit}
      type="button"
      className={`bg-darkblue space-x-1 ${
        i18n.language === 'en' ? '' : 'space-x-reverse'
      } text-sm font-bold text-white flex items-center hover:bg-darkbluehover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
    >
      {t('Duplicate')}
    </button>
  );
}
