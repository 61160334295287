import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import Modal from '../../../SharedComponents/Modal';
import PecnilAlt from '../../../SharedComponents/Svg/PencilAlt';
import { editRole } from './ApiRoles';

export default function EditRole(props) {
  const { id, name } = props;
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const { mutate, data } = useMutation(editRole, {
    onSuccess: () => {
      queryClient.invalidateQueries(['roles']);
      setIsOpen(false);
    },
  });
  const onSubmit = async (e) => {
    try {
      const newData = {
        id: id,
        ...e,
      };
      await mutate(newData);
      if (data?.isDone) {
        setIsOpen(false);
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <>
      <button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className="bg-green-100 text-green-800 hover:bg-green-200  p-1 rounded transition duration-300 ease-in-out"
      >
        <PecnilAlt className="w-5 h-5" />
      </button>
      <Formik initialValues={{ roleName: name, id: id }} onSubmit={onSubmit}>
        <Form>
          <Modal isOpen={isOpen} setIsOpen={setIsOpen} title={t('Edit Role Name')} add={true} onSubmit={onSubmit}>
            <label>
              <b className={`font-semibold block text-gray-900 ${i18n.language === 'en' ? '' : 'text-right'}`}>
                {t('Name')}
              </b>
              <Field
                style={{ textIndent: '10px' }}
                placeholder={t('EnterName')}
                className="border rounded  w-full mb-8 p-1"
                name="roleName"
                type="text"
              />
            </label>
          </Modal>
        </Form>
      </Formik>
    </>
  );
}
