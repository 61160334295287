import axios from 'axios';

export const deleteModal = async (e) => {
  const { data } = await axios.post('/PgSection/DeleteModal', e);
  return data;
};

export const editModal = async (e) => {
  const { data } = await axios.post('/PgSection/EditModal', e);
  return data;
};
