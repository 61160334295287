import { Field, FieldArray, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import Modal from '../../../SharedComponents/Modal';
import PlusCircle from '../../../SharedComponents/Svg/PlusCircle';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Loading from '../../../SharedComponents/Loading';
import * as yup from 'yup';
import { getLanguages, editGalleryAlbumItem } from '../ApiGallery';
import { useParams } from 'react-router';
import { DateTime } from 'luxon';
import PecnilAlt from '../../../SharedComponents/Svg/PencilAlt';
import AddImage from './AddImage';
import { useTranslation } from 'react-i18next';
import YoutubeVideoContainer from '../../../SharedComponents/YoutubeVideoContainer';
import ImageContainer from '../../../SharedComponents/ImageContainer';
import LanguageList from '../../../SharedComponents/LanguageList';
import VideoContainer from '../../../SharedComponents/VideoContainer';

export default function EditGalleyImage(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { id } = props.id ? props : 1;
  const { typeId, albumItem } = props;

  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-darkblue hover:bg-darkbluehover transition duration-500 text-white rounded p-1"
      >
        <PecnilAlt className="w-5 h-5" />
      </button>

      {isOpen && <ModalContainer albumItem={albumItem} id={id} typeId={typeId} isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, id, typeId, albumItem } = props;
  const [languageId, setLanguageId] = useState(1);
  const [uploadSucess, setUploadSucess] = useState(false);
  const { albumId } = useParams();
  const { t, i18n } = useTranslation();

  // Query Data To Fetch Languages
  const { data, isLoading, isError } = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  useEffect(() => {
    setLanguageId(data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [data]);

  // create a loop for localisation
  const glAlbumItemLngs = data?.data?.map((language) => ({
    languageId: language.id,
    title: '',
    description: '',
    btnNextText: '',
    btnPreviousText: '',
    btnCloseText: '',
    keywords: '',
  }));
  const initialValues = albumItem;
  const queryClient = useQueryClient();
  // Mutate Data to Create New Page
  const mutation = useMutation(editGalleryAlbumItem, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['galleryAlbums']);
        queryClient.invalidateQueries(['galleryAlbum']);

        setIsOpen(false);
      }
    },
  });

  const onSubmit = async (e) => {
    try {
      mutation.mutate(e);
    } catch (error) {
      console.log('error', error);
    }
  };

  const CreateGalleryAlbumValidationSchema = yup.object().shape({
    orderNum: yup.number().required().positive().integer(),
    duration: yup.number().required().positive().integer().min(3),

    imageId: yup.number().required('This Field is Required').positive().integer(),
  });
  return (
    <>
      {data && data.data && (
        <Formik
          onSubmit={onSubmit}
          validationSchema={CreateGalleryAlbumValidationSchema}
          validateOnBlur={true}
          validateOnChange={false}
          initialValues={initialValues}
        >
          {({ values, setFieldValue, errors }) => {
            return (
              <Form>
                <Modal
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  onSubmit={onSubmit}
                  title={t('Edit Gallery Item')}
                  isLoading={mutation.isLoading}
                  add={true}
                >
                  {isLoading && <Loading />}
                  {isError && 'error'}
                  {data && (
                    <LanguageList
                      className="flex transform translate-y-1 relative z-10 mb-4"
                      languages={data?.data}
                      languageId={languageId}
                      setLanguageId={setLanguageId}
                    >
                      <hr className="border w-full absolute bottom-0 left-0 z-0" />
                    </LanguageList>
                  )}
                  {mutation.isError && (
                    <small className="text-sm bg-red-100 block p-2 text-red-500 mb-2">
                      {t('Order Number is Duplicated Kindly Change it')}
                    </small>
                  )}
                  {/* Submit Form */}
                  <label className=" mb-4 block hidden">
                    <b className="font-semibold block text-gray-700">{t('Image Id')}</b>
                    <Field
                      className={`border rounded  w-full p-1 ${errors.imageId ? 'border-red-500' : ''}`}
                      name={`imageId`}
                      type="number"
                    />
                    {errors.imageId && <small className="text-xs text-red-500">{errors.imageId}</small>}
                  </label>
                  <div className="focus:outline-none group rounded border-dashed border-2 mb-4 relative">
                    {values.isVideo && <VideoContainer url={values.imageUrl} />}
                    {values.isVideo === false && (
                      <ImageContainer imageUrl={values.imageUrl} setFieldValue={setFieldValue} />
                    )}
                  </div>
                  {values &&
                    values.glAlbumItemLngs &&
                    values?.glAlbumItemLngs.map((asset, index) => (
                      <FieldArray
                        key={index}
                        name="glAlbumItemLngs"
                        render={(arrayHelpers) => (
                          <>
                            {languageId === asset.languageId && (
                              <>
                                <label>
                                  <b
                                    className={`font-semibold block text-gray-700 ${
                                      i18n.language === 'en' ? '' : 'text-right'
                                    }`}
                                  >
                                    {t('Title')}
                                  </b>
                                  <Field
                                    className="border rounded  w-full mb-4 p-1"
                                    type="text"
                                    name="title"
                                    name={`glAlbumItemLngs[${index}].title`}
                                  />
                                </label>
                                <label>
                                  <b
                                    className={`font-semibold block text-gray-700 ${
                                      i18n.language === 'en' ? '' : 'text-right'
                                    }`}
                                  >
                                    {t('Description')}
                                  </b>
                                  <Field
                                    className={`border rounded  w-full mb-4 p-1`}
                                    name={`glAlbumItemLngs[${index}].description`}
                                    type="text"
                                  />
                                </label>
                                <label>
                                  <b
                                    className={`font-semibold block text-gray-700 ${
                                      i18n.language === 'en' ? '' : 'text-right'
                                    }`}
                                  >
                                    {t('Button Next Text')}
                                  </b>
                                  <Field
                                    className={`border rounded  w-full mb-4 p-1`}
                                    name={`glAlbumItemLngs[${index}].btnNextText`}
                                    type="text"
                                  />
                                </label>
                                <label>
                                  <b
                                    className={`font-semibold block text-gray-700 ${
                                      i18n.language === 'en' ? '' : 'text-right'
                                    }`}
                                  >
                                    {t('Button Previous Text')}
                                  </b>
                                  <Field
                                    className={`border rounded  w-full mb-4 p-1`}
                                    name={`glAlbumItemLngs[${index}].btnPreviousText`}
                                    type="text"
                                  />
                                </label>
                                <label>
                                  <b
                                    className={`font-semibold block text-gray-700 ${
                                      i18n.language === 'en' ? '' : 'text-right'
                                    }`}
                                  >
                                    {t('Button Close Text')}
                                  </b>
                                  <Field
                                    className={`border rounded  w-full mb-4 p-1`}
                                    name={`glAlbumItemLngs[${index}].btnCloseText`}
                                    type="text"
                                  />
                                </label>
                                <label>
                                  <b
                                    className={`font-semibold block text-gray-700 ${
                                      i18n.language === 'en' ? '' : 'text-right'
                                    }`}
                                  >
                                    {t('Keywords')}
                                  </b>
                                  <Field
                                    className={`border rounded  w-full mb-4 p-1`}
                                    name={`glAlbumItemLngs[${index}].keywords`}
                                    type="text"
                                  />
                                </label>
                              </>
                            )}
                          </>
                        )}
                      />
                    ))}

                  <label>
                    <b className={`font-semibold block text-gray-700 ${i18n.language === 'en' ? '' : 'text-right'}`}>
                      {t('Duration by Seconds')}
                    </b>
                    <Field className="border rounded  w-full mb-4 p-1" name={`duration`} type="number" />
                  </label>
                  <label>
                    <b className={`font-semibold block text-gray-700 ${i18n.language === 'en' ? '' : 'text-right'}`}>
                      {t('Order Number')}
                    </b>
                    <Field className="border rounded  w-full mb-4 p-1" name={`orderNum`} type="number" />
                  </label>
                  <label>
                    <b className={`font-semibold block text-gray-700 ${i18n.language === 'en' ? '' : 'text-right'}`}>
                      {t('Button Next Link')}
                    </b>
                    <Field className={`border rounded  w-full mb-4 p-1`} name={`.btnNextLink`} type="text" />
                  </label>
                  <label>
                    <b className={`font-semibold block text-gray-700 ${i18n.language === 'en' ? '' : 'text-right'}`}>
                      {t('Button Previous Link')}
                    </b>
                    <Field className={`border rounded  w-full mb-4 p-1`} name={`.btnPreviousLink`} type="text" />
                  </label>
                  <label>
                    <b className={`font-semibold block text-gray-700 ${i18n.language === 'en' ? '' : 'text-right'}`}>
                      {t('Button Close Link')}
                    </b>
                    <Field className={`border rounded  w-full mb-4 p-1`} name={`.btnCloseLink`} type="text" />
                  </label>
                </Modal>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};
