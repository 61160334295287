import DeleteMenuItem from './DeleteMenuItem';
import EditMenuItem from './EditMenuItem';
import OptionsMenuItem from './OptionsMenuItem';
import SubMenuItem from './SubMenuItem';
import { Draggable } from 'react-beautiful-dnd';
import AddSubMenuItem from './AddSubMenuItem';
import ToggleMenuItemStatus from './ToggleMenuItemStatus';
import { useState } from 'react';
import ChevronRight from '../../../SharedComponents/Svg/ChevronRight';
import { useTranslation } from 'react-i18next';

export default function MenuItem(props) {
  const { menuItem, languageId, index, activeMenu } = props;

  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  return (
    <>
      <Draggable draggableId={`${menuItem.id}`} index={index}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.draggableProps}>
            <div className="border px-4 p-3 mb-4 shadow bg-white ">
              <div className="flex justify-between">
                <div className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
                  {menuItem.itemsCount > 0 && (
                    <button
                      className="focus:outline-none "
                      onClick={() => {
                        setIsOpen(!isOpen);
                      }}
                    >
                      <ChevronRight
                        className={`w-4 h-4 transform ${
                          isOpen ? 'rotate-90' : 'rotate-0'
                        }  transition-all duration-500`}
                      />{' '}
                    </button>
                  )}
                  <div className="flex space-x-2">
                    <span className="bg-gray-50 px-2 rounded ">{menuItem.orderNum}</span>

                    {menuItem.muMenuItemLngs.map((x) => {
                      return <span key={x.id}>{languageId === x.languageId && <> {x.title} </>}</span>;
                    })}
                  </div>
                  <span className="bg-yellow-100 text-yellow-500 px-2 rounded">{menuItem.itemsCount}</span>
                </div>
                <div className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
                  <AddSubMenuItem activeMenu={activeMenu} parentId={menuItem.id} />
                  <EditMenuItem menuItem={menuItem} languageId={languageId} activeMenu={activeMenu} />
                  <DeleteMenuItem itemsCount={menuItem.itemsCount} id={menuItem.id} />
                  <ToggleMenuItemStatus isActive={menuItem.isActive} id={menuItem.id} />
                  <div {...provided.dragHandleProps}>
                    <OptionsMenuItem />
                  </div>
                </div>
              </div>
            </div>
            {menuItem.itemsCount > 0 && isOpen && (
              <div className="pl-8 mb-4 space-y-4">
                <SubMenuItem
                  activeMenu={activeMenu}
                  languageId={languageId}
                  index={index}
                  key={menuItem.id}
                  menuItem={menuItem}
                />
              </div>
            )}
          </div>
        )}
      </Draggable>{' '}
    </>
  );
}
