// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Menus: 'القوائم',
  'Menus List': 'قائمة القوائم',
  'Create Menu': 'إنشاء قائمة',
  'Save Menu Order': 'حفظ ترتيب القائمة',
  'Menu Order Have Been Changed Successfully': 'تم تحديث القائمة بنجاح',
  'Edit Menu': 'تعديل القائمة',
  Url: 'عنوان URL',
  'Delete Menu Item': 'حذف عنصر من القائمة',
  'Are you sure you want to delete this Menu Item? All the data will be permanently removed from our servers forever. This action cannot be undone':
    'هل أنت متأكد من أنك تريد حذف العنصر من القائمة هذا؟سيتم إزالتها بشكل دائم من خوادمنا إلى الأبد.لا يمكن التراجع عن هذا الإجراء',
  'Menu Item, Deleted': 'تم حذف العنصر من القائمة',
  'Sorry, This Menu Item have Data Inside, Please Delete and Retry':
    'آسف، هذه القائمة القائمة لها بيانات داخلها، يرجى حذف وإعادة المحاولة',
  'Menu Have Been Moved Successfully': 'تم نقل القائمة بنجاح',
  'Please Select Menu To Move': 'يرجى اختيار القائمة للتحرك',
  Normal: 'الوضع العادي',
  Mega: 'ميجا',
  'Menu Item Status Have Been Changed Successfully': 'تم تغيير حالة العنصر في القائمة بنجاح',
  'Menu , Deleted': 'القائمة حذفت',
  'Sorry, This Menu  have Data Inside, Please Delete and Retry':
    'عذرا، هذه القائمة بها بيانات داخلها، يرجى حذف وإعادة المحاولة',
  'Create Menum': 'إنشاء قائمة',
  'Sub Menu Item Added, Successfullly': 'تم إضافة عنصر فرعية بنجاح',
  'Menu Item Added, Successfullly': 'تم إضافة عنصر بنجاح',
};
