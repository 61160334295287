import { Field, FieldArray, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import Modal from '../../SharedComponents/Modal';
import PlusCircle from '../../SharedComponents/Svg/PlusCircle';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Loading from '../../SharedComponents/Loading';
import * as yup from 'yup';
import { addCategory, getLanguages } from './ApiCategories';
import Notification from '../../SharedComponents/Notification';
import { useTranslation } from 'react-i18next';
import LanguageList from '../../SharedComponents/LanguageList';
import AddImage from '../Gallery/Components/AddImage';

export default function AddSubCategory(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { parentId } = props;
  const { id } = props.id ? props : 1;
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  // Mutate Data to Create New Category
  const addCategoryMutation = useMutation(addCategory, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['categories']);
        setIsOpen(false);
      }
    },
  });
  return (
    <>
      {addCategoryMutation.isSuccess && <Notification message={t('Category Created Successfully')} />}

      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-green-100 text-green-800 hover:bg-green-200  p-1 rounded transition duration-300 ease-in-out"
      >
        <PlusCircle className="w-5 h-5" />
      </button>

      {isOpen && (
        <ModalContainer
          parentId={parentId}
          id={id}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          addCategoryMutation={addCategoryMutation}
        />
      )}
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, addCategoryMutation, parentId } = props;
  const [languageId, setLanguageId] = useState(1);
  const { t, i18n } = useTranslation();
  // Query Data To Fetch Languages
  const { data, isLoading, isError } = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  useEffect(() => {
    setLanguageId(data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [data]);

  // create a loop for localisation
  const pgPageCategoryLngs = data?.data?.map((language) => ({
    languageId: language.id,
    name: '',
  }));
  const initialValues = {
    pgPageCategoryLngs: pgPageCategoryLngs,
    parentId: parentId,
    isPrimary: false,
    itemsCount: 0,
    imageId: 0,
    imageURL: '',
  };

  const onSubmit = async (e) => {
    try {
      addCategoryMutation.mutate({ ...e, imageURL: e?.imageUrl });
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      {data && data.data && (
        <Formik onSubmit={onSubmit} validateOnBlur={false} validateOnChange={false} initialValues={initialValues}>
          {({ values, setFieldValue, errors }) => {
            return (
              <Form>
                <Modal
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  onSubmit={onSubmit}
                  title={t('Create Category')}
                  isLoading={addCategoryMutation.isLoading}
                  add={true}
                >
                  {isLoading && <Loading />}
                  {isError && 'error'}
                  <LanguageList
                    className="flex transform translate-y-1 relative z-10 mb-4"
                    languages={data?.data}
                    languageId={languageId}
                    setLanguageId={setLanguageId}
                  >
                    <hr className="border w-full absolute bottom-0 left-0 z-0" />
                  </LanguageList>

                  {/* Submit Form */}
                  <label className=" mb-4 block hidden">
                    <b className="font-semibold block text-gray-700">{t('Image Id')}</b>
                    <Field
                      className={`border rounded  w-full p-1 ${errors.imageId ? 'border-red-500' : ''}`}
                      name={`imageId`}
                      type="number"
                    />
                    {errors.imageId && <small className="text-xs text-red-500">{errors.imageId}</small>}
                  </label>
                  {/* <span> {values.imageUrl}</span> */}
                  <div className="h-64 focus:outline-none group rounded border-dashed border-2 mb-4 relative">
                    {(values.imageUrl && (
                      <>
                        <img
                          className="object-fit h-64 w-full"
                          alt="img"
                          src={`${window.env.REACT_APP_IMAGE_PATH}${values.imageUrl}`}
                        />
                        <div className="absolute group-hover:opacity-100 opacity-0 inset-0 flex items-center justify-center">
                          <AddImage edit={true} setField={setFieldValue} />
                        </div>
                      </>
                    )) || (
                      <div className="absolute inset-0 flex items-center justify-center">
                        <AddImage setField={setFieldValue} />
                      </div>
                    )}
                  </div>

                  {values &&
                    values.pgPageCategoryLngs &&
                    values?.pgPageCategoryLngs.map((asset, index) => (
                      <FieldArray
                        key={index}
                        name="pgPageCategoryLngs"
                        render={(arrayHelpers) => (
                          <>
                            {languageId === asset.languageId && (
                              <>
                                <label>
                                  <b
                                    className={`font-semibold block text-gray-700 ${
                                      i18n.language === 'en' ? '' : 'text-right'
                                    }`}
                                  >
                                    {t('Name')}
                                  </b>
                                  <Field
                                    className="border rounded  w-full mb-4 p-1"
                                    type="text"
                                    name={`pgPageCategoryLngs[${index}].name`}
                                  />
                                </label>
                              </>
                            )}
                          </>
                        )}
                      />
                    ))}
                </Modal>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};
