import React from 'react';
import { Field, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { addEmialSettings, getEmailSettings, updateEmialSettings } from './ApiSettings';
import Loading from '../../SharedComponents/Loading';
import LoadingCircle from '../../SharedComponents/Svg/LoadingCircle';
import Notification from '../../SharedComponents/Notification';
import CustomInput from '../../SharedComponents/CustomInput';

export default function EmailSettings() {
  const { t } = useTranslation();
  const { data, isError, isLoading } = useQuery('getEmailSettings', getEmailSettings, {
    retry: false,
  });
  const updateEmialMutation = useMutation(updateEmialSettings);
  const addEmialMutation = useMutation(addEmialSettings);
  let initialValues = data?.data;
  if (isError) {
    initialValues = {
      email: '',
      password: '',
      port: 0,
      host: '',
    };
  }
  console.log(isError);
  const onSubmitHandler = (values) => {
    if (!isError) {
      updateEmialMutation.mutate(values);
      return;
    }
    addEmialMutation.mutate(values);
  };

  if (isLoading) return <Loading />;
  return (
    <div>
      {updateEmialMutation.isSuccess && <Notification message={t('Email Settings, Updated')} />}
      {addEmialMutation.isSuccess && <Notification message={t('Email Settings, Added')} />}
      {updateEmialMutation.isError || addEmialMutation.isError ? (
        <Notification message={t('Network Error')} error />
      ) : (
        ''
      )}
      <Formik initialValues={initialValues}>
        {({ values }) => (
          <div>
            {/* <CustomInput
              type="email"
              name="email"
              placeholder={t('Email')}
              className="w-full border focus:outline-none px-2 py-1 rounded mb-4"
            /> */}
            <Field
              type="email"
              name="email"
              placeholder={t('Email')}
              className="w-full border focus:outline-none px-2 py-1 rounded mb-4"
              required
            />

            <Field
              type="password"
              name="password"
              placeholder={t('Password')}
              className="w-full border focus:outline-none px-2 py-1 rounded mb-4"
              required
            />
            <Field
              type="number"
              name="port"
              placeholder={t('Port')}
              className="w-full border focus:outline-none px-2 py-1 rounded mb-4"
              required
            />
            <Field
              type="text"
              name="host"
              placeholder={t('Host')}
              className="w-full border focus:outline-none px-2 py-1 rounded mb-4"
              required
            />
            <div className="justify-end flex space-x-4">
              <button
                type="button"
                onClick={() => {
                  onSubmitHandler(values);
                }}
                className="bg-darkblue hover:bg-darkbluehover text-white px-4 py-2 rounded"
              >
                {t('Update')}
                {updateEmialMutation.isLoading || (addEmialMutation.isLoading && <LoadingCircle />)}
              </button>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}
