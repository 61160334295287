import { Field, FieldArray, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import Modal from '../../../../SharedComponents/Modal';
import PlusCircle from '../../../../SharedComponents/Svg/PlusCircle';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Loading from '../../../../SharedComponents/Loading';
import * as yup from 'yup';
import { addCardItem, getLanguages } from '../ApiSections';
import ListPagesList from './ListPagesList';
import RichTextEditor from '../../Pages/RichTextEditor';
import DeleteListParent from './DeleteListParent';
import { useTranslation } from 'react-i18next';
import Notification from '../../../../SharedComponents/Notification';
import LanguageList from '../../../../SharedComponents/LanguageList';

export default function AddListItem(props) {
  const { id } = props.id ? props : 1;
  const {
    typeId,
    activeMenu,
    orderNum,
    addListItemMutation,
    pgSectionListParentId,
    editListParentMutation,
    editIsLoading,
    editIsSuccess,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  return (
    <>
      {editIsSuccess && <Notification message={t('List Updated')} />}

      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center transition duration-300 ease-in-out mx-auto"
      >
        <PlusCircle className="w-8 h-8 text-lightgreen" />
      </button>
      <div className="flex justify-end items-center py-5">
        <button
          type="button"
          onClick={editListParentMutation}
          className={`bg-lightgreen py-2 px-2 flex ${
            i18n.language === 'en' ? 'mr-3' : 'ml-3 flex-row-reverse'
          }  justify-center text-center h-full text-sm font-bold text-white items-center hover:bg-lightgreenhover text-centerhover shadow  rounded transition duration-300 ease-in-out`}
        >
          <span>{t('Update List')}</span>
          {editIsLoading && (
            <svg
              className="animate-spin w-6 h-6 inline-block ml-4 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
        </button>

        <DeleteListParent id={pgSectionListParentId} />
      </div>

      {isOpen && (
        <ModalContainer
          id={id}
          typeId={typeId}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          activeMenu={activeMenu}
          orderNum={orderNum}
          addListItemMutation={addListItemMutation}
          pgSectionListParentId={pgSectionListParentId}
        />
      )}
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, addListItemMutation, pgSectionListParentId } = props;
  const [languageId, setLanguageId] = useState(1);
  const { t } = useTranslation();
  const { data, isLoading, isError } = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  useEffect(() => {
    setLanguageId(data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [data]);

  const [activePage, setActivePage] = useState();
  // create a loop for localisation
  const pgSectionListItemLngs = data?.data?.map((language) => ({
    languageId: language.id,
    title: '',
  }));
  const initialValues = {
    pgSectionListParentId: pgSectionListParentId,
    pgSectionListItemLngs: pgSectionListItemLngs,
  };

  const onSubmit = async (e) => {
    try {
      await addListItemMutation.mutate(e);
      setIsOpen(false);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {}, [activePage]);
  return (
    <>
      {data && data.data && (
        <Formik
          // onSubmit={onSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={initialValues}
        >
          {({ values, setFieldValue, errors }) => {
            return (
              <Form>
                <Modal
                  className="sm:max-w-3xl"
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  onSubmit={() => onSubmit(values)}
                  title={t('Add Item')}
                  isLoading={addListItemMutation.isLoading}
                  add={true}
                  submitType="button"
                >
                  {isLoading && <Loading />}
                  {isError && 'error'}
                  {data && (
                    <LanguageList
                      className="flex transform translate-y-1 relative z-10 mb-4"
                      languages={data?.data}
                      languageId={languageId}
                      setLanguageId={setLanguageId}
                    >
                      <hr className="border w-full absolute bottom-0 left-0 z-0" />
                    </LanguageList>
                  )}

                  {/* Submit Form */}
                  <div className="grid grid-cols-2 gap-6">
                    <div className="col-span-2">
                      <div className="mb-2">
                        {values &&
                          values.pgSectionListItemLngs &&
                          values?.pgSectionListItemLngs.map((pgSectionCollapseItemsLng, index) => (
                            <FieldArray
                              key={index}
                              name="pgSectionListItemLngs"
                              render={(arrayHelpers) => (
                                <>
                                  {languageId === pgSectionCollapseItemsLng.languageId && (
                                    <>
                                      <label>
                                        {/* <RichTextEditor
                                          fieldName={`pgSectionListItemLngs[${index}].title`}
                                          fieldValue={values.pgSectionListItemLngs[index].title}
                                          setFieldValue={setFieldValue}
                                        /> */}
                                        <Field
                                          className="border rounded  w-full  p-2"
                                          type="text"
                                          placeholder={t('Add Title')}
                                          name={`pgSectionListItemLngs[${index}].title`}
                                        />
                                      </label>
                                    </>
                                  )}
                                </>
                              )}
                            />
                          ))}
                      </div>
                    </div>
                  </div>
                </Modal>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};
