import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import Notification from '../../SharedComponents/Notification';
import Flag from '../../SharedComponents/Svg/Flag';
import { saveBranchReorder } from './ApiSettings';

export default function SaveBranchOrder(props) {
  const { state } = props;
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();
  const { mutate, data, isError, isSuccess } = useMutation(saveBranchReorder, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['settings']);
    },
  });
  const handleOrder = async (e) => {
    const newData = state.branches.map((e, index) => {
      return { orderNumber: index + 1, itemId: e.id };
    });
    try {
      await mutate(newData);
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <>
      <button
        type="button"
        onClick={handleOrder}
        className={`bg-darkblue ${
          i18n.language === 'en' ? '' : 'space-x-reverse'
        } space-x-1 text-sm font-bold text-white flex items-center hover:bg-darkbluehover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
      >
        <Flag className="w-5 h-5" /> <span>{t('Save Branches Order')}</span>
      </button>
      {isSuccess && <Notification message={t('Branches Order Have Been Changed Successfully')} />}
      {isError && <Notification error={true} message={t('Network Error')} />}
    </>
  );
}
