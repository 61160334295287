import axios from 'axios';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { Recorder } from 'react-voice-recorder';
import 'react-voice-recorder/dist/index.css';

export default function RecordAudio(props) {
  const { setFieldValue, values } = useFormikContext();

  const [audioDetails, setAudioDetails] = useState({
    url: null,
    blob: null,
    chunks: null,
    duration: {
      h: 0,
      m: 0,
      s: 0,
    },
  });

  const handleAudioStop = (data) => {
    console.log(data);
    console.log(data);
    setAudioDetails({ audioDetails: data });
    handleAudioUpload(data.blob);
    handleReset();
    setAudioDetails({
      audioDetails: {
        url: null,
        blob: null,
        chunks: null,
        duration: {
          h: 0,
          m: 0,
          s: 0,
        },
      },
    });
  };

  const handleAudioUpload = (file) => {
    const x = new Blob([file], { type: 'audio/aacMP4' });
    const audiofile = new File([file], `test.mp3`, { type: 'audio/mp3' });

    // const audiofile = new File([audioBlob], `${uuidv4()}.wav`, { type: "audio/wav" })

    console.log('audiofile', audiofile);
    console.log(x);

    const handleServer = async () => {
      const formData = new FormData();
      formData.append('file', audiofile);

      try {
        await mediaUpload.mutate(formData);
      } catch (error) {
        console.log('error', error);
      }
      handleReset();
    };
    handleServer();
  };

  const handleCountDown = (data) => {
    console.log(data);
  };

  const handleReset = () => {
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: 0,
        m: 0,
        s: 0,
      },
    };
    setAudioDetails({ audioDetails: reset });
  };

  const mediaUpload = useMutation(uploadMedia, {
    onSuccess: (e) => {
      handleReset();
      setFieldValue('message', e.data.path);
      setAudioDetails({
        url: null,
        blob: null,
        chunks: null,
        duration: {
          h: 0,
          m: 0,
          s: 0,
        },
      });
      handleReset();
      handleReset();
      //   setFilePath(e.data.path);
      //   setUploadSucess(true);
      //   setUploading(false);
    },
  });

  return (
    <div>
      <Recorder
        record={true}
        // title={'New recording'}
        hideHeader
        audioURL={audioDetails.url}
        // showUIAudio
        handleAudioStop={(data) => handleAudioStop(data)}
        // handleAudioUpload={(data) => handleAudioUpload(data)}
        // handleCountDown={(data) => handleCountDown(data)}
        // handleReset={() => handleReset()}
        uploadButtonDisabled
        // mimeTypeToUseWhenRecording={`audio/ogg`} // For specific mimetype.
      />
    </div>
  );
}

// submit data to server
export const uploadMedia = async (formData) => {
  const mediaUpload = await axios.post(`${window.env.REACT_APP_CDN}`, formData, {
    headers: {
      'Content-Type': 'application/octet-stream',
    },
  });
  // const data = query.data.data;
  // const isDone = query.data.isDone;
  return mediaUpload;
};
