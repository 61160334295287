import { Field, FieldArray, Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Notification from '../../../../SharedComponents/Notification';
import RichTextEditor from '../../Pages/RichTextEditor';
import { editCollapseItems } from './ApiCollapse';
import CollapsePagesList from './CollapsePagesList';
import DeleteCollapseItem from './DeleteCollapseItem';
// import DeleteCardItem from './DeleteCardItem';

export default function CollapseItem(props) {
  const { pgSectionCollapseItem, pgSectionCollapseItemIndex, pgSectionCollapseItemsHelper, languageId, setFieldValue } =
    props;
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const editCardItemMutation = useMutation(editCollapseItems, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['sections']);
      }
    },
  });

  const onSubmit = async (values) => {
    setFieldValue(`card.pgSectionCollapseItems[${pgSectionCollapseItemIndex}]`, values);
    try {
      await editCardItemMutation.mutate(values);
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <div className="mt-4 shadow p-4 border rounded ">
      {editCardItemMutation.isSuccess && <Notification message={t('Collapse Item Updated')} />}
      <Formik
        initialValues={{
          ...pgSectionCollapseItem,
          isExternalLink:
            pgSectionCollapseItem.isExternalLink === true || pgSectionCollapseItem.isExternalLink === 'true'
              ? 'true'
              : 'false',
        }}
      >
        {({ values, setFieldValue, setValues }) => {
          values.date = values.date ? DateTime.fromISO(values?.date).toISODate() : null;
          return (
            <Form>
              <div>
                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2">
                    <div className="mb-2">
                      {values &&
                        values.pgSectionCollapseItemsLngs &&
                        values?.pgSectionCollapseItemsLngs.map((pgSectionCollapseItemLng, index) => (
                          <FieldArray
                            key={index}
                            name="pgSectionCollapseItemsLngs"
                            render={(arrayHelpers) => (
                              <>
                                {languageId === pgSectionCollapseItemLng.languageId && (
                                  <>
                                    <label>
                                      <Field
                                        className="border rounded  w-full  p-2"
                                        type="text"
                                        placeholder={t('Add Title')}
                                        name={`pgSectionCollapseItemsLngs[${index}].title`}
                                      />
                                    </label>
                                  </>
                                )}
                              </>
                            )}
                          />
                        ))}
                    </div>
                    <div className="mb-2">
                      {values &&
                        values.pgSectionCollapseItemsLngs &&
                        values?.pgSectionCollapseItemsLngs.map((pgSectionCollapseItemLng, index) => (
                          <FieldArray
                            key={index}
                            name="pgSectionCollapseItemsLngs"
                            render={(arrayHelpers) => (
                              <>
                                {languageId === pgSectionCollapseItemLng.languageId && (
                                  <>
                                    <label>
                                      <RichTextEditor
                                        fieldName={`pgSectionCollapseItemsLngs[${index}].description`}
                                        fieldValue={values.pgSectionCollapseItemsLngs[index].description}
                                        setFieldValue={setFieldValue}
                                      />
                                      {/* <Field
                                        className="border rounded  w-full  p-2"
                                        as="textarea"
                                        rows="3"
                                        placeholder="Add Description"
                                        name={`pgSectionCollapseItemsLngs[${index}].description`}
                                      /> */}
                                    </label>
                                  </>
                                )}
                              </>
                            )}
                          />
                        ))}
                    </div>

                    {/* waiting to check if we will add image  */}
                    {/* <div className="h-52 focus:outline-none group rounded border-dashed border-2 mb-2 relative">
                        {(values.imageUrl && (
                          <>
                            <div className="flex items-center justify-center h-full">
                              <img
                                className=""
                                style={{
                                  maxWidth: 32,
                                  maxHeight: 32,
                                }}
                                alt="img"
                                src={`${window.env.REACT_APP_IMAGE_PATH}${values.imageUrl}`}
                              />{' '}
                            </div>
                            <div className="absolute group-hover:opacity-100 opacity-0 inset-0 flex items-center justify-center">
                              <AddCollapseImage edit={true} setField={setFieldValue} fieldName="imageUrl" />
                            </div>
                          </>
                        )) || (
                          <div className="absolute inset-0 flex items-center justify-center">
                            <AddCollapseImage fieldName="imageUrl" setField={setFieldValue} />
                          </div>
                        )}
                      </div> */}

                    <div className="grid grid-cols-2 gap-6 flex items-center">
                      <div>
                        {values &&
                          values.pgSectionCollapseItemsLngs &&
                          values?.pgSectionCollapseItemsLngs.map((pgSectionCollapseItemsLng, index) => (
                            <FieldArray
                              key={index}
                              name="pgSectionCollapseItemsLngs"
                              render={(arrayHelpers) => (
                                <>
                                  {languageId === pgSectionCollapseItemsLng.languageId && (
                                    <>
                                      <label>
                                        <Field
                                          className="border rounded  w-full  p-2"
                                          type="text"
                                          placeholder={t('Button Title')}
                                          name={`pgSectionCollapseItemsLngs[${index}].buttonTitle`}
                                        />
                                      </label>
                                    </>
                                  )}
                                </>
                              )}
                            />
                          ))}
                      </div>
                      <div>
                        <div
                          className={`grid grid-cols-2 space-x-2  ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
                        >
                          <label
                            className={`flex items-center space-x-2  ${
                              i18n.language === 'en' ? '' : 'space-x-reverse'
                            }`}
                          >
                            <Field type="radio" name="isExternalLink" value="true" className="w-4 h-4" />
                            <span>{t('External Link')}</span>
                          </label>
                          <label
                            className={`flex items-center space-x-2  ${
                              i18n.language === 'en' ? '' : 'space-x-reverse'
                            }`}
                          >
                            <Field type="radio" name="isExternalLink" value="false" className="w-4 h-4" />
                            <span>{t('Pages')}</span>
                          </label>
                        </div>
                      </div>

                      <div className="relative">
                        <CollapsePagesList
                          isExternalLink={values.isExternalLink}
                          setFieldValue={setFieldValue}
                          languageId={languageId}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <label className="mt-2 block">
                  <Field
                    type="number"
                    name="orderNum"
                    placeholder="Order Number"
                    className="border rounded  w-full  p-2"
                  />
                </label>
              </div>
              <div className={`mt-2 flex justify-end space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
                <button
                  type="button"
                  onClick={() => {
                    onSubmit(values);
                  }}
                  className="bg-lightgreen py-2 px-2 flex justify-center text-center h-full text-sm font-bold text-white items-center hover:bg-lightgreenhover text-centerhover shadow  rounded transition duration-300 ease-in-out"
                >
                  {t('Update Item')}
                </button>
                <DeleteCollapseItem
                  id={values.id}
                  pgSectionCollapseItemsHelper={pgSectionCollapseItemsHelper}
                  pgSectionCollapseItemIndex={pgSectionCollapseItemIndex}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
