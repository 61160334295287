import axios from 'axios';

export const getPages = async (e) => {
  const query = await axios.post('/PgPage/GetAll', e.queryKey[1]);
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};

export const getLanguages = async () => {
  const query = await axios.post('/Language/GetAll');
  const data = query.data.data;
  const isDone = query.data.isDone;
  return { data, isDone };
};

export const getPage = (id, languageId) => {
  return async () => {
    const query = await axios.get(`/PgPage/Get?id=${id}&langId=${languageId}`);
    const data = query.data.data;
    const isDone = query.data.isDone;
    return { data, isDone };
  };
};

export const addPage = async (e) => {
  const { data } = await axios.post('/PgPage/Add', e);
  return data;
};

export const editPage = async (e) => {
  const { data } = await axios.post('/PgPage/Edit', e);
  return data;
};

export const deletePage = async (id) => {
  const mutation = await axios.post(`/PgPage/Delete`, { id });
  const data = mutation.data.data;
  const isDone = mutation.data.isDone;
  return { data, isDone };
};

export const getAssetById = async (e) => {
  const { id } = e.queryKey[1];
  const { data } = await axios.get(`/AstAsset/Get?id=${id}`);
  return data;
};
