// import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import { CSVDownload, CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getNewsLetters } from './ApiNewsLetter';
import NewsLetters from './NewsLetters';

export default function DownloadNewsLetter(props) {
  const { t } = useTranslation();
  let initialState = {
    pageNumber: 0,
    pageSize: 0,
    isPaging: false,
    langId: 0,
    isSearch: false,
    searchFilter: {
      langId: 0,
      term: '',
    },
  };
  const { data, isLoading, isError } = useQuery(['newsLetters', initialState], getNewsLetters, {
    staleTime: 99900,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });

  if (isLoading)
    return (
      <>
        <span className="bg-darkblue hover:bg-darkbluehover text-white py-2 rounded px-4">Preparing</span>
      </>
    );
  if (isError) return 'Error';
  const headers = [{ label: 'Email', key: 'email' }];

  const csvReport = {
    data: data.data,
    headers: headers,
    filename: 'NewsLetter_Export.csv',
  };
  return (
    <>
      <CSVLink {...csvReport}>
        <span className="bg-darkblue hover:bg-darkbluehover text-white py-2 rounded px-4">{t('Download CSV')}</span>
      </CSVLink>
    </>
  );
}
