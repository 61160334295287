import { Fragment, useState } from 'react';
import Trash from '../../../../SharedComponents/Svg/Trash';

export default function CustomFields(props) {
  const { customFields, setFieldValue } = props;

  const [customFieldValues, setCustomFieldsValues] = useState(customFields ? JSON.parse(customFields) : []);
  const addField = () => {
    setCustomFieldsValues([...customFieldValues, { fieldName: 'fieldName', fieldValue: 'FieldValue' }]);
  };

  const onChangeFieldName = (e, fieldIndex) => {
    const newCustomField = customFieldValues.map((x, index) => {
      if (fieldIndex === index) {
        return {
          fieldName: e.target.value,
          fieldValue: x.fieldValue,
        };
      }
      return {
        fieldName: x.fieldName,
        fieldValue: x.fieldValue,
      };
    });
    setCustomFieldsValues(newCustomField);
    setFieldValue('customFields', JSON.stringify(newCustomField));
  };

  const onChangeFieldValue = (e, fieldIndex) => {
    const newCustomField = customFieldValues.map((x, index) => {
      if (fieldIndex === index) {
        return {
          fieldName: x.fieldName,
          fieldValue: e.target.value,
        };
      }
      return {
        fieldName: x.fieldName,
        fieldValue: x.fieldValue,
      };
    });
    setCustomFieldsValues(newCustomField);
    setFieldValue('customFields', JSON.stringify(newCustomField));
  };

  const handleSubmit = () => {
    setFieldValue('customFields', JSON.stringify(customFieldValues));
  };
  const handleDelete = (index) => {
    delete customFieldValues[index];
    const newCustomValues = customFieldValues.filter(Boolean);

    setFieldValue('customFields', JSON.stringify(newCustomValues));
  };

  console.log('customFieldValues', customFieldValues);
  return (
    <>
      <div className="my-2">
        {customFieldValues?.map((e, index) => {
          return (
            <Fragment key={index}>
              <div className="grid grid-cols-3 gap-6 mb-2">
                <input
                  onChange={(x) => {
                    onChangeFieldName(x, index);
                  }}
                  className="border p-2"
                  placeholder={e?.fieldName}
                />
                <div className="flex items-center col-span-2 w-full space-x-2">
                  <input
                    onChange={(x) => {
                      onChangeFieldValue(x, index);
                    }}
                    className="border flex-grow p-2 "
                    placeholder={e?.fieldValue}
                  />
                  <button
                    onClick={() => {
                      handleDelete(index);
                    }}
                    className=" bg-red-400 p-2 text-white flex-0"
                  >
                    <Trash />
                  </button>
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>
      <div className="flex justify-end">
        <button className="bg-blue-500 text-white px-4 py-2 rounded" type="button" onClick={addField}>
          Add Custom Field
        </button>
      </div>
    </>
  );
}
