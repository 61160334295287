import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import Modal from '../../SharedComponents/Modal';
import PlusCircle from '../../SharedComponents/Svg/PlusCircle';
import Notification from '../../SharedComponents/Notification';
import { addFolder } from './ApiAssets';
import { useTranslation } from 'react-i18next';

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, typeId, addFolderMutation } = props;
  const { t, i18n } = useTranslation();
  const onSubmit = async (e) => {
    try {
      await addFolderMutation.mutate(e);
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <>
      <Formik
        initialValues={{ name: '', isHidden: false, typeId: typeId }}
        onSubmit={(values, { resetForm }) => {
          onSubmit({ ...values, typeId });
          resetForm();
        }}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <Modal
                isOpen={isOpen}
                isLoading={addFolderMutation.isLoading}
                setIsOpen={setIsOpen}
                title={t('Add New Folder')}
                add={true}
              >
                <label>
                  <b className={`font-semibold block text-gray-900 ${i18n.language === 'en' ? '' : 'text-right'}`}>
                    {t('Name')}
                  </b>
                  <Field
                    style={{ textIndent: '10px' }}
                    placeholder={t('Enter Name')}
                    className="border rounded  w-full mb-2 p-1"
                    name="name"
                    type="text"
                  />
                </label>
                <label className={`space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse  flex items-center'}`}>
                  <Field
                    type="checkbox"
                    className="border rounded p-1"
                    name="isHidden"
                    //   value={values.isHidden}
                  />
                  <span className={`${i18n.language === 'en' ? '' : 'ml-auto block'}`}>{t('Hidden')}</span>
                </label>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default function AddFolder(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { typeId } = props;
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();
  const addFolderMutation = useMutation(addFolder, {
    onSuccess: () => {
      queryClient.invalidateQueries(['assetFolders']);
      setIsOpen(false);
    },
  });
  return (
    <>
      {addFolderMutation.isSuccess && <Notification message={t('Folder Created Successfully')} />}

      <button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className={`bg-lightgreen text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out ${
          i18n.language === 'en' ? 'mr-2' : 'ml-2'
        }`}
      >
        <PlusCircle className="w-5 h-5" />{' '}
        <span className={`${i18n.language === 'en' ? 'ml-1' : 'mr-1'}`}>{t('Create Folder')}</span>
      </button>
      {isOpen && (
        <ModalContainer addFolderMutation={addFolderMutation} typeId={typeId} isOpen={isOpen} setIsOpen={setIsOpen} />
      )}
    </>
  );
}
