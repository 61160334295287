import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { GetDataOfRequest } from './ApRequests';
import CreateFormData from './CreateFormData';
import EditFormData from './EditFormData';

export default function RequestHolder(props) {
  const { formID } = props;
  const params = useParams();
  const { requestId } = params;
  const queryClient = useQueryClient();

  const getSingleDataOfRequest = useQuery(
    [`GetSingleDataOfRequest${formID}`, { formID, requestId }],
    GetDataOfRequest,
    {
      retry: false,
      onError: () => {
        queryClient.cancelQueries([`GetSingleDataOfRequest${formID}`]);
      },
    }
  );

  useEffect(() => {
    queryClient.invalidateQueries([`GetSingleDataOfRequest${formID}`]);
  }, []);

  console.log(getSingleDataOfRequest, 'getSingleDataOfRequest');

  return (
    <>
      {getSingleDataOfRequest.isSuccess && <EditFormData formID={formID} requestStatus={0} />}
      {getSingleDataOfRequest.isError && <CreateFormData formID={formID} requestStatus={0} />}
    </>
  );
}
