import { Field, FieldArray, Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import { useState } from 'react';
import DeleteTabItem from './DeleteTabItem';
import PencilAlt from '../../../../SharedComponents/Svg/PencilAlt';
import AddTabImage from './AddTabImage';
import { useMutation, useQueryClient } from 'react-query';
import { editTabItem } from './ApiTab';
import Notification from '../../../../SharedComponents/Notification';
import LoadingCircle from '../../../../SharedComponents/Svg/LoadingCircle';
import RichTextEditor from '../../Pages/RichTextEditor';
import { useTranslation } from 'react-i18next';
export default function TabItem(props) {
  const { setFieldValue, languageId, pgTabItem, pgTabItemIndex, pgTabItemsHelper, viewUi } = props;
  const [viewTabItem, setViewTabItem] = useState(false);
  const { t, i18n } = useTranslation();
  //   const imagePositions = [
  //     { id: 0, name: "Top" },
  //     { id: 1, name: "Bottom" },
  //     { id: 2, name: "Left" },
  //     { id: 3, name: "Right" },
  //   ];
  //   const onSubmit = (values) => {
  //     console.log("submitted");
  //     console.log("values", values);
  //     setFieldValue(`card.pgTabItems[${pgTabItemIndex}]`, values);
  //   };

  const queryClient = useQueryClient();
  const editTabItemMutation = useMutation(editTabItem, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['sections']);
      }
    },
  });

  const onSubmit = async (values) => {
    setFieldValue(`pgTab.pgTabItems[${pgTabItemIndex}]`, values);
    try {
      await editTabItemMutation.mutate(values);
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <>
      {editTabItemMutation.isSuccess && <Notification message={t('Tab Item Updated')} />}
      {editTabItemMutation.isError && <Notification error={true} message={t('Network Error')} />}
      {(viewTabItem === true && (
        <>
          <div className="border-b py-4 flex space-x-4">
            <div className="flex flex-col space-y-2 items-center bg-gray-100 py-2 text-white rounded-full px-1">
              <span className="bg-green-500 w-6 h-6 rounded-full flex items-center justify-center">
                {pgTabItem.orderNum}
              </span>
              <button
                type="button"
                className="bg-darkblue w-6 h-6 rounded-full flex items-center justify-center"
                onClick={() => {
                  setViewTabItem(false);
                }}
              >
                <PencilAlt className="w-4 h-4" />
              </button>
              <DeleteTabItem
                id={pgTabItem.id}
                pgTabItemsHelper={pgTabItemsHelper}
                pgTabItemIndex={pgTabItemIndex}
                icon={true}
              />
            </div>
            <div>
              <h3 className="font-bold text-2xl mb-2">
                {pgTabItem &&
                  pgTabItem.pgTabItemLng &&
                  pgTabItem.pgTabItemLng.map((item, index) => {
                    return <>{languageId === item.languageId && item.title}</>;
                  })}
              </h3>
              <p className="text-gray-500 font-normal">
                {pgTabItem &&
                  pgTabItem.pgTabItemLng &&
                  pgTabItem.pgTabItemLng.map((item, index) => {
                    return <>{languageId === item.languageId && item.text}</>;
                  })}
              </p>
            </div>
          </div>
        </>
      )) || (
        <div className="mt-4 shadow p-4 border rounded ">
          <Formik initialValues={pgTabItem}>
            {({ values, setFieldValue, setValues }) => {
              return (
                <Form>
                  <div>
                    {/* Submit Form */}
                    <div className="grid grid-cols-1 gap-6">
                      <div className="h-52 focus:outline-none group rounded border-dashed border-2 mb-2 relative">
                        {(values.img && (
                          <>
                            <div className="flex items-center justify-center p-2  h-full overflow-hidden">
                              <img
                                className="w-full h-full object-contain"
                                // style={{
                                //   maxWidth: 32,
                                //   maxHeight: 32,
                                // }}
                                alt="img"
                                src={`${window.env.REACT_APP_IMAGE_PATH}${values.img}`}
                              />{' '}
                            </div>
                            <div className="absolute group-hover:opacity-100 opacity-0 inset-0 flex items-center justify-center">
                              <AddTabImage edit={true} setField={setFieldValue} fieldName="img" />
                            </div>
                          </>
                        )) || (
                          <div className="absolute inset-0 flex items-center justify-center">
                            <AddTabImage fieldName="img" setField={setFieldValue} />
                          </div>
                        )}
                      </div>
                      {values &&
                        values.pgTabItemLng &&
                        values?.pgTabItemLng.map((item, index) => (
                          <FieldArray
                            key={index}
                            name="pgTabItemLng"
                            render={(arrayHelpers) => (
                              <>
                                {languageId === item.languageId && (
                                  <>
                                    <label>
                                      <Field
                                        className="border rounded  w-full  p-2"
                                        type="text"
                                        placeholder={t('Add Title')}
                                        name={`pgTabItemLng[${index}].title`}
                                      />
                                    </label>
                                    <label>
                                      <RichTextEditor
                                        fieldName={`pgTabItemLng[${index}].text`}
                                        fieldValue={values.pgTabItemLng[index].text}
                                        setFieldValue={setFieldValue}
                                      />
                                      {/* <Field
                                        className='border rounded  w-full  p-2'
                                        as='textarea'
                                        rows='10'
                                        placeholder='Add Content'
                                        name={`pgTabItemLng[${index}].text`}
                                      /> */}
                                    </label>
                                  </>
                                )}
                              </>
                            )}
                          />
                        ))}
                    </div>
                  </div>
                  <label className="mt-2 block">
                    <Field
                      type="number"
                      name="orderNum"
                      placeholder="Order Number"
                      className="border rounded  w-full  p-2"
                    />
                  </label>
                  <div className={`flex justify-end space-x-2 py-4 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
                    <button
                      type="button"
                      onClick={() => {
                        onSubmit(values);
                      }}
                      disabled={editTabItemMutation.isLoading}
                      className="bg-lightgreen py-2 px-2 flex justify-center text-center h-full text-sm font-bold text-white items-center hover:bg-lightgreenhover text-centerhover shadow  rounded transition duration-300 ease-in-out"
                    >
                      {t('Update Item')} {editTabItemMutation.isLoading && <LoadingCircle />}
                    </button>

                    <DeleteTabItem id={values.id} pgTabItemsHelper={pgTabItemsHelper} pgTabItemIndex={pgTabItemIndex} />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}{' '}
    </>
  );
}
