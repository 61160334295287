import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import Notification from '../../../../SharedComponents/Notification';
import { addCardItem } from '../ApiSections';

export default function DuplicateCard(props) {
  const { values, pgCardItemIndex } = props;
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  // Mutate Data to Create New Page
  const addCardItemMutation = useMutation(addCardItem, {
    onSuccess: async (data) => {
      if (data.isDone) {
        // queryClient.invalidateQueries(['sections']);
        toast(<Notification message={t('Slider Item Duplicated')} />);
        queryClient.invalidateQueries(['sections']);
      }
      queryClient.invalidateQueries(['sections']);
    },
  });
  const onSubmit = () => {
    delete values.id;
    values.orderNum = pgCardItemIndex ? pgCardItemIndex + 1 : 99;
    const pgSectionCardItemLngs = values.pgSectionCardItemLngs.map((e) => {
      delete e.pgSectionCardItemId;
      delete e.id;
      return e;
    });
    addCardItemMutation.mutate({ ...values, pgSectionCardItemLngs });
    console.log('onSubmit', values);
  };
  return (
    <button
      onClick={onSubmit}
      type="button"
      className={`bg-darkblue space-x-1 ${
        i18n.language === 'en' ? '' : 'space-x-reverse'
      } text-sm font-bold text-white flex items-center hover:bg-darkbluehover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
    >
      {t('Duplicate')}
    </button>
  );
}
