import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import Loading from '../../SharedComponents/Loading';
import { EditAnswerList, GetAllForms, GetDataOfRequest } from './ApRequests';
import inputs from './utils/utils';
import { getDateHandler } from './utils/getDateHandler';
import toast from 'react-hot-toast';
import Notification from '../../SharedComponents/Notification';
export default function EditFormData(props) {
  const { formID } = props;
  const params = useParams();
  const { status, requestId } = params;
  const [languageId, setLanguageId] = useState(1);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const editAnswerListMutation = useMutation(EditAnswerList, {
    onSuccess: (data) => {
      toast.custom(<Notification message={t('Data Saved, Successfully')} />);
      // history.push(`/request/${requestStatus + 1}/${requestId ? requestId : req}`);
    },
    onError: () => {
      toast.custom(<Notification message={t("We're sorry, you don't have permission")} error={true} />);
    },
  });
  const onSubmit = (values) => {
    editAnswerList(requestId, values);
  };
  const getSingleDataOfRequest = useQuery(['GetSingleDataOfRequest', { formID, requestId }], GetDataOfRequest, {
    retry: false,
  });

  useEffect(() => {
    queryClient.invalidateQueries(['GetSingleDataOfRequest']);
  }, [status]);

  //   get All Form Inputs from form
  const { data, isLoading, isError } = useQuery(['GetAllForms', { formId: formID }], GetAllForms, {
    retry: false,
  });
  if (isLoading || getSingleDataOfRequest.isLoading)
    return (
      <div
        className="relative bg-white"
        style={{
          minHeight: '500px',
        }}
      >
        <Loading />
      </div>
    );
  if (isError || getSingleDataOfRequest.isError)
    return (
      <>
        <div className="p-8">{t("WE Are Sorry You Don't Have The Permisssion to see this Form")}</div>
      </>
    );

  //   getInitialData Function

  const getInitialValuesWithData = () => {
    const arrayOfKeys = getSingleDataOfRequest?.data?.data?.map((item) => item.question.inputName);
    const arrayOfAnswers = getSingleDataOfRequest?.data?.data?.map((item) => item.answer);
    const object = {};
    for (let index = 0; index < arrayOfKeys?.length; index++) {
      const key = arrayOfKeys[index];
      object[key] = arrayOfAnswers[index];
    }
    return object;
  };
  const initialValues = getInitialValuesWithData();
  const editAnswerList = (outId, val) => {
    const userData = localStorage.getItem('token');
    const userId = userData ? JSON.parse(userData).userId : 0;
    const entries = Object.entries(val);
    const newArr = entries?.map((e) => e);
    const newData = newArr.map((x, index) => {
      const questionId = data.data.find((e) => e.inputName === `${x[0]}`).id;
      let l = {
        questionId: questionId,
        answer: x[1],
        userId: userId,
        formId: formID,
        creationDateString: getDateHandler(),
        createDate: new Date(),
        RequestDataId: parseInt(outId),
        requestData: {
          requestStatus: 1,
        },
      };
      return l;
    });
    editAnswerListMutation.mutate(newData);
  };
  return (
    <>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, setFieldValue }) => (
          <Form className="flex flex-col   relative   px-6 py-6 text-gray-600 font-bold  ">
            {data.data.map((item, index) => {
              if (inputs[item.qtypeId] === 'textarea') {
                return (
                  <label key={index} className={`flex  flex-col mb-8 justify-start space-y-2`}>
                    <span>{item.frmQuestionLngs.filter((item) => item.languageId === languageId)[0]?.title}</span>
                    <Field
                      name={item?.inputName}
                      value={values[item?.inputName]}
                      className="border rounded py-2 px-3"
                      type={inputs[item.qtypeId]}
                      as="textarea"
                      onChange={(e) => {
                        setFieldValue(item?.inputName, e.target.value);
                      }}
                    />
                  </label>
                );
              }
              return (
                <label key={index} className={`flex  flex-col mb-8 justify-start space-y-2`}>
                  <span>{item.frmQuestionLngs.filter((item) => item.languageId === languageId)[0]?.title}</span>
                  <Field
                    name={item?.inputName}
                    value={values[item?.inputName]}
                    className="border rounded py-2 px-3"
                    type={inputs[item.qtypeId]}
                    checked={values[item?.inputName]}
                    onChange={(e) => {
                      if (inputs[item.qtypeId] === 'checkbox') {
                        setFieldValue(item?.inputName, Boolean(!values[item?.inputName]));
                      } else {
                        setFieldValue(item?.inputName, e.target.value);
                      }
                    }}
                  />
                </label>
              );
            })}
            <button
              type="submit"
              className="bg-darkblue w-24 flex justify-center items-center hover:bg-darkbluehover text-white px-4 py-2 rounded"
            >
              {t('Save')}
              {/* {addAnswerListMutation.isLoading || (getRequestIdMutation.isLoading && <LoadingCircle />)} */}
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
}
