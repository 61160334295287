import { useQuery } from 'react-query';

import { Fragment, useEffect, useState } from 'react';
import Loading from '../../../SharedComponents/Loading';
import ChevronLeft from '../../../SharedComponents/Svg/ChevronLeft';
import ChevronRight from '../../../SharedComponents/Svg/ChevronRight';
import { useParams } from 'react-router-dom';
import DeleteFormQuestionOptions from './DeleteFormQuestionOptions';
import { getFormQuestionOption, getLanguages } from '../ApiForms';
import CreateFormQuestionOptions from './CreateFormQuestionOptions';
import EditFormQuestionOptions from './EditFormQuestionOptions';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../../../SharedComponents/BreadCrumbs';
import TablePagination from '../../../SharedComponents/TablePagination';
import LanguageList from '../../../SharedComponents/LanguageList';

const ToggleButton = (props) => {
  const { statusId } = props;
  const [activeToggle, setActiveToggle] = useState(statusId);
  return (
    <button
      onClick={() => {
        setActiveToggle(!activeToggle);
      }}
      className="flex items-center cursor-pointer focus:outline-none"
    >
      {/* <!-- toggle --> */}
      <div className="relative">
        {/* <!-- line --> */}
        <div className={` w-12 h-6 ${activeToggle ? 'bg-lightgreen' : 'bg-gray-400'}  rounded-full shadow-inner`}></div>
        {/* <!-- dot --> */}
        <div
          className={`transition-all ease-in-out duration-700 absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 ${
            activeToggle ? 'right-0' : 'left-0'
          } `}
        ></div>
      </div>
      {/* <!-- label --> */}
    </button>
  );
};
export default function FormQuestionOptions() {
  const params = useParams();
  const { typeId } = params;
  const [entries, setEntries] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [languageId, setLanguageId] = useState(1);
  const { t, i18n } = useTranslation();

  // Query Data To Fetch Languages
  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });

  useEffect(() => {
    setLanguageId(languages.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [languages.data]);

  let initialState = {
    typeId: typeId,
    paginationFilter: {
      pageNumber: pageNumber,
      pageSize: entries,
      isPaging: true,
      langId: 0,
    },
  };

  const { data, paginationResult, isDone, isLoading, isError, refetch } = useQuery(
    ['formQuestionOption', initialState],
    getFormQuestionOption,
    {
      staleTime: 99900,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );
  let paginationCount = [];

  useEffect(() => {
    refetch();
  }, [entries, paginationResult]);
  if (isLoading) return <Loading />;
  if (isError) return 'error';

  for (var i = 1; i <= data.paginationResult.numberOfPages; i++) {
    paginationCount.push(i);
  }
  // to control how many rows to show in table

  const onChangeEntries = (e) => {
    setEntries(e.target.value);
  };

  const tabltHeader = [{ title: 'Title' }, { title: 'Options', className: 'w-64' }];
  return (
    <>
      <header className="flex justify-between items-center">
        <BreadCrumbs to="/formQuestionOptionsTypes" name={t('Form Question Options')} />

        {/* <div className="flex items-center bg-white rounded border shadow">
          <div className="pl-4 pr-12 py-4 relative overflow-hidden">
            <a href="/">
              <Home />
            </a>
            <div className="text-gray-100">
              <hr className="w-11 border text-gray-50 border-gray-50 absolute transform -rotate-45 right-0 bottom-0 mb-3" />
              <hr className="w-11 border text-gray-50 border-gray-50 absolute transform rotate-45 right-0 top-0 mt-3" />
            </div>
          </div>
          <div className="pl-4 pr-12 py-4 relative overflow-hidden">
            <a href="/dashboard">Dashboard</a>
            <div className="text-gray-100">
              <hr className="w-11 border text-gray-50 border-gray-50 absolute transform -rotate-45 right-0 bottom-0 mb-3" />
              <hr className="w-11 border text-gray-50 border-gray-50 absolute transform rotate-45 right-0 top-0 mt-3" />
            </div>
          </div>

          <div className="pl-4 pr-12 py-4">
            <a>Form Question Options</a>
          </div>
        </div> */}
        <div>
          <CreateFormQuestionOptions typeId={typeId} languages={languages} />
        </div>
      </header>
      <div className="my-6">
        <h2 className="text-2xl font-bold">{t('Form Question Option Types')}</h2>
      </div>
      {/* Table */}
      <div className="bg-white rounded border shadow-md ">
        <div className="flex justify-between relative items-center mb-4 p-6">
          <div className="transform -translate-y-2">
            <div
              className={`flex items-center ${i18n.language === 'en' ? '' : 'space-x-reverse'} space-x-2 text-gray-600`}
            >
              <span>{t('Show')} </span>
              <select className="border rounded w-20 py-2 px-1" onChange={onChangeEntries} defaultValue={entries}>
                <option value="10">10 </option>
                <option value="20">20 </option>
                <option value="30">30 </option>
              </select>
              <span>{t('entries')}</span>
            </div>
          </div>
          <div className="flex transform  relative z-10 ">
            {data && languages && (
              <LanguageList languages={languages.data?.data} languageId={languageId} setLanguageId={setLanguageId} />
            )}
          </div>
          <hr className="border w-full absolute top-0 left-0 z-0 mt-16" />
        </div>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className=" overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y-2 divide-gray-200">
                  <thead className=" text-sm font-bold text-black">
                    <tr>
                      {tabltHeader.map((e) => {
                        return (
                          <th
                            key={e.title}
                            scope="col"
                            className={`px-6 py-3 ${i18n.language === 'en' ? 'text-left' : 'text-right'} uppercase  ${
                              e.className
                            }`}
                          >
                            {t(e.title)}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal">
                    {data.data.map((form) => {
                      return (
                        <tr key={form.id} className="">
                          <td className="px-6 py-4 whitespace-nowrap">
                            {form.frmQoptionLngs.map((e) => {
                              return (
                                <Fragment key={e.id}>
                                  {languageId === e.languageId && (
                                    <>
                                      <div className="flex items-center space-x-2">
                                        <b>{e.title}</b>
                                      </div>
                                    </>
                                  )}
                                </Fragment>
                              );
                            })}
                          </td>

                          <td
                            className={`px-6 py-4 whitespace-nowrap flex items-center ${
                              i18n.language === 'en' ? '' : 'space-x-reverse'
                            } space-x-2`}
                          >
                            <EditFormQuestionOptions form={form} />
                            <DeleteFormQuestionOptions id={form.id} />
                          </td>
                        </tr>
                      );
                    })}
                    {/* {data &&
                      data.data &&
                      data?.data?.map((page) => {
                        return (
                          <tr key={page.id} className=''>
                            <td className='px-6 py-4 whitespace-nowrap'>
                              <div className=' '>
                                {page.pgPageLngs[0]?.title}
                              </div>
                            </td>

                            <td className='px-6 py-4 whitespace-nowrap flex items-center space-x-2'>
                        

                              <a
                                href={`./pages/${page.id}`}
                                className='bg-green-100 text-green-800 hover:bg-green-200  p-1 rounded transition duration-300 ease-in-out'
                              >
                                <PecnilAlt className='w-5 h-5' />
                              </a>
                              <DeleteForm id={page.id} />
                            </td>
                          </tr>
                        );
                      })} */}
                  </tbody>
                </table>

                {/* pagination */}
                <TablePagination
                  data={data}
                  paginationCount={paginationCount}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
