import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import Loading from '../../../SharedComponents/Loading';
import Calender from '../../../SharedComponents/Svg/Calender';
import Eye from '../../../SharedComponents/Svg/Eye';
import PecnilAlt from '../../../SharedComponents/Svg/PencilAlt';
import Trash from '../../../SharedComponents/Svg/Trash';
import { deleteAsset } from '../ApiAssets';
import EditAsset from './EditAsset';
import ViewAsset from './ViewAsset';

export default function AssetStyleBlock(props) {
  const { asset, languageId, index } = props;
  const { fileUrl, id } = asset;
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const deleteButton = useMutation(deleteAsset, {
    onSuccess: () => {
      queryClient.invalidateQueries(['assetFolder']);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    },
  });

  const onDelete = async (e) => {
    setLoading(true);
    try {
      await deleteButton.mutate({ id });
    } catch (error) {
      console.log('error', error);
    }
  };

  if (loading)
    return (
      <div className="relative border">
        <Loading message="Deleting" />
      </div>
    );
  return (
    <>
      <div key={asset.id} className="border bg-gray-100 ">
        <div className="relative h-64 bg-white">
          <img className="object-cover h-full w-full" alt="alt" src={`${window.env.REACT_APP_IMAGE_PATH}${fileUrl}`} />
          <div className="absolute bottom-0 left-0 w-full p-2">
            <div className="absolute z-0  bottom-0 left-0 w-full h-full bg-black opacity-60"></div>
            <div className={`flex justify-end relative space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
              <EditAsset asset={asset} />
              <ViewAsset image={`${window.env.REACT_APP_IMAGE_PATH}${fileUrl}`} />
              <button
                onClick={onDelete}
                className="bg-pink hover:bg-pinkhover transition duration-500 text-white rounded p-1"
              >
                <Trash className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
        {asset.astAssetLngs.map((e) => {
          return (
            <>
              {languageId === e.languageId && (
                <div key={e.id} className="bg-gray-100 p-4">
                  <b className="text-xl block mb-1">{e.title}</b>
                  <small
                    className={`flex items-center text-gray-400 ${
                      i18n.language === 'en' ? '' : 'space-x-reverse'
                    }  space-x-1 text-xs mb-2`}
                  >
                    <Calender className="w-3 h-3" />
                    <span>15/12/2020 02.30 PM</span>
                  </small>
                  <span className="text-sm text-gray-500 block">{e.alt}</span>
                  <span className="text-sm text-gray-500 block">{e.description}</span>
                  {/* <div className='my-2 block flex space-x-2'>
                <span className='text-xs text-gray-500 border rounded p-1 border-gray-400  inline-block'>
                  Mission,page
                </span>
                <span className='text-xs text-gray-500 border rounded p-1 border-gray-400  inline-block'>
                  Title news name, News
                </span>
              </div> */}
                  <span className="text-sm text-gray-400 block">Uploaded By: Mahmoud</span>
                </div>
              )}{' '}
            </>
          );
        })}
      </div>
    </>
  );
}
