import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { getUserInfo } from './ApiFormAnswer';
import { DateTime } from 'luxon';
import Loading from '../../../SharedComponents/Loading';

export default function UserDetails(props) {
  const { userId } = props;
  const userDataMutation = useMutation('userData', getUserInfo);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    userDataMutation.mutate(userId);
  }, []);
  if (userDataMutation.isLoading) return <Loading />;
  return (
    <div>
      {userDataMutation.data && userDataMutation.data[0]?.fullName && (
        <div className="flex pb-3">
          <h2 className={`${i18n.language === 'en' ? 'mr-1' : 'ml-1'} font-semibold text-gray-700 text-sm`}>
            {t('Name')} :{' '}
          </h2>
          <h3 className={`${i18n.language === 'en' ? 'ml-4' : 'mr-4'} text-gray-700 text-sm font-semibold`}>
            {userDataMutation.data[0]?.fullName}
          </h3>
        </div>
      )}
      {userDataMutation.data && userDataMutation.data[0]?.email && (
        <div className="flex pb-3">
          <h2 className={`${i18n.language === 'en' ? 'mr-1' : 'ml-1'} font-semibold text-gray-700 text-sm`}>
            {t('Email')} :{' '}
          </h2>
          <h3 className={`${i18n.language === 'en' ? 'ml-4' : 'mr-4'} text-gray-700 text-sm font-semibold`}>
            {userDataMutation.data[0]?.email}
          </h3>
        </div>
      )}
      {userDataMutation.data && userDataMutation.data[0]?.phone && (
        <div className="flex pb-3">
          <h2 className={`${i18n.language === 'en' ? 'mr-1' : 'ml-1'} font-semibold text-gray-700 text-sm`}>
            {t('Phone')} :{' '}
          </h2>
          <h3 className={`${i18n.language === 'en' ? 'ml-4' : 'mr-4'} text-gray-700 text-sm font-semibold`}>
            0{userDataMutation.data[0]?.phone}
          </h3>
        </div>
      )}
      {userDataMutation.data && userDataMutation.data[0]?.address && (
        <div className="flex pb-3">
          <h2 className={`${i18n.language === 'en' ? 'mr-1' : 'ml-1'} font-semibold text-gray-700 text-sm`}>
            {t('Address')} :{' '}
          </h2>
          <h3 className={`${i18n.language === 'en' ? 'ml-4' : 'mr-4'} text-gray-700 text-sm font-semibold`}>
            {userDataMutation.data[0]?.address}
          </h3>
        </div>
      )}
      {userDataMutation.data && userDataMutation.data[0]?.job && (
        <div className="flex pb-3">
          <h2 className={`${i18n.language === 'en' ? 'mr-1' : 'ml-1'} font-semibold text-gray-700 text-sm`}>
            {t('Job')} :{' '}
          </h2>
          <h3 className={`${i18n.language === 'en' ? 'ml-4' : 'mr-4'} text-gray-700 text-sm font-semibold`}>
            {userDataMutation.data[0]?.job}
          </h3>
        </div>
      )}
      <div className="flex pb-3">
        <h2 className={`${i18n.language === 'en' ? 'mr-1' : 'ml-1'} font-semibold text-gray-700 text-sm`}>
          {t('Last Login')} :{' '}
        </h2>
        <h3 className={`${i18n.language === 'en' ? 'ml-4' : 'mr-4'} text-gray-700 text-sm font-semibold`}>
          {userDataMutation.data && DateTime.fromISO(userDataMutation.data[0]?.lastLoginDate).toISODate()}
        </h3>
      </div>
    </div>
  );
}
