// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Forms: 'الإستمارات',
  Form: 'الاستمارة',
  'Creation Date': 'تاريخ الاإنشاء',
  'Created By': 'قام بإنشائها',
  'Create Form': 'إنشاء إستمارة',
  'Submit Button Text': 'نص زر الحفظ',
  'Reject Response Message': 'رسالة الرفض',
  'Action Failed Message': 'رسالة الفشل',
  'Action Success Message': 'رسالة النجاح',
  'Unique Url': 'العنوان المميز',
  'Delete Form': 'حذف الإستمارة',
  'Are you sure you want to delete this form? All the data will be permanently removed from our servers forever. This action cannot be undone':
    'هل أنت متأكد من أنك تريد حذف هذا الإستمارة ؟سيتم إزالتها بشكل دائم من خوادمنا إلى الأبد.لا يمكن التراجع عن هذا الإجراء',
  'Create Field': 'إنشاء إستمارة',
  Placeholder: 'عنوان نائب للمحتوى',
  Type: 'نوع',
  'Success Message': 'رسالة ناجحه',
  'Enable Validation?': 'تمكين التحقق من صحه الحقول؟',
  'Validation Message': 'رسالة التحقق من صحه الحقول',
  'Enable Condition?': 'تمكين الشروط؟',
  'Show If': 'إظهار إذا',
  Question: 'سؤال',
  Contains: 'يتضمن',
  'contain Value': 'تحتوي على قيمة',
  'Are you sure you want to delete this Form? All the data will be permanently removed from our servers forever. This action cannot be undone':
    'هل أنت متأكد أنك تريد حذف الإستمارة هذا؟سيتم إزالتها بشكل دائم من خوادمنا إلى الأبد.لا يمكن التراجع عن هذا الإجراء',
  'Success Message?': 'رسالة النجاح؟',
  'Failed Message?': 'رسالة الفشل؟',
  'Reject Message': 'رساله الرفض',
  'Success Redirect URL?': 'نجاح اعاده التوجيه؟',
  'Success Url': 'نجاح اعاده التوجيه',
  'Enter Number In Seconds': 'أدخل الرقم في ثوان',
  'Failed Redirect URL?': 'فشل اعاده التوجيه؟',
  'Use Recaptcha?': ' ؟recaptcha استخدام',
  'Server Key': 'مفتاح الخادم',
  'Public Key': 'المفتاح الرئسي',
  'Reject Response?': 'سبب الرفض؟',
  'Select Date': 'حدد تاريخ',
  'Update Settings': 'تحديث الاعدادات',
  'Failed Url': 'فشل اعاده التوجيه',
  'Form Status, Updated': 'تم تغيير حالة الاستمارة بنجاح',
  inputName: 'الإسم التعريفي',
  'Show Requests': 'رؤيه الطلبات',
  Preparing: 'يتم تحضير الملف',
  'Last Login': 'آخر تسجيل دخول',
  'User Details': 'بيانات المستخدم',
  Job: 'المهنة',
  Phone: 'رقم الهاتف',
};
