import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import BreadCrumbs from '../../../SharedComponents/BreadCrumbs';
import CreateField from './CreateField';
import FormQuestions from './FormQuestions';
import GeneralFormSettings from './GeneralFormSettings';

export default function Form(props) {
  const params = useParams();
  const formId = params.id;
  const [languageId, setLanguageId] = useState(1);
  const [formTitles, setFormTitles] = useState([]);
  const { t } = useTranslation();
  return (
    <>
      <header className="flex justify-between items-center">
        <BreadCrumbs to="/forms" name={t('Forms')} />

        <div>
          <CreateField formId={formId} />
        </div>
      </header>

      <div className="grid grid-cols-3 gap-6 mt-4">
        <div className="bg-white shadow border rounded p-4 col-span-2">
          <FormQuestions languageId={languageId} setLanguageId={setLanguageId} formTitles={formTitles} />
        </div>
        <div className="bg-white shadow border rounded p-4">
          <GeneralFormSettings setFormTitles={setFormTitles} languageId={languageId} />
        </div>
      </div>
    </>
  );
}
