import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';

import { Field, FieldArray, Form, Formik } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import LanguageList from '../../../../SharedComponents/LanguageList';
import Notification from '../../../../SharedComponents/Notification';
import PlusCircle from '../../../../SharedComponents/Svg/PlusCircle';
import GalleryAlbumsList from './GalleryAlbumsList';
import SelectVideo from './SelectVideo';

export default function AddCategoryAgendaItem(props) {
  const {
    languages,
    pgSectionCategoryId,
    languageId,
    values,
    pgSectionCategoryAgendaParentId,
    pgSectionCategoryAgendaId,
  } = props;
  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const pgSectionCategoryAgendaItemsLngs = languages.map((language) => {
    return {
      languageId: language.id,
      title: '',
      instractorName: '',
    };
  });

  const pgSectionCategoryAgendaItemsJSON = {
    timeFrom: '',
    timeTo: '',
    videoId: 0,
    videoUrl: '',
    pgSectionCategoryAgendaItemsLngs: pgSectionCategoryAgendaItemsLngs,
  };
  const initValues = {
    timeFrom: '',
    timeTo: '',
    videoId: 0,
    videoUrl: '',
    pgSectionCategoryAgendaId: pgSectionCategoryAgendaId,
    pgSectionCategoryAgendaItemsLngs: pgSectionCategoryAgendaItemsLngs,
  };

  const queryClient = useQueryClient();
  const addCategoryAgendaMutation = useMutation(addCategoryAgenda, {
    onSuccess: () => {
      toast(<Notification message={t('Added, Successfully')} />);

      queryClient.invalidateQueries('sections');
      closeModal();
    },
  });

  const onSubmit = (e) => {
    console.log('e', e);
    addCategoryAgendaMutation.mutate(e);
  };
  return (
    <>
      <div className="flex items-center justify-center">
        <button
          type="button"
          onClick={openModal}
          className={`bg-lightgreen space-x-1 ${
            i18n.language === 'en' ? '' : 'space-x-reverse'
          } text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
        >
          <PlusCircle className="w-5 h-5" /> <span>{t('Add New Agenda Item')}</span>
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={closeModal}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed bg-black opacity-70 inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            {/* <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span> */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className="inline-block w-full overflow-y-scroll p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
                style={{
                  height: '90vh',
                }}
              >
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  {t('Add Agenda Item')}
                </Dialog.Title>
                <div className="mt-2 ">
                  <Formik onSubmit={onSubmit} enableReinitialize initialValues={initValues}>
                    {({ values, setFieldValue, setValues, errors }) => {
                      console.log('values', values);
                      console.log('values', errors);
                      return (
                        <>
                          <div className="flex space-x-2">
                            <label>
                              <Field
                                className="border rounded  w-full  p-2 mt-2 "
                                type="time"
                                placeholder={t('Time From')}
                                name={`timeFrom`}
                              />
                            </label>
                            <label>
                              <Field
                                className="border rounded  w-full  p-2 mt-2 "
                                type="time"
                                placeholder={t('Time To')}
                                name={`timeTo`}
                              />
                            </label>
                            <ParentField
                              generalIndex={1}
                              name={`pgSectionCategoryAgendaItemsLngs`}
                              values={values}
                              languageId={languageId}
                            />
                          </div>

                          <div className="flex justify-end mt-4">
                            <button
                              type="button"
                              className="bg-green-500 text-white px-4 py-2 rounded"
                              onClick={() => {
                                onSubmit(values);
                              }}
                            >
                              {t('Save Agenda Item')}
                            </button>
                          </div>
                        </>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

const ParentField = (props) => {
  const { values, name, languageId, generalIndex } = props;
  const { t, i18n } = useTranslation();

  return (
    <>
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <>
            {values?.pgSectionCategoryAgendaItemsLngs?.map((e, index) => {
              return (
                <Fragment key={index}>
                  {languageId === e.languageId && (
                    <>
                      <label>
                        <Field
                          className="border rounded  w-full  p-2 mt-2 "
                          type="text"
                          placeholder={t('Add Title')}
                          name={`pgSectionCategoryAgendaItemsLngs[${index}].title`}
                        />
                      </label>
                      <label>
                        <Field
                          className="border rounded  w-full  p-2 mt-2 "
                          type="text"
                          placeholder={t('Add Title')}
                          name={`pgSectionCategoryAgendaItemsLngs[${index}].instractorName`}
                        />
                      </label>
                    </>
                  )}
                </Fragment>
              );
            })}
          </>
        )}
      />
    </>
  );
};

export const addCategoryAgenda = async (e) => {
  const data = await axios.post('/PgSection/AddCategoryAgendaItem', e);
  return data;
};
