import { Field, FieldArray, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import Modal from '../../SharedComponents/Modal';
import PlusCircle from '../../SharedComponents/Svg/PlusCircle';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Loading from '../../SharedComponents/Loading';
import * as yup from 'yup';
import PecnilAlt from '../../SharedComponents/Svg/PencilAlt';
import { editCategory, getLanguages } from './ApiCategories';
import { useTranslation } from 'react-i18next';
import LanguageList from '../../SharedComponents/LanguageList';

export default function EditCategory(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { id } = props.id ? props : 1;
  const { category } = props;
  const queryClient = useQueryClient();
  // Mutate Data to Create New Category
  const editCategoryMutation = useMutation(editCategory, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['categories']);
        setIsOpen(false);
      }
    },
  });

  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-green-100 text-green-800 hover:bg-green-200  p-1 rounded transition duration-300 ease-in-out"
      >
        <PecnilAlt className="w-5 h-5" />
      </button>

      {isOpen && (
        <ModalContainer
          id={id}
          editCategoryMutation={editCategoryMutation}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          category={category}
        />
      )}
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, editCategoryMutation, category } = props;
  const [languageId, setLanguageId] = useState(1);
  const { t, i18n } = useTranslation();
  // Query Data To Fetch Languages
  const { data, isLoading, isError } = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  useEffect(() => {
    setLanguageId(data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [data]);

  // create a loop for localisation
  const pgPageCategoryLngs = data?.data?.map((language) => ({
    languageId: language.id,
    name: '',
  }));
  const initialValues = category;

  const onSubmit = async (e) => {
    try {
      editCategoryMutation.mutate(e);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      {data && data.data && (
        <Formik onSubmit={onSubmit} validateOnBlur={false} validateOnChange={false} initialValues={initialValues}>
          {({ values, setFieldValue, errors }) => {
            return (
              <Form>
                <Modal
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  onSubmit={onSubmit}
                  title={t('Create Category')}
                  isLoading={editCategoryMutation.isLoading}
                  add={true}
                >
                  {isLoading && <Loading />}
                  {isError && 'error'}
                  <LanguageList
                    className="flex transform translate-y-1 relative z-10 mb-4"
                    languages={data?.data}
                    languageId={languageId}
                    setLanguageId={setLanguageId}
                  >
                    <hr className="border w-full absolute bottom-0 left-0 z-0" />
                  </LanguageList>

                  {/* Submit Form */}

                  {values &&
                    values.pgPageCategoryLngs &&
                    values?.pgPageCategoryLngs.map((asset, index) => (
                      <FieldArray
                        key={index}
                        name="pgPageCategoryLngs"
                        render={(arrayHelpers) => (
                          <>
                            {languageId === asset.languageId && (
                              <>
                                <label>
                                  <b
                                    className={`font-semibold block text-gray-700 ${
                                      i18n.language === 'en' ? '' : 'text-right'
                                    }`}
                                  >
                                    {t('Name')}
                                  </b>
                                  <Field
                                    className="border rounded  w-full mb-4 p-1"
                                    type="text"
                                    name={`pgPageCategoryLngs[${index}].name`}
                                  />
                                </label>
                              </>
                            )}
                          </>
                        )}
                      />
                    ))}
                </Modal>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};
