// eslint-disable-next-line import/no-anonymous-default-export
export default {
  'Form Question Option Types': 'أنواع خيارات الأسئلة ',
  'form QOptionsTypes': 'أنواع خيارات الأسئلة ',
  'Create Form Question Option Type': 'إنشاء نموذج سؤال',
  'Create Form Question Option Types': 'إنشاء نوع نموذج الأسئلة ',
  'Form Question Options': 'خيارات الأسئلة',
  'Delete form question Option': 'حذف السؤال',
  'Are you sure you want to delete this form question Option? All the data will be permanently removed from our servers forever. This action cannot be undone':
    'هل أنت متأكد من أنك تريد حذف السؤال هذا؟سيتم إزالتها بشكل دائم من خوادمنا إلى الأبد.لا يمكن التراجع عن هذا الإجراء',
  'Delete form question type': 'حذف نوع السؤال',
  'Are you sure you want to delete this form question type? All the data will be permanently removed from our servers forever. This action cannot be undone':
    'هل أنت متأكد أنك تريد حذف نوع السؤال هذا؟سيتم إزالتها بشكل دائم من خوادمنا إلى الأبد.لا يمكن التراجع عن هذا الإجراء',
};
