import { PaperClipIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import PaperClip from '../../../SharedComponents/Svg/PaperClip';
import chatVoice from '../../../assets/img/chatVoice.svg';
import chatFile from '../../../assets/img/chatFile.svg';
import chatImage from '../../../assets/img/chatImage.svg';
import { useState } from 'react';
import { useFormikContext } from 'formik';

export default function MessageType(props) {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext();
  const [isOpen, setIsOpen] = useState(false);
  // isImage: false,
  // isVoiceChat: false,
  // isFile: false,

  return (
    <div className="relative">
      <div
        className={`absolute -mt-2 divide-y transform -translate-y-full  bg-white text-gray-500   p-1 rounded shadow border text-sm ${
          isOpen ? 'scale-100' : 'scale-0'
        } `}
      >
        <button
          type="button"
          onClick={() => {
            setFieldValue('isVoiceChat', false);
            setFieldValue('isImage', true);
            setFieldValue('isFile', false);
            setIsOpen(false);
          }}
          className="whitespace-nowrap capitalize px-2 pr-6 py-2 hover:bg-gray-50 font-semibold flex space-x-2 rtl:space-x-reverse items-center"
        >
          <img src={chatImage} alt="chatImage" />
          <span>{t('upload image')}</span>
        </button>
        {/* <button
          type="button"
          onClick={() => {
            setFieldValue('isVoiceChat', true);
            setFieldValue('isImage', false);
            setFieldValue('isFile', false);
            setIsOpen(false);
          }}
          className="whitespace-nowrap capitalize px-2 pr-6 py-2 hover:bg-gray-50 font-semibold  flex space-x-2 rtl:space-x-reverse items-center"
        >
          <img src={chatVoice} alt="chatVoice" />
          <span>{t('upload Voice')}</span>
        </button> */}
        <button
          type="button"
          onClick={() => {
            setFieldValue('isVoiceChat', false);
            setFieldValue('isImage', false);
            setFieldValue('isFile', true);
            setIsOpen(false);
          }}
          className="whitespace-nowrap capitalize px-2 pr-6 py-2 hover:bg-gray-50 font-semibold  flex space-x-2 rtl:space-x-reverse items-center"
        >
          <img src={chatFile} alt="chatFile" />
          <span>{t('upload File')}</span>
        </button>
      </div>
      <button
        type="button"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className="border-2 border-blue-200 p-2 rounded-full hover:bg-gray-50 "
      >
        <PaperClip />
      </button>
    </div>
  );
}
