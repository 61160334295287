import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useTable } from 'react-table';

export default function CustomTable({ columns, data }) {
  const { t, i18n } = useTranslation();

  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  // Render the UI for your table
  return (
    <div className="flex  flex-col">
      <div className="-my-2  sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="  border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y-2 divide-gray-200" {...getTableProps()}>
              <thead className=" text-sm font-bold text-black">
                {headerGroups.map((headerGroup, index) => (
                  <tr className="" key={index} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => (
                      <th
                        className={`px-6 py-3 ${i18n.language === 'en' ? 'text-left' : 'text-right'}   uppercase   `}
                        key={index}
                        {...column.getHeaderProps()}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal"
                {...getTableBodyProps()}
              >
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr key={i} {...row.getRowProps()}>
                      {row.cells.map((cell, index) => {
                        return (
                          <td className="px-6 py-4 whitespace-nowrap" key={index} {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
