import axios from 'axios';

export const getRoles = async () => {
  const { data } = await axios.get('/UserRole');
  return data;
};

export const editRole = async (e) => {
  const mutation = await axios.post(`/UserRole/Edit`, e);
  const data = mutation.data.data;
  const isDone = mutation.data.isDone;
  return { data, isDone };
};

export const addRole = async (e) => {
  const mutation = await axios.post(`/UserRole?name=${e.name}`);
  const data = mutation.data.data;
  const isDone = mutation.data.isDone;
  return { data, isDone };
};
export const deleteRole = async (id) => {
  const mutation = await axios.get(`/UserRole/Delete?id=${id}`);
  const data = mutation.data.data;
  const isDone = mutation.data.isDone;
  return { data, isDone };
};

// export const getPermissions = async (roleName) => {
//   const { data } = await axios.get(`/Permission/GetRole/${roleName}`);
//   return data;
// };

export const getPermissions = (roleName) => {
  return async () => {
    const { data } = await axios.get(`/Permission/GetRole/${roleName}`);
    return data;
  };
};

export const editPermissions = async (e) => {
  const mutation = await axios.post(`/Permission/Role`, e);
  const data = mutation.data.data;
  const isDone = mutation.data.isDone;
  return { data, isDone };
};
