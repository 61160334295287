import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import AddYoutube from '../Views/Gallery/Components/AddYoutube';

export default function VideoContainer(props) {
  const regEx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
  const { url } = props;
  const { setFieldValue } = useFormikContext();
  const youtube = url?.match(regEx);
  const { t } = useTranslation();
  return (
    <>
      <video className="h-96 w-full" controls>
        <source src={`${window.env.REACT_APP_IMAGE_PATH}${url}`} type="video/mp4" />
        {t('Your browser does not support the video tag.')}
      </video>

      {url ? (
        <div className="  p-4">
          <AddYoutube edit={true} setField={setFieldValue} />
        </div>
      ) : (
        <div className="absolute inset-0 flex items-center justify-center">
          <AddYoutube setField={setFieldValue} />
        </div>
      )}
    </>
  );
}
