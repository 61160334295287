import { Field, FieldArray, Formik } from 'formik';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryClient, useMutation, useQueryClient } from 'react-query';
import Modal from '../../../../SharedComponents/Modal';
import Notification from '../../../../SharedComponents/Notification';
import PlusCircle from '../../../../SharedComponents/Svg/PlusCircle';
import RichTextEditor from '../../Pages/RichTextEditor';
import { addStatisticItem } from './ApiStatistics';

export default function AddStaticItem(props) {
  const { pgStatisticsId, languages, orderNumber, addStaticItemMutation } = props;
  const [isOpen, setIsOpen] = useState();
  const { t, i18n } = useTranslation();

  return (
    <>
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className={`bg-lightgreen space-x-1 ${
          i18n.language === 'en' ? '' : 'space-x-reverse'
        } text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
      >
        <PlusCircle className="w-5 h-5" /> <span>{t('Add New Statistic')}</span>
      </button>

      <ModalContainer
        orderNumber={orderNumber}
        pgStatisticsId={pgStatisticsId}
        isOpen={isOpen}
        languages={languages}
        setIsOpen={setIsOpen}
        addStaticItemMutation={addStaticItemMutation}
      />
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, languages, pgStatisticsId, orderNumber, addStaticItemMutation } = props;
  const [languageId, setLanguageId] = useState(1);
  const { t, i18n } = useTranslation();

  const pgStatisticsItemLngs = languages?.data?.data?.map((language) => ({
    languageId: language.id,
    title: '',
    measureUnit: '',
  }));

  const initialValues = {
    // number: 0,
    // measureUnit: 0,
    pgStatisticsId: pgStatisticsId,
    pgStatisticsItemLngs: pgStatisticsItemLngs,
    orderNum: orderNumber,
  };

  const onSubmit = async (e) => {
    await addStaticItemMutation.mutate(e);
  };
  return (
    <>
      <Formik initialValues={initialValues}>
        {({ values, setFieldValue }) => {
          return (
            <>
              <Modal
                add={true}
                title={t('Add New Statistic')}
                className="sm:max-w-3xl"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                submitType="button"
                onSubmit={() => onSubmit(values)}
              >
                {languages && languages.data && languages.data.data && (
                  <div className="flex transform translate-y-1 relative z-10 mb-4">
                    {languages?.data?.data?.map((language) => (
                      <div
                        key={language.id}
                        className={`flex justify-center items-center space-x-2 ${
                          languageId === language.id ? 'text-pagetabhover border-pagetabhover' : ''
                        }   hover:text-pagetabhover pb-2 px-4 z-50 border-b-2 hover:border-pagetabhover transition duration-300 ease-in-out cursor-pointer `}
                        onClick={(e) => setLanguageId(language.id)}
                      >
                        <span>{language.name}</span>
                      </div>
                    ))}
                    <hr className="border w-full absolute bottom-0 left-0 z-0" />
                  </div>
                )}
                {/* Submit Form */}
                <FieldArray
                  name="pgStatisticsItemLngs"
                  render={(arrayHelpers) => (
                    <>
                      {arrayHelpers.form.values.pgStatisticsItemLngs.map((e, index) => {
                        return (
                          <Fragment key={index}>
                            {languageId === e.languageId && (
                              <>
                                <label>
                                  <Field
                                    className="border rounded  w-full  p-2 mb-2"
                                    type="text"
                                    placeholder={t('Add Title')}
                                    name={`pgStatisticsItemLngs[${index}].title`}
                                  />
                                </label>
                                <label>
                                  <div className="mb-2">
                                    <RichTextEditor
                                      fieldName={`pgStatisticsItemLngs[${index}].measureUnit`}
                                      fieldValue={values.pgStatisticsItemLngs[index].measureUnit}
                                      setFieldValue={setFieldValue}
                                    />
                                  </div>
                                </label>
                              </>
                            )}
                          </Fragment>
                        );
                      })}
                    </>
                  )}
                />
                <Field
                  type="number"
                  name="number"
                  className="border rounded  w-full  p-2 mb-2"
                  placeholder={t('Add Number')}
                />
                <Field
                  type="number"
                  name="measureUnit"
                  className="border rounded  w-full  p-2 mb-2"
                  placeholder={t('Add Measure Unit')}
                />
                <Field
                  type="number"
                  name="orderNum"
                  className="border rounded  w-full  p-2 mb-2"
                  placeholder={t('Add Order Number')}
                />
              </Modal>
            </>
          );
        }}
      </Formik>
    </>
  );
};
