import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import Modal from '../../SharedComponents/Modal';
import PecnilAlt from '../../SharedComponents/Svg/PencilAlt';
import { editNewsLetter, editRole } from './ApiNewsLetter';

export default function EditNewsLetter(props) {
  const { newsletter } = props;
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const { mutate, data } = useMutation(editNewsLetter, {
    onSuccess: () => {
      queryClient.invalidateQueries(['newsLetters']);
      setIsOpen(false);
    },
  });
  const onSubmit = async (e) => {
    try {
      await mutate(e);
      if (data?.isDone) {
        setIsOpen(false);
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <>
      <button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className='bg-green-100 text-green-800 hover:bg-green-200  p-1 rounded transition duration-300 ease-in-out'
      >
        <PecnilAlt className='w-5 h-5' />
      </button>
      <Formik initialValues={newsletter} onSubmit={onSubmit}>
        <Form>
          <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title='Edit Email'
            add={true}
            onSubmit={onSubmit}
          >
            <label>
              <b className='font-semibold block text-gray-900'>Email</b>
              <Field
                style={{ textIndent: '10px' }}
                placeholder='EnterName'
                className='border rounded  w-full mb-8 p-1'
                name='email'
                type='text'
              />
            </label>
          </Modal>
        </Form>
      </Formik>
    </>
  );
}
