import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import LanguageList from '../../SharedComponents/LanguageList';
import PlusCircle from '../../SharedComponents/Svg/PlusCircle';
import TableSearch from '../../SharedComponents/TableSearch';
import CustomTable from '../Instructors/components/CustomTable';
// import AddInstructor from './AddInstructor';
// import CustomTable from './components/CustomTable';
// import DeleteInstructor from './DeleteInstructor';
// import EditInstructor from './EditInstructor';

export default function RegisterationCodes(props) {
  const { t, i18n } = useTranslation();
  // Query Data To Fetch Languages
  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  // search
  const [entries, setEntries] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [languageId, setLanguageId] = useState(2);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchLanguage, setSearchlanguage] = useState(0);
  const [filterSearch, setFilterSearch] = useState('');

  const onChangeEntries = (e) => {
    setFilterSearch('');
    setEntries(e.target.value);
  };
  const onSearch = (e) => {
    setFilterSearch('');
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    setSearchlanguage(languageId);
  };

  let initialState = {
    pageNumber: pageNumber,
    pageSize: entries,
    isPaging: true,
    isFilter: false,
    langId: 0,
    isSearch: false,
  };
  const { data, isLoading, isError } = useQuery(['promoCodes', initialState], getRegisterationCodes, {
    // staleTime: 99900,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {
      pagination(data);
    },
  });
  // useEffect(() => {
  //   data && pagination(data);
  // }, [data]);
  const [paginationCount, setPaginationCount] = useState([]);

  function pagination(data) {
    let paginationCount = [];
    for (var i = 1; i <= data.paginationResult.numberOfPages; i++) {
      paginationCount.push(i);
    }
    setPaginationCount(paginationCount);
  }

  const dataColumns = [
    {
      Header: 'code',
      accessor: 'code',
    },
    {
      Header: 'status',
      accessor: 'codeStatus',
      Cell: ({ cell }) => {
        console.log('cell', cell);
        return <>{cell.value === true ? 'not used' : 'used'}</>;
      },
    },
  ];
  if (isLoading) return '';
  if (isError) return '';
  return (
    <>
      <header className="flex justify-between items-center">
        <BreadCrumbs to="/instructors" name={t('instructors')} />
        <div>{/* <AddInstructor languages={languages?.data?.data} /> */}</div>
      </header>
      <div className="my-6">
        <h2 className="text-2xl font-bold capitalize">{t('instructors')}</h2>
      </div>
      {/* Table */}
      <div className="bg-white rounded border shadow-md">
        <div className="flex   relative items-center px-6 py-6  ">
          <TableSearch onChangeEntries={onChangeEntries} entries={entries} onSearch={onSearch} />
          <div className="flex transform  relative z-10 ml-auto ">
            <LanguageList languages={languages.data?.data} languageId={languageId} setLanguageId={setLanguageId} />
          </div>
          <hr className="border w-full absolute top-0 left-0 z-0 mt-16" />
        </div>

        <CustomTable columns={dataColumns} data={data.data} />
      </div>
    </>
  );
}

export const getLanguages = async () => {
  const query = await axios.post('/Language/GetAll');
  const data = query.data.data;
  const isDone = query.data.isDone;
  return { data, isDone };
};

export const getRegisterationCodes = async (e) => {
  const query = await axios.post('/RegistrationCodes/GetAll', e.queryKey[1]);
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};
