import { useTranslation } from 'react-i18next';
import { UserGroupIcon } from '@heroicons/react/solid';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import { timeConvert } from '../../../../utils/timeConvert';
import AddInstructorToPage from './AddInstructorToPage';
import SetLiveSpeaker from './SetLiveSpeaker';
import AddChairPersonToPage from './AddChairPersonToPage';
import Trash from '../../../../SharedComponents/Svg/Trash';
import Notification from '../../../../SharedComponents/Notification';
import toast from 'react-hot-toast';
import EditChairPersonToPage from './EditChairPersonToPage';

export default function ChairPersonsComponent(props) {
  const { chairsPersons, languageId, languages } = props;
  const { t, i18n } = useTranslation();

  console.log('chairsPersons', chairsPersons);
  return (
    <>
      <div className="bg-white p-4 rounded shadow">
        <div
          className={`border-b-2 text-gray-500 flex space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'} py-2`}
        >
          <UserGroupIcon className="h-6" />
          <span className="font-bold">{t('chairsPersons')}</span>
        </div>
        {chairsPersons?.map((e) => {
          // data.chairsPersonsTalkers[0].chairsPersons.chairsPersonsLngs
          const instructorName = e.chairsPersons.chairsPersonsLngs?.filter((x) => x.languageId === languageId)[0].name;
          const instructorDesc = e.chairsPersons.chairsPersonsLngs?.filter((x) => x.languageId === languageId)[0]
            .description;
          // const talkTitle = e.talkersLngs?.filter((x) => x.languageId === languageId)[0].title;
          return (
            <>
              <div className="flex space-x-2 mt-4 relative">
                {/* <SetLiveSpeaker speaker={e} /> */}
                <div>
                  <img
                    alt="speaker"
                    className="h-10 w-10 rounded-full"
                    src={`${window.env.REACT_APP_IMAGE_PATH}${e.chairsPersons.imageUrl}`}
                  />
                </div>
                <div className="flex flex-col w-full">
                  <span className="flex items-center justify-between w-full flex-col md:flex-row space-y-2 md:space-x-2">
                    <span>{instructorName}</span>
                    <div className="flex space-x-2">
                      <EditChairPersonToPage chairPerson={e} />
                      <DeleteChairPerson chairPerson={e} />
                    </div>
                  </span>
                  <span>{instructorDesc}</span>
                  {/* <div className="flex space-x-2 text-xs">
                    <span>{timeConvert(e.timeFrom)}</span>
                    <span>{timeConvert(e.timeTo)}</span>
                  </div> */}
                </div>
              </div>
            </>
          );
        })}
        <div>
          <AddChairPersonToPage languages={languages} />
        </div>
      </div>
    </>
  );
}
const DeleteChairPerson = (props) => {
  const { t, i18n } = useTranslation();
  const { chairPerson } = props;

  const deleteChairPerson = async (e) => {
    const { data } = await axios.post(`/ChairsPersonsTalkers/Delete`, e);
    return data;
  };
  const queryClient = useQueryClient();
  const deleteChairPersonMutation = useMutation(deleteChairPerson, {
    onSuccess: () => {
      toast(<Notification message={t('Deleted, Successfully')} />);
      queryClient.invalidateQueries('page');
    },
    onError: (data) => {
      console.log('data', data);
      toast(<Notification message={t('Deleted, Successfully')} />);
      queryClient.invalidateQueries('page');
    },
  });

  const onClick = () => {
    deleteChairPersonMutation.mutate({
      id: chairPerson.id,
    });
  };
  return (
    <>
      <button
        onClick={() => {
          onClick();
        }}
        type="button"
        className={`bg-red-100 text-red-800 hover:bg-red-200  p-1 rounded transition duration-300 ease-in-out`}
      >
        <Trash className="w-4 h-4 " />
        {/* <span>{t('Delete')} </span> */}
      </button>
    </>
  );
};
