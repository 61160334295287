import { Field, FieldArray, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import RichTextEditor from '../../Pages/RichTextEditor';
import AddListItem from './AddListItem';
import { addListItem, editListParent } from './ApiList';
import ListItem from './ListItem';
import ListPagesList from './ListPagesList';

export default function ListParent(props) {
  const { pgSectionListParents, languageId } = props;
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  // Mutate Data to Create New List Parent
  const addListItemMutation = useMutation(addListItem, {
    onSuccess: async (data) => {
      if (data.isDone) {
        // queryClient.resetQueries(['sections']);
        // queryClient.resetQueries('sections');
        queryClient.refetchQueries(['sections']);
      }
    },
  });
  const editListParentMutation = useMutation(editListParent);

  return (
    <>
      {/* {pgSectionListParents && (
        <div className="flex transform translate-y-1 relative z-10 mb-4">
          {pgSectionListParents?.pgSectionListParentLngs?.map((language) => (
            <div
              key={language.id}
              className={`flex justify-center items-center space-x-2 ${
                languageId === language.id ? 'text-pagetabhover border-pagetabhover' : ''
              }   hover:text-pagetabhover pb-2 px-4 z-50 border-b-2 hover:border-pagetabhover transition duration-300 ease-in-out cursor-pointer `}
              onClick={(e) => setLanguageId(language.id)}
            >
              <span>{language.name}</span>
            </div>
          ))}
          <hr className="border w-full absolute bottom-0 left-0 z-0" />
        </div>
      )} */}
      <Formik enableReinitialize initialValues={pgSectionListParents}>
        {({ values, setFieldValue, setValues }) => {
          return (
            <div className="border p-2 rounder shadow mt-4">
              {' '}
              {/* Submit Form */}
              <div className="grid grid-cols-2 gap-6 mb-4 border p-2">
                <div className="col-span-2">
                  <div className="mb-2">
                    {values &&
                      values.pgSectionListParentLngs &&
                      values?.pgSectionListParentLngs.map((pgSectionCollapseItemsLng, index) => (
                        <FieldArray
                          key={index}
                          name="pgSectionListParentLngs"
                          render={(arrayHelpers) => (
                            <>
                              {languageId === pgSectionCollapseItemsLng.languageId && (
                                <>
                                  <label>
                                    <Field
                                      className="border rounded  w-full  p-2"
                                      type="text"
                                      placeholder="Add Title"
                                      name={`pgSectionListParentLngs[${index}].title`}
                                    />
                                  </label>
                                </>
                              )}
                            </>
                          )}
                        />
                      ))}
                  </div>
                  <div className="mb-2">
                    {values &&
                      values.pgSectionListParentLngs &&
                      values?.pgSectionListParentLngs.map((pgSectionCollapseItemsLng, index) => (
                        <FieldArray
                          key={index}
                          name="pgSectionListParentLngs"
                          render={(arrayHelpers) => (
                            <>
                              {languageId === pgSectionCollapseItemsLng.languageId && (
                                <>
                                  <label>
                                    <RichTextEditor
                                      fieldName={`pgSectionListParentLngs[${index}].description`}
                                      fieldValue={values.pgSectionListParentLngs[index].description}
                                      setFieldValue={setFieldValue}
                                    />
                                    {/* <Field
                                      className="border rounded  w-full  p-2"
                                      as="textarea"
                                      rows="3"
                                      placeholder="Add Description"
                                      name={`pgSectionListParentLngs[${index}].description`}
                                    /> */}
                                  </label>
                                </>
                              )}
                            </>
                          )}
                        />
                      ))}
                  </div>

                  {/* waiting to check if we will add image  */}
                  {/* <div className="h-52 focus:outline-none group rounded border-dashed border-2 mb-2 relative">
        {(values.imageUrl && (
          <>
            <div className="flex items-center justify-center h-full">
              <img
                className=""
                style={{
                  maxWidth: 32,
                  maxHeight: 32,
                }}
                alt="img"
                src={`${window.env.REACT_APP_IMAGE_PATH}${values.imageUrl}`}
              />{' '}
            </div>
            <div className="absolute group-hover:opacity-100 opacity-0 inset-0 flex items-center justify-center">
              <AddCollapseImage edit={true} setField={setFieldValue} fieldName="imageUrl" />
            </div>
          </>
        )) || (
          <div className="absolute inset-0 flex items-center justify-center">
            <AddCollapseImage fieldName="imageUrl" setField={setFieldValue} />
          </div>
        )}
      </div> */}

                  <div className="grid grid-cols-2 gap-6 flex items-center">
                    <div>
                      {values &&
                        values.pgSectionListParentLngs &&
                        values?.pgSectionListParentLngs.map((pgSectionCollapseItemsLng, index) => (
                          <FieldArray
                            key={index}
                            name="pgSectionListParentLngs"
                            render={(arrayHelpers) => (
                              <>
                                {languageId === pgSectionCollapseItemsLng.languageId && (
                                  <>
                                    <label>
                                      <Field
                                        className="border rounded  w-full  p-2"
                                        type="text"
                                        placeholder={t('Button Title')}
                                        name={`pgSectionListParentLngs[${index}].buttonTitle`}
                                      />
                                    </label>
                                  </>
                                )}
                              </>
                            )}
                          />
                        ))}
                    </div>
                    <div>
                      <div className={`grid grid-cols-2 space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
                        <label
                          className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
                        >
                          <Field type="radio" name="isExternalLink" value="true" className="w-4 h-4" />
                          <span>{t('External Link')}</span>
                        </label>
                        <label
                          className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
                        >
                          <Field type="radio" name="isExternalLink" value="false" className="w-4 h-4" />
                          <span>{t('Pages')}</span>
                        </label>
                      </div>
                    </div>

                    <div className="relative">
                      <ListPagesList
                        isExternalLink={values.isExternalLink}
                        setFieldValue={setFieldValue}
                        languageId={languageId}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {values &&
                values.pgSectionListItems?.map((item, index) => {
                  return (
                    <ListItem
                      ListItem={item}
                      mainIndex={index}
                      listItems={values.pgSectionListItems}
                      languageId={languageId}
                      setParentFieldValue={setFieldValue}
                    />
                  );
                })}
              <AddListItem
                addListItemMutation={addListItemMutation}
                pgSectionListParentId={values.id}
                editListParentMutation={() => editListParentMutation.mutate(values)}
                editIsLoading={editListParentMutation.isLoading}
                editIsSuccess={editListParentMutation.isSuccess}
              />
            </div>
          );
        }}
      </Formik>
    </>
  );
}
