import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import Loading from '../../../SharedComponents/Loading';
import { getPagesByStatus } from '../ApiDashboard';

export default function PagesByStatus(props) {
  const { data, isLoading, isError, isSuccess } = useQuery(['PagesByStatus'], getPagesByStatus, {
    staleTime: 90000,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {},
  });
  const { t } = useTranslation();

  if (isLoading)
    return (
      <div className="h-64 relative rounded shadow border bg-white">
        <Loading />
      </div>
    );
  if (isError) return 'error';
  const statusIds = data?.data?.map((page) => {
    if (page.statusId === 0) {
      return t('Draft');
    } else if (page.statusId === 1) {
      return t('Published');
    }
  });
  const pages = data?.data?.map((page) => page.pages);
  const newData = {
    labels: statusIds,
    datasets: [
      {
        label: t('# of pages'),
        data: pages,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return (
    <>
      <div className="bg-white p-4 rounded border shadow">
        <h3>{t('Pages Count By Status')} </h3>
        <hr className="block my-4" />
        <Bar data={newData} options={options} />
      </div>
    </>
  );
}
