import PagesByCategory from './Components/PagesByCategory';
import PagesByCategoryCount from './Components/PagesByCategoryCount';
import PagesByStatus from './Components/PagesByStatus';
import UserCountByRole from './Components/UserCountByRole';
import UserCountByRoleCount from './Components/UserCountByRoleCount';
import UserCountByStatus from './Components/UserCountByStatus';
import { Helmet } from 'react-helmet';
import { getSetting } from '../Settings/ApiSettings';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

export default function Dashboard(props) {
  const { t, i18n } = useTranslation();
  const morePages = [];
  const getSettingsQuery = useQuery('getSettings', getSetting);
  if (getSettingsQuery.isLoading) return '';
  if (getSettingsQuery.isError) return '';

  const englishTitle = getSettingsQuery?.data?.data?.settingLngs?.filter((e) => e.languageId === 2)[0]?.cmsTitle;
  const arabicTitle = getSettingsQuery?.data?.data?.settingLngs?.filter((e) => e.languageId === 1)[0]?.cmsTitle;
  console.log('englishTitle', englishTitle);
  return (
    <>
      <Helmet>
        <title>
          {`${i18n.language === 'en' ? englishTitle : arabicTitle} | DashBoard ${
            morePages.length !== 0 ? '| ' + morePages?.map((e) => e?.name)?.join(' | ') : ''
          }`}
        </title>{' '}
      </Helmet>

      <div className="grid  grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <PagesByCategoryCount />
        <UserCountByRoleCount />
      </div>
      <div className="grid grid-cols-2 gap-6">
        <UserCountByRole />
        <UserCountByStatus />
        <PagesByCategory />
        <PagesByStatus />
      </div>
    </>
  );
}
