// import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import axios from 'axios';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

export default function DownloadEventLogs(props) {
  const { pageId } = props;
  const { t } = useTranslation();

  const fetchEventLogs = async (e) => {
    const { data } = await axios.post('/EventLog/GetByPageId', e.queryKey[1]);
    return data;
  };

  let initialState = {
    pageNumber: 0,
    pageSize: 0,
    isPaging: false,
    langId: 0,
    isSearch: false,
    searchFilter: {
      langId: 0,
      term: '',
    },
    pageId: pageId,
  };

  const { data, isLoading, isError } = useQuery(['newsLetters', initialState], fetchEventLogs, {
    staleTime: 99900,
  });

  if (isLoading)
    return (
      <>
        <span className="bg-darkblue hover:bg-darkbluehover text-white py-2 rounded px-4">Preparing</span>
      </>
    );
  if (isError) return 'Error';
  console.log('data', data);
  const headers = [
    { label: 'Name', key: 'firstName' },
    { label: 'Country', key: 'country' },
    { label: 'speciality', key: 'speciality' },
    { label: 'phone', key: 'phone' },
    { label: 'nationality', key: 'nationality' },
    { label: 'dayOfVisit', key: 'dayOfVisit' },
    { label: 'timeOfVisit', key: 'timeOfVisit' },
    { label: 'visitedHall', key: 'visitedHall' },
  ];

  const csvReport = {
    data: data.data,
    headers: headers,
    filename: 'Event_Logs.csv',
  };
  return (
    <>
      <CSVLink {...csvReport}>
        <span className="bg-darkblue hover:bg-darkbluehover text-white py-2 w-full block text-center rounded px-4">
          {t('Download Event Logs')}
        </span>
      </CSVLink>
    </>
  );
}
