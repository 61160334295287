import axios from 'axios';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { NavLink } from 'react-router-dom';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
export default function NotificationsPage(props) {
  const { t } = useTranslation();
  const userData = localStorage.getItem('token');
  const userId = userData ? JSON.parse(userData).userId : 0;
  const specialistId = userData ? JSON.parse(userData).specialistId : 0;
  let initialState = {
    pageNumber: 1,
    pageSize: 10,
    isPaging: true,
    isFilter: false,
    langId: 0,
    isSearch: false,
    userId: userId,
    isSorted: true,
    isSpecialist: true,
  };
  const queryClient = useQueryClient();

  const { data, isLoading, isError } = useQuery(['notificationsList', initialState], queryFunction, {
    // staleTime: 99900,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {
      // pagination(data);
      queryClient.invalidateQueries('notificationsCounter');
    },
  });
  if (isLoading) return '';
  if (isError) return '';
  console.log('data', data);
  return (
    <>
      <header className="flex justify-between items-center">
        <BreadCrumbs to="/categories" name={t('Notifications')} />
        <div></div>
      </header>
      <div className="my-6">
        <h2 className="text-2xl font-bold">{t('Notifications List')}</h2>
      </div>
      <div className="bg-white p-4 divide-y ">
        {data.data.map((e, index) => {
          return (
            <div className="py-2">
              <NavLink to={`/ConsultingSpecialistSingleMessage/${specialistId}/${e.userSenderId}`}>
                <div className={`flex justify-between ${e.markAsRead ? '' : 'bg-gray-50'}`}>
                  <span>
                    You have a new message from <b>{e?.userSenderFullName}</b>{' '}
                    <small className="mx-4">
                      {DateTime.fromISO(e.createDate).setLocale('en-uk').toLocaleString(DateTime.DATETIME_SHORT)}
                    </small>
                  </span>
                  Check
                </div>
              </NavLink>
            </div>
          );
        })}
      </div>
    </>
  );
}

export const queryFunction = async (e) => {
  const query = await axios.post('Notifications/GetNotificationsByUserId', e.queryKey[1]);
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};
