import { Field, FieldArray, Form, Formik } from 'formik';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import ChevronRight from '../../../SharedComponents/Svg/ChevronRight';
import PlusCircle from '../../../SharedComponents/Svg/PlusCircle';
import PollQOption from './PollQOption';
import { addPollQuestion, editPollQuestion, getLanguages } from '../ApiPolls';
import { useParams } from 'react-router';
import DeleteQuestion from './DeleteQuestion';
import Notification from '../../../SharedComponents/Notification';
import { useTranslation } from 'react-i18next';

export default function Question(props) {
  const { pollQOptionLngs, Question, pollQuestions, languageId, mainIndex, values, setValues, setFieldValue } = props;
  // console.log('Question', Question);
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const params = useParams();
  const queryClient = useQueryClient();
  // Mutate Data to Create New Question
  const addQuestion = useMutation(addPollQuestion, {
    onSuccess: async (data) => {
      queryClient.invalidateQueries(['poll']);

      if (data.isDone) {
        queryClient.invalidateQueries(['poll']);
      }
    },
  });
  // Mutate Data to Create New Question
  const editQuestion = useMutation(editPollQuestion, {
    onSuccess: async (data) => {
      queryClient.invalidateQueries(['poll']);

      if (data.isDone) {
        queryClient.invalidateQueries(['poll']);
      }
    },
  });
  const onSubmit = async (e) => {
    delete e.pollQAnswers;
    if (e.id) {
      try {
        await editQuestion.mutate(e);
      } catch (error) {
        console.log('error', error);
      }
    } else {
      try {
        const id = params.id;
        await addQuestion.mutate({ ...e, pollId: id });
      } catch (error) {
        console.log('error', error);
      }
    }
  };
  return (
    <>
      <div className="border mb-4 shadow bg-white ">
        <div className="  px-4 p-3  flex justify-between">
          <div className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
            <button
              type="button"
              className="focus:outline-none "
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <ChevronRight
                className={`w-4 h-4 transform ${isOpen ? 'rotate-90' : 'rotate-0'}  transition-all duration-500`}
              />
            </button>
            {values &&
              values.pollQuestions &&
              values?.pollQuestions[mainIndex] &&
              values.pollQuestions[mainIndex].pollQuestionLngs.map((pollQuestionLng, index) => (
                <FieldArray
                  key={index}
                  name="pollQuestions"
                  render={() => (
                    <>
                      {languageId === pollQuestionLng.languageId && (
                        <>
                          <label>
                            <Field
                              className="border rounded  w-full  p-1"
                              type="text"
                              placeHolder={t('Add Question Name')}
                              // name='title'
                              name={`pollQuestions[${mainIndex}].pollQuestionLngs[${index}].header`}
                            />
                          </label>
                        </>
                      )}
                    </>
                  )}
                />
              ))}
          </div>
          <div>
            {/* <DeleteBranch id={branch.id} /> */}
            <DeleteQuestion
              setFieldValue={setFieldValue}
              mainIndex={mainIndex}
              values={values}
              id={values?.pollQuestions[mainIndex].id}
            />
          </div>
        </div>
        <div className={`bg-gray-100 px-4   overflow-hidden      ${isOpen ? 'py-3 h-full  ' : '  py-0 h-0'}`}>
          <>
            <FieldArray
              key={mainIndex}
              name="pollQuestions"
              render={(arrayHelpers) => {
                // arrayHelpers.push({
                //   pollQuestions: pollQuestions,
                // });

                return (
                  <>
                    {values &&
                      values.pollQuestions &&
                      values?.pollQuestions[mainIndex] &&
                      values.pollQuestions[mainIndex].pollQuestionLngs.map((pollQuestionLng, index) => (
                        <FieldArray
                          key={index}
                          name="pollQuestions"
                          render={() => (
                            <>
                              {languageId === pollQuestionLng.languageId && (
                                <>
                                  <label>
                                    <b className="font-semibold block text-gray-700">{t('Question')}</b>
                                    <Field
                                      className="border rounded  w-full mb-2 p-1"
                                      as="textarea"
                                      // name='title'
                                      name={`pollQuestions[${mainIndex}].pollQuestionLngs[${index}].title`}
                                    />
                                  </label>
                                </>
                              )}
                            </>
                          )}
                        />
                      ))}
                    <label>
                      <b className="font-semibold block text-gray-700">{t('Options')}</b>
                    </label>
                    {values &&
                      values.pollQuestions &&
                      values?.pollQuestions[mainIndex] &&
                      values.pollQuestions[mainIndex].pollQOptions.map((pollQOption, index) => {
                        const subIndex = index;
                        return (
                          <FieldArray
                            key={`${subIndex}_0`}
                            name="pollQuestions"
                            render={() => (
                              <>
                                {values &&
                                  values.pollQuestions &&
                                  values?.pollQuestions[mainIndex] &&
                                  values?.pollQuestions[mainIndex].pollQOptions[subIndex] &&
                                  values.pollQuestions[mainIndex].pollQOptions[subIndex].pollQOptionLngs.map(
                                    (pollQOptionLng, index) => (
                                      <FieldArray
                                        key={`${index}_0`}
                                        name={`pollQuestions[${mainIndex}].pollQOptions`}
                                        render={(arrayHelpers) => {
                                          const optionHelpers = arrayHelpers;
                                          return (
                                            <>
                                              <PollQOption
                                                pollQOptionLngs={pollQOptionLngs}
                                                mainIndex={mainIndex}
                                                subIndex={subIndex}
                                                index={index}
                                                pollQOptionHelper={arrayHelpers}
                                                languageId={languageId}
                                                pollQOptionLng={pollQOptionLng}
                                              />
                                            </>
                                          );
                                        }}
                                      />
                                    )
                                  )}
                                {/* <div className='text-center'>
                                  <button
                                    type='button'
                                    onClick={() =>
                                      arrayHelpers.push({ pollQOptionLngs })
                                    }
                                  >
                                    +
                                  </button>
                                </div> */}
                              </>
                            )}
                          />
                        );
                      })}
                  </>
                );
              }}
            />
          </>
          {editQuestion.isSuccess && <Notification message={t('Poll Question Updated')} />}
          {addQuestion.isSuccess && <Notification message={t('Poll Question Added')} />}
          <div className="flex justify-end my-4">
            <button
              type="button"
              className={`bg-lightgreen space-x-1 ${
                i18n.language === 'en' ? '' : 'space-x-reverse'
              } text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
              onClick={() => {
                const oldData = values.pollQuestions;

                const newData = oldData.filter((item, index) => index === mainIndex);

                onSubmit(newData[0]);
              }}
            >
              <PlusCircle className="w-5 h-5" />{' '}
              <span>
                {values.pollQuestions.filter((item, index) => index === mainIndex)[0].id
                  ? t('Update Poll')
                  : t('Save Poll')}
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
