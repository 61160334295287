import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import Notification from '../../../SharedComponents/Notification';
import { toggleStatusSection } from './ApiSections';

export default function ToggleSectionStatus(props) {
  const { sectionStatus, id } = props;
  const [activeToggle, setActiveToggle] = useState(sectionStatus);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const toggleStatusSectionMutation = useMutation(toggleStatusSection, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['sections']);
    },
  });

  const handleClick = async (e) => {
    setActiveToggle(!activeToggle);
    const newData = { sectionId: id, isActive: !activeToggle };

    try {
      await toggleStatusSectionMutation.mutate(newData);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      <button type="button" onClick={handleClick} className="flex items-center cursor-pointer focus:outline-none">
        {/* <!-- toggle --> */}
        <div className="relative">
          {/* <!-- line --> */}
          <div
            className={` w-12 h-6 ${activeToggle ? 'bg-lightgreen' : 'bg-gray-400'}  rounded-full shadow-inner`}
          ></div>
          {/* <!-- dot --> */}
          <div
            className={`transition-all ease-in-out duration-700 absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 ${
              activeToggle ? 'right-0' : 'left-0'
            } `}
          ></div>
        </div>
        {/* <!-- label --> */}
      </button>
      {toggleStatusSectionMutation.isSuccess && <Notification message={t('Section Status Changed Successfully')} />}
    </>
  );
}
