import { CheckIcon, UploadIcon } from '@heroicons/react/solid';
import axios from 'axios';
import { useFormikContext } from 'formik';
import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import LoadingSpinner from './LoadingSpinner';

export default function Upload(props) {
  const { isOpen, setIsOpen, fieldName } = props;
  const sharedProps = { isOpen, setIsOpen };
  const [uploading, setUploading] = useState(false);
  const [filePath, setFilePath] = useState('');
  const [uploadSucess, setUploadSucess] = useState(false);
  const [attachment, setAttachment] = useState([]);
  const { t } = useTranslation();
  const [image, setImage] = useState('');

  const { setFieldValue, values } = useFormikContext();
  const mediaUploadMutation = useMutation(uploadMedia, {
    onSuccess: (e, variables) => {
      console.log('e', e);

      const newAttachment = attachment.map((x, index) => {
        return { ...x, cdnPath: e.data.path };

        // if (attachment.length > 1) {
        //   if (index === attachment.length - 1) {
        //     return { ...x, cdnPath: e.data.path }
        //   }
        //   return { ...x }
        // } else {
        //   return { ...x, cdnPath: e.data.path }
        // }
      });

      setFilePath(e.data.path);
      setUploadSucess(true);
      setUploading(false);
      setAttachment(newAttachment);
      setFieldValue('message', e.data.path);
      // setFieldValue(fieldName, e.data.path);
      //   setTimeout(() => {
      //     setIsOpen(false)
      //   }, 2000)
    },
  });

  const onDrop = useCallback(
    (acceptedFiles) => {
      setUploading(true);

      // loop through the accepted Files
      acceptedFiles.map((file) => {
        // Initialize FileReader browser API
        const reader = new FileReader();
        // onload callback gets called after the reader reads the file data
        reader.onload = function (e) {
          // add the image into the state. Since FileReader reading process is asynchronous, its better to get the latest snapshot state (i.e., prevState) and update it.
          setImage(e.target.result);
        };
        // Read the file as Data URL (since we accept only images)
        reader.readAsDataURL(file);

        return file;
      });
      const handleServer = async () => {
        const formData = new FormData();
        formData.append('file', acceptedFiles[0]);
        setAttachment([...acceptedFiles]);

        // if (attachment) {
        //   // setFieldValue(
        //   // 	fieldName,
        //   // 	JSON.stringify([...attachment, ...acceptedFiles])
        //   // );
        //   setAttachment([...attachment, ...acceptedFiles])
        // } else {
        //   setAttachment([...acceptedFiles])
        // }

        try {
          await mediaUploadMutation.mutate(formData);
        } catch (error) {}
      };
      handleServer();
      // eslint disable next-line
    },
    [mediaUploadMutation, attachment, setAttachment]
  );
  // useEffect to not let the Component Reload
  useEffect(() => {}, [filePath]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDrop,
    accept: `${values.isFile === true ? '.pdf,.zip,.docx,.xlsx' : values.isImage ? '.png,.jpg,.jpeg' : ''} `,
  });
  return (
    <>
      <div
        className="flex items-center justify-center text-second font-regular   bg-blue-500 px-4 py-2 text-white rounded"
        {...getRootProps()}
      >
        <input className="dropzone-input focus:outline-none" type="file" {...getInputProps()} />
        <div className="text-center">
          {isDragActive && !uploading ? (
            <div className="dropzone-content text-center ">
              {/* <Photograph className="inline-block w-8 h-8" /> */}
              <span className="block">{t('upload')}</span>
            </div>
          ) : (
            <div className="dropzone-content text-center  ">
              {/* <Photograph className="inline-block w-8 h-8" /> */}
              <div className="relative flex items-center space-x-2">
                <span className="block ">{t('upload')}</span>
                {uploading && <LoadingSpinner />}
                {uploadSucess && !uploading && <CheckIcon className="h-4 m-0" />}
                {!uploadSucess && !uploading && <UploadIcon className="h-4 m-0" />}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* {uploadSucess && attachment && attachment.length > 0 && (
        <>
          <div dir="rtl" className="border p-2 flex">
            <span>{attachment[0].path}</span>
          </div>
        </>
      )} */}
    </>
  );
}

// submit data to server
export const uploadMedia = async (formData) => {
  const mediaUpload = await axios.post(`${window.env.REACT_APP_CDN}`, formData);

  return mediaUpload;
};
