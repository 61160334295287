import axios from 'axios';

export const addTab = async (e) => {
  const { data } = await axios.post('/PgSection/AddTab', e);
  return data;
};
export const addTabItem = async (e) => {
  const { data } = await axios.post('/PgSection/AddTabItem', e);
  return data;
};
export const editTabItem = async (e) => {
  const { data } = await axios.post('/PgSection/EditTabItem', e);
  return data;
};

export const deleteTabItem = async (e) => {
  const { data } = await axios.post('/PgSection/DeleteTabItem', e);
  return data;
};
export const editTab = async (e) => {
  const { data } = await axios.post('/PgSection/EditTab', e);
  return data;
};
