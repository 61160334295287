import axios from 'axios';
import { useQuery } from 'react-query';
import { DefaultTable } from '../../SharedComponents/Table/Table';
import { useTranslation } from 'react-i18next';
import LanguageList from '../../SharedComponents/LanguageList';
import { useState } from 'react';
import { getLanguages } from './ApiCategories';
import EditSubCategory from './EditSubCategory';

export default function SubCategoriesTable(props) {
  // Query Data To Fetch Languages
  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  const { id } = props.row.original;
  const { t, i18n } = useTranslation();
  const [languageId, setLanguageId] = useState(1);

  const queryKey = {
    parentId: parseInt(id),
  };

  const { data, isLoading, isError } = useQuery([`categories-${id}`, queryKey], queryFunction);

  if (isLoading) return '';
  if (isError) return 'error';

  const dataColumns = [
    {
      Header: t('Title'),
      accessor: 'pgPageCategoryLngs',
      Cell: ({ row, cell }) => {
        return (
          <div className="">
            {cell.value.map((x) => {
              return <span key={x.id}>{parseInt(languageId) === parseInt(x.languageId) && <> {x.name} </>}</span>;
            })}
          </div>
        );
      },
    },
    {
      Header: t('Options'),
      accessor: 'options',
      Cell: ({ row, cell }) => {
        return (
          <div
            className={`whitespace-nowrap flex items-center space-x-2 ${
              i18n.language === 'en' ? '' : 'space-x-reverse'
            }`}
          >
            {/* <EditRole id={user.id} name={user.name} /> */}
            {/* <DeleteRole id={user.id} /> */}
            <EditSubCategory category={row.original} />
          </div>
        );
      },
    },
  ];
  return (
    <div className="bg-gray-100  m-4 ">
      <div className="p-4 border bg-white">
        <div className=" flex justify-between items-center border-b mb-4">
          <label className="text-base block   font-bold text-gray-900">sub categories</label>
          <div className="flex transform  relative z-10 ">
            <LanguageList languages={languages?.data?.data} languageId={languageId} setLanguageId={setLanguageId} />
          </div>
          <hr className="border w-full absolute top-0 left-0 z-0 mt-16" />
        </div>
        {<DefaultTable columns={dataColumns} data={data.data} />}
        {/* <DefaultTable columns={dataColumns} data={data.data} /> */}
      </div>
    </div>
  );
}

const queryFunction = async (e) => {
  const { data } = await axios.post(`PgPage/GetCategoryByParentId`, e.queryKey[1]);
  return data;
};
