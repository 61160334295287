// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Polls: 'استطلاعات الرأي',
  Deadline: 'تاريخ الانتهاء',
  Questions: 'أسئلة',
  Answers: 'إجابات',
  Users: 'المستخدمين',
  Active: 'نشط',
  Optioms: 'خيارات',
  'Create Poll': 'إنشاء استطلاع',
  'Add New Question': 'إضافة سؤال جديد',
  'Add Question Name': 'إضافة اسم سؤال ',
  'Poll Question, Deleted': 'حذف استطلاع للرأي',
  'Sorry, This Poll Question have Data Inside, Please Delete and Retry':
    'عذرا، هذا الاستطلاع السؤال لديه بيانات داخل، يرجى حذف وإعادة المحاولة',
  'Are you sure you want to delete this Poll Question? All the data will be permanently removed from our servers forever. This action cannot be undone':
    'هل أنت متأكد أنك تريد حذف سؤال الاستطلاع هذا؟سيتم إزالتها بشكل دائم من خوادمنا إلى الأبد.لا يمكن التراجع عن هذا الإجراء',
  'Delete Poll Question': 'حذف سؤال الاستطلاع',
  'Update Poll': 'تحديث الاستطلاع',
  'Save Poll': 'حفظ استطلاع',
  'Poll Question Updated': ' تم تحديث سؤال الاستطلاع ',
  'Poll Question Added': 'تمت إضافة سؤال الاستطلاع',
  'Sorry, This Menu Item have Data Inside, Please Delete and Retry':
    'آسف، هذه القائمة القائمة لها بيانات داخل، يرجى حذف وإعادة المحاولة',
  'Delete Telephone': 'حذف رقم الهاتف',
  'Are you sure you want to delete this Telephone? All the data will be permanently removed from our servers forever. This action cannot be undone':
    'هل أنت متأكد أنك تريد حذف هذا الهاتف؟سيتم إزالتها بشكل دائم من خوادمنا إلى الأبد.لا يمكن التراجع عن هذا الإجراء',
  'Delete Poll': 'حذف استطلاع',
  'Are you sure you want to delete this Poll? All the data will be permanently removed from our servers forever. This action cannot be undone':
    'هل أنت متأكد أنك تريد حذف هذا الاستطلاع؟سيتم إزالتها بشكل دائم من خوادمنا إلى الأبد.لا يمكن التراجع عن هذا الإجراء',
  'Write vote button title': 'كتابة عنوان زر التصويت',
  'For All': 'للجميع',
  Members: 'الاعضاء',
  'Ask user once in home page': 'اسأل المستخدم مرة واحدة في الصفحة الرئيسية',
  'Show results for users': 'عرض النتائج للمستخدمين',
};
