import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import ChevronRight from '../../../SharedComponents/Svg/ChevronRight';
import DotsVertical from '../../../SharedComponents/Svg/DotsVertical';
import DeleteSection from './DeleteSection';
import EditSection from './EditSection';
import ToggleSectionStatus from './ToggleSectionStatus';

export default function SectionHeader(props) {
  const { isOpen, setIsOpen, section, languageId, provided } = props;
  const { t, i18n } = useTranslation();
  return (
    <div className="  px-4 p-3  flex justify-between  shadow relative ">
      <div className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
        <button
          type="button"
          className="focus:outline-none "
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <ChevronRight
            className={`w-4 h-4 transform ${isOpen ? 'rotate-90' : 'rotate-0'}  transition-all duration-500`}
          />
        </button>
        <div>
          {section &&
            section.pgSectionLngs &&
            section?.pgSectionLngs.map((pgSectionLng, index) => {
              return (
                <Fragment key={index}>
                  {languageId === pgSectionLng.languageId && (
                    <>{pgSectionLng.title ? pgSectionLng.title : 'Untitled Section'}</>
                  )}
                </Fragment>
              );
            })}
        </div>
      </div>
      <div className={`flex space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
        {section.id && <span className=" rounded px-2 bg-yellow-500 text-white font-normal">{section.id}</span>}
        {section.sectionStyle && (
          <span className="border rounded px-2 bg-pink text-white font-normal">{section.sectionStyle}</span>
        )}
        <EditSection section={section} />
        <ToggleSectionStatus sectionStatus={section.isActive} id={section.id} />
        <DeleteSection id={section.id} />
        <div {...provided.dragHandleProps}>
          {' '}
          <DotsVertical className="w-5 h-5" />
        </div>
      </div>
    </div>
  );
}
