import { useQuery } from 'react-query';
import Loading from '../../../SharedComponents/Loading';
import { getAssetById } from './ApiPage';

export default function ImageContainer(props) {
  const { imageId } = props;

  const { data, isLoading, isError } = useQuery(['assetById', { id: imageId }], getAssetById, {
    staleTime: 90000,
  });

  if (isLoading) return <Loading />;
  if (isError) return 'error';

  return (
    <div className="h-64 w-full flex items-center justify-center">
      <img className="h-full p-8" alt="img" src={`${window.env.REACT_APP_IMAGE_PATH}${data.data.fileUrl}`} />
    </div>
  );
}
