import { Fragment } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import OutsideClickHandler from '../../../SharedComponents/OutsideClickHandler';
import { getPages } from './ApiPage';

export default function PagesList(props) {
  //TODO
  //   Error while searching by name
  const { languageId, setFieldValue, parentId } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [searchParam, setSearchParam] = useState('');
  const { t } = useTranslation();
  const onSearchParam = (e) => {
    setSearchParam(e.target.value);
  };
  const PagesinitialData = {
    pageNumber: 1,
    pageSize: 1,
    isPaging: false,
    langId: languageId,
  };
  // Query Data To Fetch Languages
  const { data, isLoading, isError } = useQuery(['pages', PagesinitialData], getPages, {
    staleTime: 360000000,
  });
  if (isLoading) return '';
  if (isError) return 'error';
  const onClick = (data) => {
    setFieldValue('parentId', data.id);
    setIsOpen(false);
  };
  let defaultValue = data.data.find((e) => e.id === parentId);
  return (
    <div className="relative">
      <label>
        <span>{t('Parent Page')}</span>
        <input
          onFocus={() => {
            setIsOpen(true);
          }}
          onChange={onSearchParam}
          className="border rounded  w-full mb-0 p-1"
          value={defaultValue?.pgPageLngs[0].title}
        />
      </label>

      {isOpen && (
        <OutsideClickHandler setIsOpen={setIsOpen}>
          <div className="absolute z-50 bottom-0 left-0 bg-white w-full transform translate-y-full pl-0 block ">
            <ul className="border rounded divide-y overflow-y-scroll max-h-64 mt-2">
              {data.data
                .filter((e) => e.pgPageLngs[0]?.title.toLowerCase().includes(searchParam.toLowerCase()))
                .map((e) => {
                  return (
                    <Fragment key={e.id}>
                      <button
                        onClick={(x) => {
                          onClick(e);
                        }}
                        type="button"
                        className={`${
                          parentId === e.id ? 'bg-darkbluehover text-white' : ''
                        } w-full text-left block px-2 py-1 hover:bg-darkbluehover hover:text-white`}
                      >
                        {e.pgPageLngs.map((x) => {
                          return <>{languageId === x.languageId && x.title}</>;
                        })}
                      </button>
                    </Fragment>
                  );
                })}
            </ul>
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
}
