import axios from 'axios';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import Notification from '../../../../SharedComponents/Notification';
import Trash from '../../../../SharedComponents/Svg/Trash';

export default function DeleteCategoryAgendaItem(props) {
  const { id } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const deleteCategoryAgendaItemMutation = useMutation(deleteCategoryAgendaItem, {
    onSuccess: () => {
      toast(<Notification message={t('Deleted, Successfully')} />);
      queryClient.invalidateQueries('sections');
    },
  });

  const onClick = () => {
    deleteCategoryAgendaItemMutation.mutate(id);
  };
  return (
    <>
      <button type="button" onClick={onClick} className="bg-red-500 text-white p-1 rounded shadow">
        <Trash />
      </button>
    </>
  );
}

export const deleteCategoryAgendaItem = async (e) => {
  console.log('e', e);
  const data = await axios.post('/PgSection/DeleteCategoryAgendaItem', {
    id: e,
  });
  return data;
};
