import { Field, FieldArray, Form, Formik } from 'formik';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import ChevronRight from '../../../SharedComponents/Svg/ChevronRight';
import { editFormQuestion } from '../ApiForms';
import AllQuestions from './AllQuestions';
import AllQuestionsTypes from './AllQuestionsTypes';
import DeleteFormQuestion from './DeleteFormQuestion';
// import CreateFormQuestionOptions from './CreateFormQuestionOptions';
import FieldType from './FieldType';

export default function FormQuestion(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { languageId, question, folderId } = props;
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();
  const mutation = useMutation(editFormQuestion, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['formQuestion']);

        setIsOpen(false);
      }
    },
  });

  const onSubmit = async (e) => {
    const newData = {
      ...e,
      qtypeId: parseInt(e.qtypeId),
      qoptionTypeId: parseInt(e.qtypeId) === 8 ? parseInt(e.qoptionTypeId) : null,
    };

    try {
      await mutation.mutate(newData);
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <div className="bg-white shadow border mb-4 p-2 ">
      <div onClick={() => setIsOpen(!isOpen)} className="flex justify-between cursor-pointer ">
        <div className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
          <ChevronRight className="w-4 h-4" />{' '}
          <b>
            {question.frmQuestionLngs.map((e) => {
              return <Fragment key={e.id}>{languageId === e.languageId && e.title}</Fragment>;
            })}
          </b>
        </div>
        <div></div>
      </div>
      <div className={`${isOpen ? ' my-4 px-6 ' : 'h-0'} overflow-hidden relative font-bold text-gray-600 text-sm`}>
        <Formik onSubmit={onSubmit} initialValues={{ ...question, qtypeId: parseInt(question.qtypeId) }}>
          {({ values, setFieldValue }) => {
            console.log('values', values);
            return (
              <Form>
                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2">
                    {values.frmQuestionLngs.map((form, index) => (
                      <FieldArray
                        key={form.id}
                        name="frmQuestionLngs"
                        render={(frmQuestionLngs) => (
                          <>
                            {languageId === form.languageId && (
                              <>
                                <label>
                                  {t('Title')}
                                  <Field
                                    className="border rounded  w-full mb-4 p-2 text-sm focus:outline-none"
                                    placeholder={t('Success Message')}
                                    name={`frmQuestionLngs[${index}].title`}
                                    type="text"
                                  />
                                </label>
                                <label>
                                  {t('Description')}
                                  <Field
                                    className="border rounded  w-full  p-2 text-sm focus:outline-none"
                                    name={`frmQuestionLngs[${index}].description`}
                                    type="text"
                                  />
                                </label>
                              </>
                            )}
                          </>
                        )}
                      />
                    ))}
                    <label>
                      <b
                        className={`font-semibold mt-4 block ${
                          i18n.language === 'en' ? '' : 'text-right'
                        } text-gray-700`}
                      >
                        {t('inputName')}
                      </b>
                      <Field
                        className="border rounded  w-full  p-2 text-sm focus:outline-none"
                        type="text"
                        name={`inputName`}
                      />
                    </label>
                  </div>
                  <div>
                    <FieldType fieldName="qtypeId" />
                  </div>
                  <div>
                    {values.frmQuestionLngs.map((form, index) => (
                      <FieldArray
                        key={form.id}
                        name="frmQuestionLngs"
                        render={(frmQuestionLngs) => (
                          <>
                            {languageId === form.languageId && (
                              <label>
                                {t('Placeholder')}
                                <Field
                                  className="border rounded  w-full  p-2 text-sm focus:outline-none"
                                  placeholder={t('Success Message')}
                                  name={`frmQuestionLngs[${index}].placeholderText`}
                                  type="text"
                                />
                              </label>
                            )}
                          </>
                        )}
                      />
                    ))}
                  </div>
                  {(parseInt(values.qtypeId) === 8 ||
                    parseInt(values.qtypeId) === 2 ||
                    parseInt(values.qtypeId) === 3) && (
                    <>
                      <div>
                        <AllQuestionsTypes fieldName="qoptionTypeId" languageId={languageId} />
                      </div>
                      <div>{/* <CreateFormQuestionOptions languageId={languageId} /> */}</div>
                    </>
                  )}
                  <div className="flex items-center">
                    <label
                      className={`flex items-center space-x-2 ${
                        i18n.language === 'en' ? '' : 'space-x-reverse'
                      } mb-2 text-sm font-bold text-gray-600`}
                    >
                      <Field type="checkbox" name="valEnabled" className="w-3 h-3" />
                      <span>{t('Enable Validation?')}</span>
                    </label>
                  </div>
                  <div className="flex items-center">
                    <label
                      className={`flex items-center space-x-2 ${
                        i18n.language === 'en' ? '' : 'space-x-reverse'
                      } mb-2 text-sm font-bold text-gray-600`}
                    >
                      <Field type="checkbox" name="isRequired" className="w-3 h-3" />
                      <span>{t('Required ?')}</span>
                    </label>
                  </div>
                  {/* we use it for internal form requests */}
                  <div className="flex items-center">
                    <label
                      className={`flex items-center space-x-2 ${
                        i18n.language === 'en' ? '' : 'space-x-reverse'
                      } mb-2 text-sm font-bold text-gray-600`}
                    >
                      <Field type="checkbox" name="showInList" className="w-3 h-3" />
                      <span>{t('Show In List?')}</span>
                    </label>
                  </div>

                  <div>
                    {values.frmQuestionLngs.map((form, index) => (
                      <FieldArray
                        key={form.id}
                        name="frmQuestionLngs"
                        render={(frmQuestionLngs) => (
                          <>
                            {languageId === form.languageId && (
                              <label>
                                <Field
                                  className="border rounded  w-full  p-2 text-sm focus:outline-none"
                                  placeholder={t('Validation Message')}
                                  name={`frmQuestionLngs[${index}].valMessage`}
                                  type="text"
                                />
                              </label>
                            )}
                          </>
                        )}
                      />
                    ))}
                  </div>
                  <div className={`flex items-center col-span-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
                    <label
                      className={`flex items-center space-x-2 ${
                        i18n.language === 'en' ? '' : 'space-x-reverse'
                      } mb-2 text-sm font-bold text-gray-600`}
                    >
                      <Field type="checkbox" name="showIfEnabled" className="w-3 h-3" />
                      <span>{t('Enable Condition?')}</span>
                    </label>
                  </div>
                  {values.showIfEnabled && (
                    <>
                      <div className=" mb-4 ">
                        <div
                          className={`flex items-center space-x-4 text-gray-600 ${
                            i18n.language === 'en' ? '' : 'space-x-reverse'
                          }`}
                        >
                          <div>
                            <span>{t('Show If')}</span>
                          </div>

                          <div style={{ width: 170 }}>
                            <AllQuestions fieldName="showIfQuestionId" folderId={folderId} languageId={languageId} />
                          </div>
                          <div>
                            <span>{t('Question')}</span>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`flex items-center justify-between ${
                          i18n.language === 'en' ? '' : 'space-x-reverse'
                        } space-x-2`}
                      >
                        <div>
                          <span>{t('Contains')}</span>
                        </div>
                        <div>
                          <Field
                            type="text"
                            name="showIfValue"
                            placeholder={t('contain Value')}
                            className="border rounded  w-64 py-2 px-4 text-sm focus:outline-none"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <button type="submit" className="bg-darkblue text-white py-1 px-2 rounded hover:bg-darkbluehover">
                  {t('Save')}
                </button>
              </Form>
            );
          }}
        </Formik>
        <div className="absolute bottom-0 left-0 ml-20">
          <DeleteFormQuestion id={question.id} />
        </div>
      </div>
    </div>
  );
}
