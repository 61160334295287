import axios from 'axios';

export const getNewsLetters = async (e) => {
  const query = await axios.post('/NewsLetter/GetAll', e.queryKey[1]);
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};
export const editNewsLetter = async (e) => {
  const mutation = await axios.post(`/NewsLetter/Edit`, e);
  const data = mutation.data.data;
  const isDone = mutation.data.isDone;
  return { data, isDone };
};

export const addNewsLetter = async (e) => {
  const mutation = await axios.post(`/NewsLetter/Add`, e);
  const data = mutation.data.data;
  const isDone = mutation.data.isDone;
  return { data, isDone };
};

export const deleteNewsLetter = async (id) => {
  const mutation = await axios.post(`/NewsLetter/Delete`, { id });
  const data = mutation.data.data;
  const isDone = mutation.data.isDone;
  return { data, isDone };
};
