import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import RecordAudio from '../../../SharedComponents/custom/RecordAudio';
import Button from './Button';
import MessageType from './MessageType';
import Upload from './Upload';

export default function SubmitForm(props) {
  const { specialistId, userId } = useParams();
  const initialValues = {
    consultingSpecialistId: parseInt(specialistId),
    userId: parseInt(userId),
    message: '',
    isImage: false,
    isVoiceChat: false,
    sendDatestring: `${DateTime.now().toSQL({ includeOffset: false })}`,
    sendDate: DateTime.now(),
    isFile: false,
    isSpecialist: true,
    langId: 0,
  };

  const dt = DateTime.now();
  console.log(dt.toSQL({ includeOffset: false }));
  console.log(dt.toUTC().toISO());
  console.log(dt.toJSDate().toISOString());
  console.log(new Date().toISOString());

  const queryClient = useQueryClient();
  const mutation = useMutation(mutationFunction, {
    onSuccess: () => {
      //   toast(<Notification message={t('Added, Successfully')} />);
      queryClient.invalidateQueries('ConsultingSpecialist');
    },
  });

  const onSubmit = (e, { resetForm }) => {
    mutation.mutate({
      ...e,
      sendDatestring: `${DateTime.now().toSQL({ includeOffset: false })}`,
      sendDate: DateTime.now(),
    });
    resetForm();
  };
  return (
    <>
      <div>
        <Formik onSubmit={onSubmit} initialValues={initialValues}>
          {({ handleSubmit, values, setFieldValue, setFieldTouched }) => {
            return (
              <Form>
                <div className="flex space-x-2 items-center w-full bg-white p-4  ">
                  <MessageType />
                  <Field
                    type="text"
                    name="message"
                    placeHolder="Write Your Message Here ...."
                    className="w-full px-4 py-2 outline-none"
                  />
                  {(values.isImage || values.isFile) && <Upload />}

                  <div className="relative">
                    <Button
                      disabled={mutation.isLoading}
                      loading={mutation.isLoading}
                      type="submit"
                      text="Submit"
                      className="bg-green-500 px-4 py-2 text-white rounded"
                    />
                  </div>
                </div>
                {values.isVoiceChat && <RecordAudio />}
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

export const mutationFunction = async (e) => {
  const data = await axios.post('ConsultingMessageUpdated/Add', e);

  return data;
};
