import axios from 'axios';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { NavLink } from 'react-router-dom';

export default function NotificationsCounter(props) {
  const [count, setCount] = useState(0);
  const token = localStorage.getItem('token');
  const tokenJSON = JSON.parse(token);
  const userId = tokenJSON.userId;
  const initialState = {
    id: userId,
    isSpecialist: true,
  };
  const { data, isLoading, isError } = useQuery(['notificationsCounter', initialState], queryFunction, {
    // staleTime: 99900,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {
      console.log('data', setCount(data.data));
      //   setMessageReload(!messageReload);
      // pagination(data);
    },
  });

  return (
    <NavLink to={`/notifications`} className="flex relative text-gray-400 focus:outline-none">
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
        ></path>
      </svg>

      <div class="inline-flex absolute -top-2 -right-2 justify-center items-center w-5 h-5 text-xs font-bold text-white bg-red-500 rounded-full border-2 border-white dark:border-gray-900">
        {count}
      </div>
    </NavLink>
  );
}

export const queryFunction = async (e) => {
  const { data } = await axios.post(`Notifications/GetUnReadedNotificationsCount`, e.queryKey[1]);

  return data;
};
