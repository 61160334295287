import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getPages } from './ApiMenu';

export default function PagesList(props) {
  const { languageId, setActivePage, setFieldValue } = props;
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState('');

  // Query Data To Fetch Languages
  const { data, isLoading, isError } = useQuery('pages', getPages, {
    staleTime: 360000000,
  });
  if (isLoading) return '';
  if (isError) return 'error';

  const onClick = (data) => {
    console.log('xx', data);
    setFieldValue(
      'muMenuItemLngs',
      data.pgPageLngs.map((e) => {
        return { languageId: e.languageId, title: e.title };
      })
    );
    // data.pgPageLngs.map((e, index) => setFieldValue(`muMenuItemLngs[${index}].title`, e.title));
    const categoryname = data.pgPageCategory.pgPageCategoryLngs.filter((e) => {
      if (e.languageId === languageId) {
        return e.name;
      }
      return '';
    });
    setFieldValue(`url`, data.uniqueUrl);
    setFieldValue(`pageId`, data.id);
    setFieldValue(`categoryName`, categoryname[0].name);

    const newData = {
      uniqueUrl: data.uniqueUrl,
      pgPageLngs: data.pgPageLngs,
    };
    setActivePage(newData);
  };

  const searchFilter = () => {
    const dataArray = data?.data
      ?.map((item) => {
        for (let index = 0; index < item.pgPageLngs.length; index++) {
          const element = item.pgPageLngs[index];
          if (
            element.languageId === languageId &&
            element.title.toLocaleLowerCase().includes(search.toLocaleLowerCase())
          ) {
            return item;
          } else {
            continue;
          }
        }
      })
      .filter((e) => !!e);
    return dataArray;
  };
  return (
    <>
      <h3 className={i18n.language === 'en' ? 'text-left' : 'text-right'}>{t('Pages')}</h3>
      <ul className="border rounded divide-y overflow-y-scroll max-h-32">
        <input
          type="text"
          className="w-full p-2"
          onChange={(e) => setSearch(e.target.value)}
          placeholder={t('Search')}
        />
        {searchFilter().map((e) => {
          return (
            <Fragment key={e.id}>
              <button
                onClick={(x) => {
                  onClick(e);
                }}
                type="button"
                className=" w-full text-left block px-2 py-1 hover:bg-darkbluehover hover:text-white"
              >
                {e.pgPageLngs.map((x) => {
                  return <Fragment key={x.id}>{languageId === x.languageId && x.title}</Fragment>;
                })}
              </button>
            </Fragment>
          );
        })}
      </ul>
    </>
  );
}
