import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useState } from 'react';
import ChevronRight from '../../SharedComponents/Svg/ChevronRight';
import Loading from '../../SharedComponents/Loading';
import ChevronLeft from '../../SharedComponents/Svg/ChevronLeft';
import { editLanguage, getLanguages } from './ApiLanguage';
import AddLanguage from './AddLanguage';
import DeleteLanguage from './DeleteLanguage';
import EditLanguage from './EditLanguage';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import DefaultLanguage from './DefaultLanguage';
import Notification from '../../SharedComponents/Notification';
import TableSearch from '../../SharedComponents/TableSearch';

export default function Language() {
  const [entries, setEntries] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [languageId, setLanguageId] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchLanguage, setSearchlanguage] = useState(0);
  const onSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    setSearchlanguage(languageId);
  };
  const { t, i18n } = useTranslation();
  const { data, isLoading, isError } = useQuery(['getlanguages'], getLanguages, {
    staleTime: 99900,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {
      pagination(data);
    },
  });
  const [paginationCount, setPaginationCount] = useState([]);

  function pagination(data) {
    // let paginationCount = [];
    // for (var i = 1; i <= data?.paginationResult?.numberOfPages; i++) {
    //   paginationCount.push(i);
    // }
    // setPaginationCount(paginationCount);
  }

  // to control how many rows to show in table
  const onChangeEntries = (e) => {
    setEntries(e.target.value);
  };
  const queryClient = useQueryClient();

  const editLangMutation = useMutation(editLanguage, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['getlanguages']);
      }
    },
  });
  const tabltHeader = [{ title: 'Title' }, { title: 'Options', className: 'w-64' }];
  return (
    <>
      {editLangMutation.isSuccess && <Notification message={t('Default Language Status Changed Succefully')} />}

      <header className="flex justify-between items-center">
        <BreadCrumbs to="/language" name={t('Language')} />

        <div>
          <AddLanguage id={data?.data?.length} />
        </div>
      </header>
      <div className="my-6">
        <h2 className="text-2xl font-bold uppercase">{t('Language List')}</h2>
      </div>
      {/* Table */}
      <div className="bg-white rounded border shadow-md">
        <div className="flex justify-between relative items-center px-6 py-6">
          <TableSearch onChangeEntries={onChangeEntries} entries={entries} onSearch={onSearch} />
        </div>
        {isLoading && (
          <div className="relative bg-white h-72">
            <Loading />
          </div>
        )}
        {isError && 'loading'}
        {data && (
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className=" overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y-2 divide-gray-200">
                    <thead className=" text-sm font-bold text-black">
                      <tr>
                        {tabltHeader.map((e) => {
                          return (
                            <th
                              key={e.title}
                              scope="col"
                              className={`px-6 py-3 ${
                                i18n.language === 'en' ? 'text-left' : 'text-right'
                              }  uppercase  ${e.className}`}
                            >
                              {t(e.title)}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal">
                      {data &&
                        data.data &&
                        data?.data?.map((lang) => {
                          return (
                            <tr key={lang.id}>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="flex justify-between">
                                  <span>{lang?.name}</span>

                                  {data.data.find((item) => item.defaultLang) ? (
                                    ''
                                  ) : (
                                    <DefaultLanguage
                                      editLangMutation={editLangMutation}
                                      languages={data?.data}
                                      language={lang}
                                    />
                                  )}
                                  {lang.defaultLang ? (
                                    <DefaultLanguage
                                      editLangMutation={editLangMutation}
                                      languages={data?.data}
                                      language={lang}
                                    />
                                  ) : (
                                    ''
                                  )}
                                  {/* <DefaultLanguage languages={data?.data} language={lang} /> */}
                                </div>
                              </td>

                              <td
                                className={`px-6 py-4 whitespace-nowrap flex items-center ${
                                  i18n.language === 'en' ? '' : 'space-x-reverse'
                                } space-x-2`}
                              >
                                {/* <EditRole id={user.id} name={user.name} /> */}
                                {/* <DeleteRole id={user.id} /> */}
                                <EditLanguage lang={lang} />
                                <DeleteLanguage id={lang.id} />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>

                  {/* pagination */}
                  <div>
                    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                      <div className="flex-1 flex justify-between sm:hidden">
                        <a
                          href="#"
                          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
                        >
                          {t('Previous')}
                        </a>
                        <a
                          href="#"
                          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
                        >
                          {t('Next')}
                        </a>
                      </div>
                      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                        {/* <div>
                          <p className="text-sm text-gray-700">
                             <span className="font-medium">
                              {'    '}
                              {data.data.length > data.paginationResult.dataCount
                                ? data.paginationResult.dataCount
                                : data.data.length}
                              {'    '}
                            </span>
                            of
                            <span className="font-medium">
                              {'    '}
                              {data.paginationResult.dataCount}
                              {'    '}
                            </span>
                            results
                          </p>
                        </div> */}
                        <div>
                          {paginationCount.length > 0 && (
                            <nav
                              className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                              aria-label="Pagination"
                            >
                              {pageNumber !== 1 && (
                                <button
                                  onClick={() => setPageNumber(pageNumber - 1)}
                                  className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                >
                                  <span className="sr-only">{t('Previous')}</span>
                                  <ChevronLeft className="w-5 h-5" />
                                </button>
                              )}
                              {paginationCount.map((e) => {
                                return (
                                  <button
                                    key={e}
                                    onClick={() => setPageNumber(e)}
                                    className={`relative ${
                                      pageNumber === e ? 'bg-gray-200' : ''
                                    } focus:outline-none inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50`}
                                  >
                                    {e}
                                  </button>
                                );
                              })}
                              {paginationCount.length !== pageNumber && (
                                <button
                                  onClick={() => setPageNumber(pageNumber + 1)}
                                  className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                >
                                  <span className="sr-only">{t('Next')}</span>
                                  <ChevronRight className="w-5 h-5" />
                                </button>
                              )}
                            </nav>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
