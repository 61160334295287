import axios from 'axios';

// Comon API
export const getLanguages = async () => {
  const query = await axios.post('/Language/GetAll');
  const data = query.data.data;
  const isDone = query.data.isDone;
  return { data, isDone };
};

// GalleryAlbumsApi
export const getGalleryAlbums = async (e) => {
  const query = await axios.post('/GlAlbum/GetAll', e.queryKey[1]);
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};

export const addGalleryAlbum = async (e) => {
  const { data } = await axios.post('/GlAlbum/Add', e);
  return data;
};
export const editGalleryAlbum = async (e) => {
  const { data } = await axios.post('/GlAlbum/Edit', e);
  return data;
};

export const deleteGalleryAlbum = async (id) => {
  const mutation = await axios.post(`/GlAlbum/Delete`, { id });
  const data = mutation.data.data;
  const isDone = mutation.data.isDone;
  return { data, isDone };
};
