import jwt_decode from 'jwt-decode';
export default function usePermission() {
  const localstorage = localStorage.getItem('token');
  if (localstorage) {
    const decoded = jwt_decode(JSON.parse(localstorage).par);
    const permissions = decoded['Application.Permission'];
    return permissions;
  }
  return '';
}
