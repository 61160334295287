import logokey from '../assets/img/logokey.svg';

export default function Loading(props) {
  const { message } = props;
  return (
    <>
      <div className='absolute top-0 right-0 z-50 flex h-full w-full items-center justify-center '>
        <div className='text-center'>
          <img
            className='animate-bounce inline-block '
            src={logokey}
            alt='loading'
          />
          <small className='block text-sm text-gray-400 animate-pulse'>
            {message ? message : 'Loading'}
          </small>
        </div>
      </div>
    </>
  );
}
