import axios from 'axios';

export const deleteStatisticItem = async (e) => {
  const { data } = await axios.post('/PgSection/DeleteStatisticItem', e);
  return data;
};

export const editStatisticItem = async (e) => {
  const { data } = await axios.post('/PgSection/EditStatisticItem', e);
  return data;
};

export const addStatisticItem = async (e) => {
  const { data } = await axios.post('/PgSection/AddStatisticItem', e);
  return data;
};

export const editStatistic = async (e) => {
  const { data } = await axios.post('/PgSection/EditStatistic', e);
  return data;
};
