import { Field, FieldArray, Form, Formik, useFormikContext } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import Home from '../../../SharedComponents/Svg/Home';
import PlusCircle from '../../../SharedComponents/Svg/PlusCircle';
import { addPoll, getLanguages } from '../ApiPolls';
import Loading from '../../../SharedComponents/Loading';
import Question from './Question';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../../../SharedComponents/BreadCrumbs';
import LanguageList from '../../../SharedComponents/LanguageList';

export default function CreatePoll(props) {
  const [languageId, setLanguageId] = useState(1);
  const { t, i18n } = useTranslation();
  // Query Data To Fetch Languages
  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  useEffect(() => {
    setLanguageId(languages.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [languages.data]);

  const pollLngs = languages?.data?.data.map((language) => ({
    languageId: language.id,
    title: '',
    voteBtnTitle: '',
  }));

  const pollQOptionLngs = languages?.data?.data.map((language) => ({
    languageId: language.id,
    title: '',
  }));
  const pollQuestionLngs = languages?.data?.data.map((language) => ({
    languageId: language.id,
    title: '',
  }));
  const pollQuestions = [
    {
      pollQuestionLngs: pollQuestionLngs,
      pollQOptions: [{ pollQOptionLngs }],
    },
  ];
  const initialValues = {
    pollLngs: pollLngs,
    isActive: true,
    showResults: true,
    appearOnce: true,
    isForAllUsers: 'true',
    isMultiQuestion: true,
    pollQuestions: pollQuestions,
  };

  // Mutate Data to Create New Page
  const mutation = useMutation(addPoll, {
    onSuccess: async (data) => {
      if (data.isDone) {
        window.location.href = `/Polls/${data.outId}`;
      }
    },
  });
  // Handling onSubmit Form
  const onSubmit = async (e) => {
    const newData = {
      ...e,
      isForAllUsers: e.isForAllUsers === 'true' || e.isForAllUsers === true ? true : false,
    };
    try {
      await mutation.mutate(newData);
    } catch (error) {}
  };
  const [x, setX] = useState({});

  if (languages.isLoading) return <Loading />;
  return (
    <>
      <header className="flex justify-between items-center mb-4">
        <BreadCrumbs to="/polls" name={t('Polls')} />
      </header>
      <Formik onSubmit={onSubmit} initialValues={initialValues}>
        {({ values, setFieldValue, setValues }) => {
          return (
            <Form>
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-2 bg-white shadow border rounded p-4 text-gray-600 font-bold">
                  <div className="flex justify-between relative items-center">
                    <div className="flex transform -translate-y-1 relative z-10">
                      <span className="text-2xl transform -translate-y-1">{t('Create Poll')}</span>
                    </div>
                    <div>
                      <div>
                        <LanguageList
                          className="flex justify-end transform translate-y-1 relative z-10 mb-4"
                          languages={languages?.data?.data}
                          languageId={languageId}
                          setLanguageId={setLanguageId}
                        />
                      </div>
                    </div>
                    <hr className="border w-full absolute top-0 left-0 z-0 mt-11" />
                  </div>
                  <div className="py-4 w-full">
                    {values &&
                      values.pollLngs &&
                      values?.pollLngs.map((setting, index) => (
                        <FieldArray
                          key={index}
                          name="pollLngs"
                          render={() => (
                            <>
                              {languageId === setting.languageId && (
                                <>
                                  <label>
                                    <b
                                      className={`font-semibold block text-gray-700 ${
                                        i18n.language === 'en' ? '' : 'text-right'
                                      }`}
                                    >
                                      {t('Title')}
                                    </b>
                                    <Field
                                      className="border rounded  w-full mb-2 p-1"
                                      type="text"
                                      name="title"
                                      name={`pollLngs[${index}].title`}
                                    />
                                  </label>
                                </>
                              )}
                            </>
                          )}
                        />
                      ))}

                    <div className="text-right mb-4 mt-2 flex justify-end">
                      <button
                        type="button"
                        className={`bg-lightgreen space-x-1 ${
                          i18n.language === 'en' ? '' : 'space-x-reverse'
                        } text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
                        onClick={() => {
                          setFieldValue('pollQuestions', [...values.pollQuestions, ...pollQuestions]);
                        }}
                      >
                        <PlusCircle className="w-5 h-5" /> <span>{t('Add New Question')}</span>
                      </button>
                    </div>
                    {values &&
                      values.pollQuestions &&
                      values?.pollQuestions.map((pollQuestion, index) => {
                        const mainIndex = index;
                        return (
                          <>
                            <Question
                              key={index}
                              languageId={languageId}
                              Question={pollQuestion}
                              mainIndex={mainIndex}
                              values={values}
                              pollQuestions={pollQuestions}
                              pollQOptionLngs={pollQOptionLngs}
                              setValues={setValues}
                              setFieldValue={setFieldValue}
                              // Question={`pollQuestions[${index}]`}
                            />
                          </>
                        );
                      })}
                  </div>
                </div>
                <div className="bg-white shadow border rounded p-4 text-gray-600 font-bold">
                  <div
                    className={`border-b-2 text-gray-500 flex space-x-2  py-2 ${
                      i18n.language === 'en' ? '' : 'space-x-reverse'
                    }`}
                  >
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                      ></path>
                    </svg>
                    <span className="font-bold">{t('Options')}</span>
                  </div>
                  <div className="py-4">
                    {values &&
                      values.pollLngs &&
                      values?.pollLngs.map((setting, index) => (
                        <FieldArray
                          key={index}
                          name="pollLngs"
                          render={() => (
                            <>
                              {languageId === setting.languageId && (
                                <>
                                  <label>
                                    <b className="font-semibold block text-gray-700">{t('Write vote button title')}</b>
                                    <Field
                                      className="border rounded  w-full mb-2 p-1"
                                      type="text"
                                      name="title"
                                      name={`pollLngs[${index}].voteBtnTitle`}
                                    />
                                  </label>
                                </>
                              )}
                            </>
                          )}
                        />
                      ))}

                    <label className={` mb-2 block`}>
                      {t('Date')}
                      <Field className="w-full border p-2 rounded " type="date" name="pageDate" />
                    </label>
                    <div className="grid grid-cols-3">
                      <label
                        className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
                      >
                        <Field type="radio" value="true" name="isForAllUsers" className="w-4 h-4" />
                        <span>{t('For All')}</span>
                      </label>
                      <label
                        className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
                      >
                        <Field type="radio" value="false" name="isForAllUsers" className="w-4 h-4" />
                        <span>{t('Members')}</span>
                      </label>
                    </div>
                    <label className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
                      <Field type="checkbox" name="appearOnce" className="w-4 h-4" />
                      <span>{t('Ask user once in home page')}</span>
                    </label>
                    <label className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
                      <Field type="checkbox" name="showResults" className="w-4 h-4" />
                      <span>{t('Show results for users')}</span>
                    </label>
                    <label className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
                      <Field type="checkbox" name="isActive" className="w-4 h-4" />
                      <span>{t('Active')}</span>
                    </label>
                  </div>
                  <div className="text-right">
                    <button type="submit" className="bg-darkblue hover:bg-darkbluehover text-white px-4 py-2 rounded">
                      {t('Save')}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
