import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import { initializeApp } from 'firebase/app';

var firebaseConfig = {
  apiKey: 'AIzaSyB-wsL89O8nRYEO-nR5n8Cyg_ydgOt176w',
  authDomain: 'sanad-keyfra.firebaseapp.com',
  projectId: 'sanad-keyfra',
  storageBucket: 'sanad-keyfra.appspot.com',
  messagingSenderId: '1027892936176',
  appId: '1:1027892936176:web:7382c8e9722c7c72433135',
};

initializeApp(firebaseConfig);

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (setTokenFound, setFirebaseToken) => {
  return getToken(messaging, {
    vapidKey: 'BI9gFBg1HEGfS9rqjyXLQIhLy2NbisPbi4cviZjfEu9hNKwcxJVKr9op6ae69s2WHnjhIADf7JI3fyyHqFIvZuY',
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log('Token: ', currentToken);
        setTokenFound(true);
        setFirebaseToken(currentToken);

        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
