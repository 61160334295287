import { Fragment } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import OutsideClickHandler from './OutsideClickHandler';
import Filter from './Svg/Filter';
import { getCategories } from '../Views/Categories/ApiCategories';
import XCircle from './Svg/XCircle';

export default function TableFilter(props) {
  const { onFilter, languageId, reset } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [searchParam, setSearchParam] = useState('');
  const [category, setCategory] = useState();
  const [isActive, setIsActive] = useState();
  const { t, i18n } = useTranslation();
  const onSearchParam = (e) => {
    setSearchParam(e.target.value);
  };
  let categorystate = {
    pageNumber: 0,
    pageSize: 0,
    isPaging: false,
    langId: 0,
    isSearch: false,
  };

  const CategoriesQuery = useQuery(['categories', categorystate], getCategories);

  const categories = CategoriesQuery?.data?.data?.map((e) => {
    const catData = e.pgPageCategoryLngs?.filter((lang) => {
      if (e?.pgPageCategoryLngs?.length === 1) {
        return lang?.name;
      }
      if (lang?.languageId === languageId) {
        return lang?.name;
      } else {
        return;
      }
    });
    if (catData.length === 0) {
      return '';
    } else {
      return catData?.reduce((e) => e?.name);
    }
  });

  const onClick = (data) => {
    setCategory(data);
    setSearchParam(data.name);
    onFilter(data.name);
  };
  return (
    <OutsideClickHandler setIsOpen={setIsActive}>
      <div className={`relative z-20   ${i18n.language === 'en' ? 'ml-3' : 'mr-3'}`}>
        <button
          onClick={() => setIsActive((e) => !e)}
          className="bg-orange hover:bg-orangehover transition duration-500 text-white rounded p-1"
        >
          <Filter className="w-4 h-4" />
        </button>
        {isActive && (
          <div className="absolute w-60 pt-1 ">
            <label className="flex relative">
              <input onChange={onSearchParam} className="border-2 rounded  w-full mb-0 p-1" value={searchParam} />
              <button className="absolute top-1 right-1 text-red-500" onClick={reset}>
                <XCircle />
              </button>
            </label>

            <div className="absolute bottom-0 left-0 bg-white w-full transform translate-y-full pl-0 block  ">
              <ul
                className={`${
                  categories?.filter((e) => e?.name?.toLowerCase()?.includes(searchParam?.toLowerCase()))?.length !== 0
                    ? 'border'
                    : ''
                }  rounded divide-y h-20 overflow-y-auto max-h-64 mt-2`}
              >
                {categories
                  ?.filter((e) => e?.name?.toLowerCase()?.includes(searchParam?.toLowerCase()))
                  ?.map((e) => {
                    return (
                      <Fragment key={e.id}>
                        <button
                          onClick={(x) => {
                            onClick(e);
                          }}
                          type="button"
                          className={`${
                            category?.id === e.id ? 'bg-darkbluehover text-white' : ''
                          } w-full text-left block px-2 py-1 hover:bg-darkbluehover hover:text-white`}
                        >
                          {e.name}
                        </button>
                      </Fragment>
                    );
                  })}
              </ul>
            </div>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
}
