import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChevronLeft from './Svg/ChevronLeft';
import ChevronRight from './Svg/ChevronRight';

export default function SectionPagination(props) {
  const { data, setPageNumber, pageNumber } = props;
  const paginationResult = {
    numberOfPages: Math.floor(data.length / 10) + 1,
    dataCount: 10,
  };
  console.log('data', data.length / 10);
  console.log('paginationResult', paginationResult);
  const [paginationCount, setPaginationCount] = useState([]);

  function pagination(data) {
    let paginationCount = [];
    for (var i = 1; i <= paginationResult.numberOfPages; i++) {
      paginationCount.push(i);
    }
    setPaginationCount(paginationCount);
  }
  useEffect(() => {
    pagination(data);
  }, []);

  const { t, i18n } = useTranslation();
  return (
    <>
      <div>
        <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
          <div className="flex-1 flex justify-between sm:hidden">
            <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
              Previous
            </span>
            <span className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
              Next
            </span>
          </div>
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              {/* <p className="text-sm text-gray-700">
                {t('Showing')}
                <span className="font-medium">
                  {'    '}
                  {data?.length > paginationResult?.dataCount ? paginationResult.dataCount : data.length}
                  {'    '}
                </span>
                {t('of')}
                <span className="font-medium">
                  {'    '}
                  {paginationResult.dataCount}
                  {'    '}
                </span>
                {t('results')}
              </p> */}
            </div>
            <div>
              {console.log('paginationCount', paginationCount)}
              {paginationCount.length > 0 && (
                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                  {pageNumber !== 1 && (
                    <button
                      type="button"
                      onClick={() => setPageNumber(pageNumber - 1)}
                      className={`relative inline-flex items-center px-2 py-2 ${
                        i18n.language === 'en' ? 'rounded-l-md' : 'rounded-r-md'
                      }  border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50`}
                    >
                      <span className="sr-only">Previous</span>
                      {i18n.language === 'en' ? (
                        <ChevronLeft className="w-5 h-5" />
                      ) : (
                        <ChevronRight className="w-5 h-5" />
                      )}
                    </button>
                  )}
                  {paginationCount.map((e) => {
                    return (
                      <button
                        type="button"
                        key={e}
                        onClick={() => setPageNumber(e)}
                        className={`relative ${
                          pageNumber === e ? 'bg-gray-200' : ''
                        } focus:outline-none inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50`}
                      >
                        {e}
                      </button>
                    );
                  })}
                  {paginationCount.length !== pageNumber && (
                    <button
                      type="button"
                      onClick={() => setPageNumber(pageNumber + 1)}
                      className={`relative inline-flex items-center px-2 py-2  ${
                        i18n.language === 'en' ? 'rounded-r-md' : 'rounded-l-md'
                      } border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50`}
                    >
                      <span className="sr-only">Next</span>
                      {i18n.language === 'en' ? (
                        <ChevronRight className="w-5 h-5" />
                      ) : (
                        <ChevronLeft className="w-5 h-5" />
                      )}
                    </button>
                  )}
                </nav>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
