import axios from 'axios';

export const getFaqs = async (e) => {
  const query = await axios.post('/FAQ/GetAll', e.queryKey[1]);
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};

// export const getAllFormsRole =

export const getAllFormsRole = async (e) => {
  const query = await axios.post('/FrmForm/GetAllFormsRole', e.queryKey[1]);
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};

// export const addFormRole = async (e) => {
//   const { data } = await axios.post('​/FrmForm/AddFormsRole​', e);
//   return data;
// };
export const addFormRole = async (e) => {
  const { data } = await axios.post('/FrmForm/AddFormsRole', e);
  return data;
};
export const getLanguages = async () => {
  const query = await axios.post('/Language/GetAll');
  const data = query.data.data;
  const isDone = query.data.isDone;
  return { data, isDone };
};

export const addFaq = async (e) => {
  const { data } = await axios.post('/FAQ/Add', e);
  return data;
};

export const editFormsRole = async (e) => {
  const { data } = await axios.post('/FrmForm/EditFormsRole', e);
  return data;
};

export const GetFormsRole = async (e) => {
  const { data } = await axios.get(`/FrmForm/GetFormsRole?id=${e.queryKey[1].id}&langId=0`);
  return data;
};

export const deleteFormsRole = async (id) => {
  const data = await axios.post('/FrmForm/DeleteFormsRole', { id });
  return data;
};
