import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import Photograph from '../../SharedComponents/Svg/Photograph';
const DropZone = ({ onDrop, accept, images }) => {
  // initializing useDropZonehooks with options
  const { t } = useTranslation();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  });

  return (
    <div className="  h-32  flex items-center justify-center text-gray-400 text-sm" {...getRootProps()}>
      <input className="dropzone-input focus:outline-none" type="file" {...getInputProps()} />
      <div className="text-center">
        {isDragActive ? (
          <div className="dropzone-content text-center">
            <Photograph className="inline-block w-8 h-8" />
            <span className="block">{t('Release to drop the files here')}</span>
          </div>
        ) : (
          <div className="dropzone-content text-center">
            <Photograph className="inline-block w-8 h-8" />
            <span className="block">{t('Upload file or drag and drop')}</span>
            <span>{t('up to 10MB')}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default DropZone;
