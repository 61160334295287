// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Percentage: 'نسبة مئوية',
  'Add New Statistic': 'إضافة إحصائية',
  'Add Number': 'أضف رقم',
  'Add Measure Unit': 'أضف وحدة قياس',
  'Added StaticItem, Successfully': 'تم إضافة إحصائية بنجاح',
  'Statistic Item Updated': 'تم تحديث الإحصائية بنجاح',
  'Delete Statistic Item': 'حذف الإحصائية من الكارت',
  'Are you sure you want to delete this Statistic Item? All the data will be permanently removed from our servers forever. This action cannot be undone':
    'هل أنت متأكد أنك تريد حذف إحصائية من الكارت هذا؟سيتم إزالتها بشكل دائم من خوادمنا إلى الأبد.لا يمكن التراجع عن هذا الإجراء',
  'Statistic Item, Deleted': 'تم مسح الإحصائية',
  'Sorry, This Statistic Item have Data Inside, Please Delete and Retry':
    'عذرا، هذه الكارت تحتوي على بيانات بداخلها يرجى حذف وإعادة المحاولة',
  'Add New Statistic Item': 'إضافة إحصائية جديدة',
};
