import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Home from './Svg/Home';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { getSetting } from '../Views/Settings/ApiSettings';

export default function BreadCrumbs(props) {
  const { to, name, morePages = [], backTo } = props;
  const { t, i18n } = useTranslation();
  const getSettingsQuery = useQuery('getSettings', getSetting);
  const englishTitle = getSettingsQuery?.data?.data?.settingLngs?.filter((e) => e.languageId === 2)[0]?.cmsTitle;
  const arabicTitle = getSettingsQuery?.data?.data?.settingLngs?.filter((e) => e.languageId === 1)[0]?.cmsTitle;
  return (
    <>
      <Helmet>
        <title>
          {`${i18n.language === 'en' ? englishTitle : arabicTitle} | ${name} ${
            morePages.length !== 0 ? '| ' + morePages?.map((e) => e?.name)?.join(' | ') : ''
          }`}
        </title>
        {/* <title>Keyframe CMS</title> */}
      </Helmet>

      <div className={`flex items-center justify-between ${backTo ? 'w-full' : ''}`}>
        <div className="flex items-center bg-white rounded border shadow">
          <div className={` ${i18n.language === 'en' ? ' pl-4 pr-12 ' : 'pl-12 pr-4'}  py-2 relative overflow-hidden`}>
            <a href="/">
              <Home />
            </a>
            <div className="text-gray-100">
              <hr
                className={`w-11 border text-gray-50 border-gray-50 absolute transform ${
                  i18n.language === 'en' ? ' -rotate-45 right-0 ' : 'rotate-45 left-0'
                }   bottom-0 mb-1`}
              />
              <hr
                className={`w-11 border text-gray-50 border-gray-50 absolute transform ${
                  i18n.language === 'en' ? ' rotate-45 right-0 ' : '-rotate-45 left-0 '
                }  top-0 mt-1`}
              />
            </div>
          </div>
          <div className={` ${i18n.language === 'en' ? ' pl-4 pr-12 ' : 'pl-12 pr-4'}  py-2 relative overflow-hidden`}>
            <a href="/dashboard">{t('Dashboard')}</a>
            <div className="text-gray-100">
              <hr
                className={`w-11 border text-gray-50 border-gray-50 absolute transform ${
                  i18n.language === 'en' ? ' -rotate-45 right-0 ' : 'rotate-45 left-0'
                }   bottom-0 mb-1`}
              />
              <hr
                className={`w-11 border text-gray-50 border-gray-50 absolute transform ${
                  i18n.language === 'en' ? ' rotate-45 right-0 ' : '-rotate-45 left-0 '
                }  top-0 mt-1`}
              />
            </div>
          </div>

          <div className={` ${i18n.language === 'en' ? ' pl-4 pr-12 ' : 'pl-12 pr-4'}  py-2 relative overflow-hidden`}>
            <Link to={to}>{name}</Link>
            {morePages.length !== 0 && (
              <div className="text-gray-100">
                <hr
                  className={`w-11 border text-gray-50 border-gray-50 absolute transform ${
                    i18n.language === 'en' ? ' -rotate-45 right-0 ' : 'rotate-45 left-0'
                  }   bottom-0 mb-1`}
                />
                <hr
                  className={`w-11 border text-gray-50 border-gray-50 absolute transform ${
                    i18n.language === 'en' ? ' rotate-45 right-0 ' : '-rotate-45 left-0 '
                  }  top-0 mt-1`}
                />
              </div>
            )}
          </div>
          {morePages.map((item, index) => (
            <div
              className={` ${i18n.language === 'en' ? ' pl-4 pr-12 ' : 'pl-12 pr-4'}  py-2 relative overflow-hidden`}
            >
              <Link to={item.to}>{item.name}</Link>
              {index + 1 !== morePages.length && (
                <div className="text-gray-100">
                  <hr
                    className={`w-11 border text-gray-50 border-gray-50 absolute transform ${
                      i18n.language === 'en' ? ' -rotate-45 right-0 ' : 'rotate-45 left-0'
                    }   bottom-0 mb-1`}
                  />
                  <hr
                    className={`w-11 border text-gray-50 border-gray-50 absolute transform ${
                      i18n.language === 'en' ? ' rotate-45 right-0 ' : '-rotate-45 left-0 '
                    }  top-0 mt-1`}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
        {!!backTo && (
          <Link
            to={backTo}
            className={`${
              i18n.language === 'en' ? '' : 'sapace-x-reverse'
            } bg-lightgreen space-x-1 space-x-reverse text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
          >
            <span>{t('GO BACK')}</span>
          </Link>
        )}
      </div>
    </>
  );
}
