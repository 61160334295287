import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Modal from '../../../SharedComponents/Modal';
import PecnilAlt from '../../../SharedComponents/Svg/PencilAlt';
import { assignRole, editRole, editUser, getRoles } from './ApiUser';

export default function EditUser(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { user, roles } = props;

  return (
    <>
      <button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className="bg-green-300 text-green-800 hover:bg-green-400  p-1 rounded transition duration-300 ease-in-out"
      >
        <PecnilAlt className="w-5 h-5" />
      </button>
      {isOpen && <ModalContainer user={user} roles={roles} isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
}

const ModalContainer = (props) => {
  const queryClient = useQueryClient();
  const { user, roles, isOpen, setIsOpen } = props;
  const [newUserRole, setNewUserRole] = useState(user.roleId);
  const findRoleById = (name) => {
    const m = roles.data.data.find((e) => e.name === 'Admin');
    return m.id;
  };
  findRoleById(user.role);
  const assignNewRole = useMutation(assignRole, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['users']);
      if (data.isDone) {
        setIsOpen(false);
      }
    },
  });
  const { mutate, data } = useMutation(editUser, {
    onSuccess: (data) => {
      // queryClient.invalidateQueries(['users']);
      if (data.isDone) {
        try {
          const roleData = {
            userId: user.id,
            rolesIds: [newUserRole],
            deleteOldRoles: true,
          };
          assignNewRole.mutate(roleData);
        } catch (error) {}
        setIsOpen(false);
      }
    },
  });

  const onSubmit = async (e) => {
    const role = e.role ? parseInt(e.role) : 1;
    setNewUserRole(parseInt(e.role ? parseInt(e.role) : 1));

    const newData = {
      ...e,
      role,
    };
    try {
      await mutate(newData);
    } catch (error) {
      console.log('error', error);
    }
  };
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={{ ...user, role: findRoleById(user.role) }}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values);
        resetForm();
      }}
    >
      <Form>
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} title={t('Edit User')} add={true}>
          {data?.isDone === false && (
            <span className="border-red-400 mb-4 border text-center block rounded mt-4 text-red-400 p-2">
              {data?.isMessage}
            </span>
          )}
          <div className="flex flex-col">
            <Field
              style={{ textIndent: '10px' }}
              placeholder={t('Enter Name')}
              className="border rounded  w-full mb-4 p-1 hidden"
              name="id"
              type="number"
            />
            <Field
              style={{ textIndent: '10px' }}
              placeholder={t('Enter Name')}
              className="border rounded  w-full mb-4 p-1"
              name="userName"
              type="text"
            />
            <Field
              style={{ textIndent: '10px' }}
              placeholder={t('Enter Email')}
              className="border rounded  w-full mb-4 p-1"
              name="email"
              type="text"
            />
            <Field
              style={{ textIndent: '10px' }}
              placeholder={t('Enter Phone')}
              className="border rounded  w-full mb-4 p-1"
              name="phone"
              type="number"
            />
            <Field
              style={{ textIndent: '10px' }}
              placeholder={t('Enter your new password')}
              className="border rounded  w-full mb-4 p-1"
              name="password"
              type="password"
              autoComplete="on"
            />
            <Field
              style={{ textIndent: '10px' }}
              placeholder={t('Enter your new password')}
              className="border rounded  w-full mb-4 p-1 "
              name="role"
              as="select"
            >
              {roles &&
                roles.data &&
                roles.data.data &&
                roles.data.data.map((role) => {
                  return <option value={parseInt(role.id)}>{role.name}</option>;
                })}
            </Field>
            <Field
              style={{ textIndent: '10px' }}
              placeholder="Enter your new password"
              className="border rounded  w-full mb-4 p-1 hidden"
              name="isActive"
              type="checkbox"
            />
          </div>
        </Modal>
      </Form>
    </Formik>
  );
};
