import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { editCategory } from './ApiCategories';

export default function TogglePrimary(props) {
  const { category, id } = props;
  const [activeToggle, setActiveToggle] = useState(category.isPrimary);

  const editCategoryMutation = useMutation(editCategory, {
    onSuccess: async (data) => {
      if (data.isDone) {
        setActiveToggle((e) => !e);
      }
    },
  });

  const handleClick = () => {
    editCategoryMutation.mutate({
      ...category,
      isPrimary: !activeToggle,
    });
  };
  return (
    <button onClick={handleClick} className="flex items-center cursor-pointer focus:outline-none">
      {/* <!-- toggle --> */}
      <div className="relative">
        {/* <!-- line --> */}
        <div className={` w-12 h-6 ${activeToggle ? 'bg-lightgreen' : 'bg-gray-400'}  rounded-full shadow-inner`}></div>
        {/* <!-- dot --> */}
        <div
          className={`transition-all ease-in-out duration-700 absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 ${
            activeToggle ? 'right-0' : 'left-0'
          } `}
        ></div>
      </div>
      {/* <!-- label --> */}
    </button>
  );
}
