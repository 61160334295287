import { Field, FieldArray, Form, Formik } from 'formik';
import { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import Notification from '../../../../SharedComponents/Notification';
import ChevronRight from '../../../../SharedComponents/Svg/ChevronRight';
import DeleteSection from '../DeleteSection';
import EditSection from '../EditSection';
import SectionHeader from '../SectionHeader';
import ToggleSectionStatus from '../ToggleSectionStatus';
import AddStaticItem from './AddStaticItem';
import { addStatisticItem, editStatistic } from './ApiStatistics';
import StatisticItem from './StatisticItem';
import LoadingCircle from '../../../../SharedComponents/Svg/LoadingCircle';
import LanguageList from '../../../../SharedComponents/LanguageList';

export default function Statistics(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { id, section, languages, editSectionMutation, provided } = props;
  const [languageId, setLanguageId] = useState(1);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const addStaticItemMutation = useMutation(addStatisticItem, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.resetQueries('sections');
        setIsOpen(false);
      }
    },
  });
  const editStaticItemMutation = useMutation(editStatistic, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['sections']);
      }
    },
  });

  const onSaveSettings = async (values) => {
    try {
      await editStaticItemMutation.mutate(values.pgStatistics);
    } catch (error) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    setLanguageId(languages?.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [languages.data]);

  return (
    <div className="block bg-white w-full shadow border mb-4">
      {editStaticItemMutation.isSuccess && <Notification message={t('Section Updated')} />}

      {editStaticItemMutation.isError && <Notification error={true} message={t('Network Error')} />}

      <SectionHeader
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        section={section}
        languageId={languageId}
        provided={provided}
      />
      <Formik initialValues={section}>
        {({ values, setFieldValue, setValues }) => {
          return (
            <>
              <>
                <div className={`bg-white px-4  overflow-hidden      ${isOpen ? 'py-3 h-full  ' : '  py-0 h-0'}`}>
                  <div className="">
                    <div className="flex justify-between relative items-center">
                      <div className="flex transform -translate-y-1 relative z-10">
                        {t('Section Type')} : {t('Statistics')}
                      </div>
                      <div>
                        <div>
                          <LanguageList
                            languages={languages.data?.data}
                            languageId={languageId}
                            setLanguageId={setLanguageId}
                            className="flex justify-end transform translate-y-1 relative z-10 mb-4"
                          />
                        </div>
                      </div>
                      <hr className="border w-full absolute top-0 left-0 z-0 mt-11" />
                    </div>
                  </div>
                  <div className="bg-gray-100 p-4">
                    <div> {t('Section Settings')} </div>
                    <hr className="mt-2 mb-4 block" />
                    <div className="grid grid-cols-3 gap-6 items-center flex">
                      <div>
                        <label
                          className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
                        >
                          <Field type="checkbox" name="pgStatistics.isPercentage" className="w-4 h-4" />
                          <span>{t('Percentage')}</span>
                        </label>
                      </div>
                    </div>
                    <div className="flex justify-end mt-4">
                      <button
                        type="button"
                        disabled={editStaticItemMutation.isLoading}
                        onClick={() => {
                          onSaveSettings(values);
                        }}
                        className={`bg-darkblue relative focus:outline-none space-x-1 ${
                          i18n.language === 'en' ? '' : 'space-x-reverse'
                        } text-sm font-bold text-white flex items-center hover:bg-darkbluehover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
                      >
                        {t('Save Settings')}
                        {editStaticItemMutation.isLoading && <LoadingCircle />}
                      </button>
                    </div>
                  </div>

                  <FieldArray
                    name="pgStatistics.pgStatisticsItems"
                    render={(arrayHelpers) => (
                      <>
                        {values.pgStatistics.pgStatisticsItems.map((pgStatisticsItem, index) => {
                          return (
                            <Fragment key={index}>
                              <>
                                <StatisticItem
                                  languageId={languageId}
                                  languages={languages}
                                  pgStatisticsItem={pgStatisticsItem}
                                  setFieldValue={setFieldValue}
                                  pgStatisticsItemIndex={index}
                                  pgStatisticsItemsHelper={arrayHelpers}
                                />
                              </>
                            </Fragment>
                          );
                        })}
                      </>
                    )}
                  />

                  <div className={`flex justify-end py-4 space-x-4 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
                    <AddStaticItem
                      pgStatisticsId={section.pgStatisticsId}
                      languages={languages}
                      orderNumber={section.pgStatistics.pgStatisticsItems.length}
                      addStaticItemMutation={addStaticItemMutation}
                      // addCardItemMutation={addCardItemMutation}
                    />
                    {/* <button
                    type="button"
                    onClick={() => {
                      onSubmit(values);
                    }}
                    className="bg-lightgreen py-2 px-2 flex justify-center text-center h-full text-sm font-bold text-white items-center hover:bg-lightgreenhover text-centerhover shadow  rounded transition duration-300 ease-in-out"
                  >
                    {t('Save Section')}
                  </button> */}
                  </div>
                </div>
              </>
            </>
          );
        }}
      </Formik>
      {addStaticItemMutation.isSuccess && <Notification message={t('Added StaticItem, Successfully')} />}
      {addStaticItemMutation.isError && <Notification error={true} message={t('Network Error')} />}
    </div>
  );
}
