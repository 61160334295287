import { Field, FieldArray, Formik } from 'formik';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import Notification from '../../../../SharedComponents/Notification';
import { editCardItem } from './ApiCard';
import CardPagesList from './CardPagesList';
import DeleteCardItem from './DeleteCardItem';
import AddThumbnail from '../../Pages/AddThumbnail';
import AddYoutube from '../../../../SharedComponents/AddYoutube';
import RichTextEditor from '../../Pages/RichTextEditor';
import LoadingButton from '../../../../SharedComponents/Svg/LoadingCircle';
import DuplicateCard from './DuplicateCard';
import CustomFields from './CustomFields';
import { useEffect } from 'react';

export default function CardItem(props) {
  const regEx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;

  const { pgSectionCardItem, pgSectionCardItemIndex, pgSectionCardItemsHelper, languageId, setFieldValue } = props;
  const { t, i18n } = useTranslation();
  const imagePositions = [
    { id: 0, name: 'Top' },
    { id: 1, name: 'Bottom' },
    { id: 2, name: 'Left' },
    { id: 3, name: 'Right' },
  ];

  const queryClient = useQueryClient();
  const editCardItemMutation = useMutation(editCardItem, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['sections']);
      }
    },
  });

  const onSubmit = async (values) => {
    setFieldValue(`card.pgSectionCardItems[${pgSectionCardItemIndex}]`, values);
    try {
      await editCardItemMutation.mutate(values);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {}, []);
  return (
    <div className="mt-4 shadow p-4 border rounded ">
      {editCardItemMutation.isSuccess && <Notification message={t('Card Item Updated')} />}
      <Formik
        enableReinitialize
        initialValues={{
          ...pgSectionCardItem,
          isExternalLink:
            pgSectionCardItem.isExternalLink === true || pgSectionCardItem.isExternalLink === 'true' ? 'true' : 'false',
          isVideo: pgSectionCardItem.isVideo === true || pgSectionCardItem.isVideo === 'true' ? 'true' : 'false',
        }}
      >
        {({ values, setFieldValue, setValues }) => {
          console.log('values', values);
          values.date = values.date ? DateTime.fromISO(values?.date).toISODate() : null;
          const url = values?.imageUrl?.match(regEx);
          return (
            <>
              <div>
                <div className="grid grid-cols-2 gap-6">
                  <div>
                    <div className="grid grid-cols-3 mb-2">
                      <label
                        className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
                      >
                        <input
                          type="radio"
                          checked={values.isVideo === 'false'}
                          onChange={() => {
                            setFieldValue('isVideo', 'false');
                          }}
                          className="w-4 h-4"
                        />

                        <span>{t('Image')}</span>
                      </label>
                      <label
                        className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
                      >
                        <input
                          type="radio"
                          checked={values.isVideo === 'true'}
                          onChange={() => {
                            setFieldValue('isVideo', 'true');
                          }}
                          className="w-4 h-4"
                        />
                        <span>{t('Video')}</span>
                      </label>
                    </div>
                    <Field className={`border rounded hidden w-full p-1 `} name={`imageId`} type="number" />
                    {values.isVideo === 'false' && (
                      <>
                        <div className="h-64 focus:outline-none group rounded border-dashed border-2 mb-4 relative p-2">
                          {(values.imageUrl && (
                            <>
                              <img
                                className="object-contain h-full w-full"
                                alt="img"
                                src={`${window.env.REACT_APP_IMAGE_PATH}${values.imageUrl}`}
                              />
                              <div className="absolute group-hover:opacity-100 opacity-0 inset-0 flex items-center justify-center">
                                <AddThumbnail languageId={languageId} edit={true} setField={setFieldValue} />
                              </div>
                            </>
                          )) || (
                            <div className="absolute inset-0 flex items-center justify-center">
                              <AddThumbnail languageId={languageId} setField={setFieldValue} />
                            </div>
                          )}
                        </div>
                        {values.imageId && (
                          <button
                            className="block mb-4 bg-red-700 text-white w-full rounded p-1"
                            type="button"
                            onClick={() => {
                              setFieldValue('imageId', null);
                            }}
                          >
                            {t('Remove Image')}
                          </button>
                        )}
                      </>
                    )}
                    {values.isVideo === 'true' && (
                      <>
                        <div className="h-64 focus:outline-none group rounded border-dashed border-2 mb-4 relative">
                          {(values.imageUrl && url && (
                            <>
                              <iframe
                                style={{ width: '100%' }}
                                height="315"
                                src={`https://www.youtube.com/embed/${url[1]}`}
                                title="video"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                              ></iframe>
                              <div className="absolute group-hover:opacity-100 opacity-0 inset-0 flex items-center justify-center">
                                <AddYoutube fieldName="imageUrl" edit={true} setField={setFieldValue} />
                              </div>
                            </>
                          )) || (
                            <div className="absolute inset-0 flex items-center justify-center">
                              <AddYoutube fieldName="imageUrl" edit={true} setField={setFieldValue} />
                            </div>
                          )}
                        </div>
                      </>
                    )}

                    <Field
                      className="border rounded p-2 w-full mt-4"
                      as="select"
                      name="card.nextBtnTypeId"
                      onChange={(e) => {
                        setFieldValue('card.nextBtnTypeId', parseInt(e.target.value));
                      }}
                    >
                      {imagePositions.map((imagePosition, index) => (
                        <option value={parseInt(imagePosition.id)} key={imagePosition.id}>
                          {t(imagePosition.name)}
                        </option>
                      ))}
                    </Field>
                    <div className="mt-4">
                      {values &&
                        values.pgSectionCardItemLngs &&
                        values?.pgSectionCardItemLngs.map((pgSectionCardItemLng, index) => (
                          <FieldArray
                            key={index}
                            name="pgSectionCardItemLngs"
                            render={(arrayHelpers) => (
                              <>
                                {languageId === pgSectionCardItemLng.languageId && (
                                  <>
                                    <label>
                                      <Field
                                        className="border rounded  w-full  p-2"
                                        type="text"
                                        placeholder={t('Add Title')}
                                        name={`pgSectionCardItemLngs[${index}].btnTitle`}
                                      />
                                    </label>
                                  </>
                                )}
                              </>
                            )}
                          />
                        ))}
                    </div>
                    <div className="mt-4 relative z-20">
                      <CardPagesList setFieldValue={setFieldValue} languageId={languageId} />
                    </div>
                  </div>
                  <div className="relative z-10">
                    {values &&
                      values.pgSectionCardItemLngs &&
                      values?.pgSectionCardItemLngs.map((pgSectionCardItemLng, index) => (
                        <FieldArray
                          key={index}
                          name="pgSectionCardItemLngs"
                          render={(arrayHelpers) => (
                            <>
                              {languageId === pgSectionCardItemLng.languageId && (
                                <>
                                  <label>
                                    <Field
                                      className="border rounded  w-full  p-2"
                                      type="text"
                                      placeholder={t('Add Title')}
                                      name={`pgSectionCardItemLngs[${index}].title`}
                                    />
                                  </label>
                                </>
                              )}
                            </>
                          )}
                        />
                      ))}
                    <label className={`my-4 block `}>
                      <Field className="w-full border p-2 rounded " type="date" name="date" />
                    </label>

                    {values &&
                      values.pgSectionCardItemLngs &&
                      values?.pgSectionCardItemLngs.map((pgSectionCardItemLng, index) => (
                        <FieldArray
                          key={index}
                          name="pgSectionCardItemLngs"
                          render={(arrayHelpers) => (
                            <>
                              {languageId === pgSectionCardItemLng.languageId && (
                                <>
                                  <label>
                                    <RichTextEditor
                                      fieldName={`pgSectionCardItemLngs[${index}].description`}
                                      fieldValue={values.pgSectionCardItemLngs[index].description}
                                      setFieldValue={setFieldValue}
                                    />
                                    {/* <Field
                                      className="border rounded  w-full  p-2"
                                      as="textarea"
                                      placeholder={t('Add Description')}
                                      name={`pgSectionCardItemLngs[${index}].description`}
                                      rows="3"
                                    /> */}
                                  </label>
                                </>
                              )}
                            </>
                          )}
                        />
                      ))}
                    <div
                      className={`grid grid-cols-2 space-x-2 mt-4 ${i18n.language === 'en' ? '' : 'space-x-reverse'} `}
                    >
                      <label
                        className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'} `}
                      >
                        <Field type="radio" name="isExternalLink" value="true" className="w-4 h-4" />
                        <span>{t('External Link')}</span>
                      </label>
                      <label
                        className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
                      >
                        <Field type="radio" name="isExternalLink" value="false" className="w-4 h-4" />
                        <span>{t('Pages')}</span>
                      </label>
                    </div>
                  </div>
                </div>
                <label className="mt-2 block">
                  <Field
                    type="number"
                    name="orderNum"
                    placeholder="Order Number"
                    className="border rounded  w-full  p-2"
                  />
                </label>
              </div>
              <div>
                <CustomFields setFieldValue={setFieldValue} customFields={values.customFields} />
              </div>
              <div className={`flex justify-end space-x-2 py-4 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
                <DuplicateCard pgSectionCardItemIndex={pgSectionCardItemIndex} values={values} />
                <button
                  type="button"
                  onClick={() => {
                    onSubmit(values);
                  }}
                  className="bg-lightgreen py-2 px-2 flex justify-center text-center h-full text-sm font-bold text-white items-center hover:bg-lightgreenhover text-centerhover shadow  rounded transition duration-300 ease-in-out"
                >
                  {i18n.language === 'en' ? (
                    <>
                      {t('Update Item')}
                      {editCardItemMutation.isLoading && <LoadingButton />}
                    </>
                  ) : (
                    <>
                      {editCardItemMutation.isLoading && <LoadingButton />}
                      {t('Update Item')}
                    </>
                  )}
                </button>
                <DeleteCardItem
                  id={values.id}
                  pgSectionCardItemsHelper={pgSectionCardItemsHelper}
                  pgSectionCardItemIndex={pgSectionCardItemIndex}
                />
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
}
