import axios from 'axios';

export const getUsersCountByRole = async (e) => {
  const query = await axios.get('/UserAccount/GetUsersCountByRole');
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};

export const getUsersCountByStatus = async (e) => {
  const query = await axios.get('/UserAccount/GetUsersCountByStatus');
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};
export const getPagesByCategory = async (e) => {
  const query = await axios.get('PgPage/PagesByCategory');
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};

export const getPagesByStatus = async (e) => {
  const query = await axios.get('PgPage/PagesByStatus');
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};
