/* eslint-disable */

import { ExclamationCircleIcon, FilterIcon, RefreshIcon } from '@heroicons/react/solid';
import React, { useEffect, useMemo, useState } from 'react';
import { useRowSelect, useTable, useFilters, useGlobalFilter } from 'react-table';
import OutsideClickHandler from '../../OutsideClickHandler';

export default function DefaultTable({ columns, data, setSelectedData }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    preGlobalFilteredRows,
    setGlobalFilter,
    setAllFilters,
    state: { selectedRowIds, filters },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useRowSelect
  );

  useEffect(() => {
    if (setSelectedData) {
      setSelectedData(selectedFlatRows);
    }
    // eslint-disable-next-line
  }, [selectedRowIds]);

  // Render the UI for your table
  return (
    <>
      {filters.length > 0 && (
        <div className="border p-4 mb-4">
          <div className="text-base flex justify-between  py-2 px-2 border-dashed border bg-gray-50 border-gray-300 block  font-bold text-gray-900">
            <span>مدخلات البحث</span>
            <button
              type="button"
              onClick={() => {
                setAllFilters([]);
              }}
            >
              <RefreshIcon className="h-4" />
            </button>
          </div>
          <div className="py-4">
            {filters.map((e) => {
              const filterName = headerGroups[0].headers.filter((x) => x.id === e.id)[0].Header;

              return (
                <div className="flex space-x-2 rtl:space-x-reverse ">
                  <b>{filterName}</b> : <span>{e.value}</span>
                </div>
              );
            })}
          </div>
          {/* <pre>
					<code>{JSON.stringify(filters, null, 3)}</code>
				</pre> */}
        </div>
      )}

      <div className="flex flex-col justify-between text-sm  bg-white border  p-2 rounded overflow-x-auto overflow-y-hidden">
        <table className="" {...getTableProps()}>
          <thead className="border-b">
            {headerGroups.map((headerGroup, index) => (
              <tr className=" text-right divide-x divide-x-reverse " key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    className="px-2 py-2 font-semibold whitespace-nowrap capitalize w-1 "
                    key={index}
                    {...column.getHeaderProps()}
                  >
                    <div className="flex justify-between items-center">
                      {column.render('Header')}
                      {/* Render the columns filter UI */}
                      <div>{column.canFilter ? column.render('Filter') : null}</div>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="divide-y" {...getTableBodyProps()}>
            {rows.length === 0 && (
              <>
                {headerGroups.map((headerGroup, index) => (
                  <tr className="mt-4">
                    <td colspan={headerGroup.headers.length} className="w-full ">
                      <div className="flex items-center flex-col border border-dashed mt-2 p-8">
                        <ExclamationCircleIcon className="h-16 h-10 text-green-500" />
                        <span className="">no data available</span>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            )}
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  className={`  divide-x  divide-x-reverse hover:bg-gray-100 text-sm ${
                    i % 2 === 0 ? 'bg-gray-50' : ''
                  }`}
                  key={i}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <td className="px-2 py-2 whitespace-nowrap" key={index} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

// Define a default UI for filtering
function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div>
      <span>
        Search:{' '}
        <input
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`${count} records...`}
          style={{
            fontSize: '1.1rem',
            border: '0',
          }}
        />
      </span>
    </div>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter, Header } }) {
  const count = preFilteredRows.length;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <button
        type="button"
        className={`${isOpen ? 'text-green-500' : 'text-gray-500'}`}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <FilterIcon className="h-4" />
      </button>
      <OutsideClickHandler setIsOpen={setIsOpen}>
        <div
          className={`fixed ${
            isOpen ? 'translate-y-0' : 'translate-y-full'
          }  transition-all shadow  transform duration-300 bottom-0 border-t border-black right-0 z-10 bg-white w-full p-4`}
        >
          <div className="border flex space-x-4 rtl:space-x-reverse items-center border-dashed p-4">
            <span> البحث في جدول {Header} : </span>
            <input
              className=" w-full  py-4 block outline-none "
              value={filterValue || ''}
              onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
              }}
              placeholder={`البحث في ${count} نتيجة...`}
            />
          </div>
        </div>
      </OutsideClickHandler>
    </>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}
