const inputs = {
  1: 'text',
  2: 'radio',
  3: 'checkbox',
  4: 'file',
  5: 'tel',
  6: 'url',
  7: 'number',
  8: 'select',
  9: 'textarea',
};

export default inputs;
