import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import Modal from '../../../SharedComponents/Modal';
import { getUserInfo } from './ApiFormAnswer';
import { DateTime } from 'luxon';

export default function FormUser(props) {
  const { userName, userId } = props;
  const [isOpen, setIsOpen] = useState(false);
  const userDataMutation = useMutation('userData', getUserInfo);
  return (
    <>
      <span
        onClick={(e) => {
          setIsOpen(!isOpen);
        }}
        className="cursor-pointer"
      >
        {userName}
      </span>
      <ModalContainer isOpen={isOpen} setIsOpen={setIsOpen} userDataMutation={userDataMutation} userId={userId} />
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, userDataMutation, userId } = props;
  const { t, i18n } = useTranslation();

  useEffect(() => {
    userDataMutation.mutate(userId);
  }, []);
  return (
    <>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} title={t('User Details')} hideSubmit>
        {userDataMutation.data && userDataMutation.data[0]?.fullName && (
          <div className="flex pb-3 mb-3 border-b border-[#e6e7e9] ">
            <h2 className={`${i18n.language === 'en' ? 'mr-1' : 'ml-1'} font-bold text-black uppercase text-sm`}>
              {t('Name')} :{' '}
            </h2>
            <h3 className={`${i18n.language === 'en' ? 'ml-4' : 'mr-4'} text-[#999999] text-sm font-semibold`}>
              {userDataMutation.data[0]?.fullName}
            </h3>
          </div>
        )}
        {userDataMutation.data && userDataMutation.data[0]?.email && (
          <div className="flex pb-3 mb-3 border-b border-[#e6e7e9] ">
            <h2 className={`${i18n.language === 'en' ? 'mr-1' : 'ml-1'} font-bold text-black uppercase text-sm`}>
              {t('Email')} :{' '}
            </h2>
            <h3 className={`${i18n.language === 'en' ? 'ml-4' : 'mr-4'} text-[#999999] text-sm font-semibold`}>
              {userDataMutation.data[0]?.email}
            </h3>
          </div>
        )}
        {userDataMutation.data && userDataMutation.data[0]?.phone && (
          <div className="flex pb-3 mb-3 border-b border-[#e6e7e9] ">
            <h2 className={`${i18n.language === 'en' ? 'mr-1' : 'ml-1'} font-bold text-black uppercase text-sm`}>
              {t('Phone')} :{' '}
            </h2>
            <h3 className={`${i18n.language === 'en' ? 'ml-4' : 'mr-4'} text-[#999999] text-sm font-semibold`}>
              0{userDataMutation.data[0]?.phone}
            </h3>
          </div>
        )}
        {userDataMutation.data && userDataMutation.data[0]?.address && (
          <div className="flex pb-3 mb-3 border-b border-[#e6e7e9] ">
            <h2 className={`${i18n.language === 'en' ? 'mr-1' : 'ml-1'} font-bold text-black uppercase text-sm`}>
              {t('Address')} :{' '}
            </h2>
            <h3 className={`${i18n.language === 'en' ? 'ml-4' : 'mr-4'} text-[#999999] text-sm font-semibold`}>
              {userDataMutation.data[0]?.address}
            </h3>
          </div>
        )}
        {userDataMutation.data && userDataMutation.data[0]?.job && (
          <div className="flex pb-3 mb-3 border-b border-[#e6e7e9] ">
            <h2 className={`${i18n.language === 'en' ? 'mr-1' : 'ml-1'} font-bold text-black uppercase text-sm`}>
              {t('Job')} :{' '}
            </h2>
            <h3 className={`${i18n.language === 'en' ? 'ml-4' : 'mr-4'} text-[#999999] text-sm font-semibold`}>
              {userDataMutation.data[0]?.job}
            </h3>
          </div>
        )}
        <div className="flex pb-3 mb-3  ">
          <h2 className={`${i18n.language === 'en' ? 'mr-1' : 'ml-1'} font-bold text-black uppercase text-sm`}>
            {t('Last Login')} :{' '}
          </h2>
          <h3 className={`${i18n.language === 'en' ? 'ml-4' : 'mr-4'} text-[#999999] text-sm font-semibold`}>
            {userDataMutation.data && DateTime.fromISO(userDataMutation.data[0]?.lastLoginDate).toISODate()}
          </h3>
        </div>
      </Modal>
    </>
  );
};
