import { Field } from 'formik';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getPages } from '../ApiSections';
import OutsideClickHandler from '../../../../SharedComponents/OutsideClickHandler';

export default function CollapsePagesList(props) {
  const { languageId, setActivePage, setFieldValue, isExternalLink } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');

  // Query Data To Fetch Languages
  const { t } = useTranslation();
  const { data, isLoading, isError } = useQuery('pages', getPages, {
    staleTime: 360000000,
  });
  if (isLoading) return '';
  if (isError) return 'error';

  const onClick = (data) => {
    data.pgPageLngs.map((e, index) => setFieldValue(`pgSectionCollapseItemsLngs[${index}].buttonTitle`, e.title));
    setFieldValue('toLinkUrl', data.uniqueUrl);
    setFieldValue('pageId', data.id);
    setIsOpen(false);
    // const newData = {
    //   uniqueUrl: data.uniqueUrl,
    //   pgPageLngs: data.pgPageLngs,
    // };
    // setActivePage(newData);
  };

  const searchFilter = () => {
    const dataArray = data?.data
      ?.map((item) => {
        for (let index = 0; index < item.pgPageLngs.length; index++) {
          const element = item.pgPageLngs[index];
          if (
            element.languageId === languageId &&
            element.title.toLocaleLowerCase().includes(search.toLocaleLowerCase())
          ) {
            return item;
          } else {
            continue;
          }
        }
      })
      .filter((e) => !!e);
    return dataArray;
  };

  return (
    <>
      <label>
        <Field
          className="border rounded  w-full  p-2"
          type="text"
          placeholder={t('Link Url')}
          name="toLinkUrl"
          onFocus={() => {
            setIsOpen(true);
            setSearch('');
          }}
          // onBlur={() => {
          //   setIsOpen(false);
          // }}
        />
      </label>
      <OutsideClickHandler setIsOpen={setIsOpen}>
        {(isExternalLink === 'false' || isExternalLink === false) && isOpen && (
          <div className="absolute bottom-0 bg-white w-full transform translate-x-full pl-0 block right-0 -mr-6">
            {t('Pages')}
            <ul className="border rounded divide-y overflow-y-scroll max-h-40">
              <input
                type="text"
                className="w-full p-2"
                onChange={(e) => setSearch(e.target.value)}
                placeholder={t('Search')}
              />
              {searchFilter().map((e) => {
                return (
                  <Fragment key={e.id}>
                    <button
                      onClick={(x) => {
                        onClick(e);
                      }}
                      type="button"
                      className=" w-full text-left block px-2 py-1 hover:bg-darkbluehover hover:text-white"
                    >
                      {e.pgPageLngs.map((x) => {
                        return <>{languageId === x.languageId && x.title}</>;
                      })}
                    </button>
                  </Fragment>
                );
              })}
            </ul>
          </div>
        )}{' '}
      </OutsideClickHandler>
    </>
  );
}
