import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import { Field, FieldArray, Form, Formik } from 'formik';
import { Fragment, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import LanguageList from '../../SharedComponents/LanguageList';
import Notification from '../../SharedComponents/Notification';
import AddThumbnail from '../Page/Pages/AddThumbnail';
import GalleryAlbumsList from '../Page/Sections/GallaryAlbumSection/GalleryAlbumsList';
import SelectImage from './components/SelectImage';

export default function AddConsultingSpecialist(props) {
  const { languages, values } = props;
  const [languageId, setLanguageId] = useState(parseInt(window.env.REACT_APP_DEFAULT_LANGUAGE));
  const consultingSpecialistWithLangLngs = languages?.map((language) => {
    return {
      languageId: language.id,
      name: '',
      experience: '',
      qualifications: '',
      languages: '',
      cvs: '',
    };
  });

  let [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const queryClient = useQueryClient();
  const addChairPersonMutation = useMutation(mutationFunction, {
    onSuccess: () => {
      toast(<Notification message={t('Added, Successfully')} />);
      queryClient.invalidateQueries('ConsultingSpecialist');
      closeModal();
    },
    onError: () => {
      toast(<Notification message={t('Network Error')} />);
    },
  });

  const { data, isLoading, isError } = useQuery(['ConsultingTypes'], queryFunction);
  if (isLoading) return '';
  if (isError) return '';

  const initValues = {
    userName: '',
    fullName: '',
    password: '',
    phone: '',
    email: '',
    isActive: true,
    roleId: 1038,
    statusId: 0,
    // "isMale": true,
    // "age": 38,
    consultingTypeId: data.data[0].id,
    consultingSpecialistWithLangLngs,
  };

  const onSubmit = (e) => {
    addChairPersonMutation.mutate({ consultingTypeId: data.data[0].id, ...e });
  };

  return (
    <>
      <div className="flex items-center justify-end">
        <button
          type="button"
          onClick={openModal}
          className="px-4 py-2 mb-4 text-sm font-medium text-white bg-green-500 rounded  hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        >
          {'Add ConsultingSpecialist'}
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={closeModal}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-80 " />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-4xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="flex justify-between border-b">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    {t('Add ConsultingTypes')}
                  </Dialog.Title>
                  <LanguageList languageId={languageId} setLanguageId={setLanguageId} languages={languages} />
                </div>
                <div className="mt-2">
                  <Formik onSubmit={onSubmit} enableReinitialize initialValues={initValues}>
                    {({ values, setFieldValue, setValues, errors }) => {
                      return (
                        <Form>
                          <Field className={`border rounded hidden w-full p-1 `} name={`imageId`} type="number" />
                          <>
                            <div className="h-64 focus:outline-none group rounded border-dashed border-2 mb-4 relative p-2">
                              {(values.imageUrl && (
                                <>
                                  <img
                                    className="object-contain h-full w-full"
                                    alt="img"
                                    src={`${window.env.REACT_APP_IMAGE_PATH}${values.imageUrl}`}
                                  />
                                  <div className="absolute group-hover:opacity-100 opacity-0 inset-0 flex items-center justify-center">
                                    <SelectImage
                                      setFieldValue={setFieldValue}
                                      imageIdValue={values?.imageId}
                                      imageUrlValue={values?.imageUrl}
                                      imageId={`imageId`}
                                      imageUrl={`imageUrl`}
                                    />
                                  </div>
                                </>
                              )) || (
                                <div className="absolute inset-0 flex items-center justify-center">
                                  <SelectImage
                                    setFieldValue={setFieldValue}
                                    imageIdValue={values?.imageId}
                                    imageUrlValue={values?.imageUrl}
                                    imageId={`imageId`}
                                    imageUrl={`imageUrl`}
                                  />
                                </div>
                              )}
                            </div>
                            {values.imageId && (
                              <button
                                className="block mb-4 bg-red-700 text-white w-full rounded p-1"
                                type="button"
                                onClick={() => {
                                  setFieldValue('imageId', null);
                                }}
                              >
                                {t('Remove Image')}
                              </button>
                            )}
                          </>
                          <label>
                            <Field
                              className="border rounded  w-full  p-2 mt-2 "
                              type="text"
                              placeholder={t('userName')}
                              name={`userName`}
                            />
                          </label>
                          <label>
                            <Field
                              className="border rounded  w-full  p-2 mt-2 "
                              type="text"
                              placeholder={t('fullName')}
                              name={`fullName`}
                            />
                          </label>
                          <label>
                            <Field
                              className="border rounded  w-full  p-2 mt-2 "
                              type="text"
                              placeholder={t('password')}
                              name={`password`}
                            />
                          </label>
                          <label>
                            <Field
                              className="border rounded  w-full  p-2 mt-2 "
                              type="text"
                              placeholder={t('phone')}
                              name={`phone`}
                            />
                          </label>
                          <label>
                            <Field
                              className="border rounded  w-full  p-2 mt-2 "
                              type="text"
                              placeholder={t('email')}
                              name={`email`}
                            />
                          </label>
                          <Field
                            style={{ textIndent: '10px' }}
                            className="border rounded  mt-2 w-full mb-4 p-1 "
                            name="consultingTypeId"
                            as="select"
                          >
                            {data &&
                              data.data &&
                              data.data.map((type) => {
                                const valueWithLanguage = type?.consultingTypeLngs?.filter(
                                  (e) => parseInt(e.languageId) === parseInt(languageId)
                                );

                                return (
                                  <option key={type.id} value={`${type.id}`}>
                                    {valueWithLanguage[0]?.name}
                                  </option>
                                );
                              })}
                          </Field>
                          <div>
                            <FieldArray
                              name="consultingSpecialistWithLangLngs"
                              render={(arrayHelpers) => (
                                <>
                                  {values.consultingSpecialistWithLangLngs.map((e, index) => {
                                    return (
                                      <Fragment key={index + 100}>
                                        {languageId === e.languageId && (
                                          <>
                                            <label>
                                              <Field
                                                className="border rounded  w-full  p-2 mt-2 "
                                                type="text"
                                                placeholder={t('Add Name')}
                                                name={`consultingSpecialistWithLangLngs[${index}].name`}
                                              />
                                            </label>
                                            <label>
                                              <Field
                                                className="border rounded  w-full  p-2 mt-2 "
                                                type="text"
                                                placeholder={t('Add Experience')}
                                                name={`consultingSpecialistWithLangLngs[${index}].experience`}
                                              />
                                            </label>
                                            <label>
                                              <Field
                                                className="border rounded  w-full  p-2 mt-2 "
                                                type="text"
                                                placeholder={t('Add Qualifications')}
                                                name={`consultingSpecialistWithLangLngs[${index}].qualifications`}
                                              />
                                            </label>
                                            <label>
                                              <Field
                                                className="border rounded  w-full  p-2 mt-2 "
                                                type="text"
                                                placeholder={t('Add Languages')}
                                                name={`consultingSpecialistWithLangLngs[${index}].languages`}
                                              />
                                            </label>
                                            <label>
                                              <Field
                                                className="border rounded  w-full  p-2 mt-2 "
                                                type="text"
                                                placeholder={t('Add Cvs')}
                                                name={`consultingSpecialistWithLangLngs[${index}].cvs`}
                                              />
                                            </label>
                                            {/* "name": "string", "experience": "string", "qualifications": "string",
                                            "languages": "string", "cvs": "string", */}
                                          </>
                                        )}
                                      </Fragment>
                                    );
                                  })}
                                </>
                              )}
                            />
                          </div>
                          <div
                            className="mt-4
                           flex justify-end"
                          >
                            <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded">
                              {t('Save')}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export const mutationFunction = async (e) => {
  const data = await axios.post('AccountMangment/RegisterSpecialist', e);

  return data;
};

export const queryFunction = async (e) => {
  const query = await axios.post('ConsultingTypeWithLang/GetAll', {});
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};
