// eslint-disable-next-line import/no-anonymous-default-export
export default {
  FAQ: 'الأسئلة الشائعة',
  'FAQ LIST': 'قائمة الأسئلة الشائعة',
  'Create Faq': 'إنشاء أسئلة وأجوبة',
  'Order Number is Duplicated': 'رقم الطلب مكرر',
  'Add Faq': 'أضف أسئلة وأجوبة',
  'Sorry, This Form Question Option Types have Data Inside, Please Delete and Retry':
    'عذرا، هذه خيارات الأسئلة تحتوي على بيانات بداخلها يرجى حذف وإعادة المحاولة',
  'The Faq has been deleted successfully': 'تم حذف السؤال بنجاح',
};
