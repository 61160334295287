import { useQuery } from 'react-query';
import { getLanguages, getPages } from './ApiPage';
import { useEffect, useState } from 'react';
import PlusCircle from '../../../SharedComponents/Svg/PlusCircle';
import DeletePage from './DeletePage';
import PecnilAlt from '../../../SharedComponents/Svg/PencilAlt';
import Loading from '../../../SharedComponents/Loading';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../../../SharedComponents/BreadCrumbs';
import TablePagination from '../../../SharedComponents/TablePagination';
import TableSearch from '../../../SharedComponents/TableSearch';
import LanguageList from '../../../SharedComponents/LanguageList';
import TableFilter from '../../../SharedComponents/TableFilter';
import ChangePageStatus from '../components/instructors/ChangePageStatus';

export default function Pages() {
  const [entries, setEntries] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [languageId, setLanguageId] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchLanguage, setSearchlanguage] = useState(0);
  const [filterSearch, setFilterSearch] = useState('');
  const { t, i18n } = useTranslation();
  const onSearch = (e) => {
    setFilterSearch('');
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    setSearchlanguage(languageId);
  };
  // Query Data To Fetch Languages
  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  useEffect(() => {
    setLanguageId(languages.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [languages.data]);

  let initialState = {
    pageNumber: pageNumber,
    pageSize: entries,
    isPaging: true,
    isFilter: filterSearch.length !== 0,
    filter: [
      {
        filterBy: 'categoryname',
        filterType: 'string',
        searchTerm: [
          {
            langId: languageId,
            term: filterSearch,
          },
        ],
      },
    ],
    langId: 0,
    isSearch: searchTerm ? true : false,
    searchFilter: {
      langId: searchLanguage,
      term: searchTerm,
    },
  };
  const { data, isLoading, isError } = useQuery(['pages', initialState], getPages, {
    staleTime: 99900,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {
      pagination(data);
    },
  });
  useEffect(() => {
    data && pagination(data);
  }, [data]);
  const [paginationCount, setPaginationCount] = useState([]);

  function pagination(data) {
    let paginationCount = [];
    for (var i = 1; i <= data.paginationResult.numberOfPages; i++) {
      paginationCount.push(i);
    }
    setPaginationCount(paginationCount);
  }

  // to control how many rows to show in table
  const onChangeEntries = (e) => {
    setFilterSearch('');
    setEntries(e.target.value);
  };

  const tabltHeader = [
    { title: 'Title' },
    { title: 'Category', filter: true, className: 'flex items-center' },
    { title: 'Options', className: 'w-64' },
  ];
  return (
    <>
      <header className="flex justify-between items-center">
        <BreadCrumbs to="/pages" name={t('Pages')} />
        <div>
          {/* <AddUser /> */}
          <a
            href={`./pages/create`}
            className={`bg-lightgreen ${
              i18n.language === 'en' ? '' : 'space-x-reverse'
            } space-x-1 text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
          >
            <PlusCircle className="w-5 h-5" /> <span>{t('Create Page')}</span>
          </a>
        </div>
      </header>
      <div className="my-6">
        <h2 className="text-2xl font-bold">{t('Pages')}</h2>
      </div>
      {/* Table */}
      <div className="bg-white rounded border shadow-md">
        <div className="flex   relative items-center px-6 py-6  ">
          <TableSearch onChangeEntries={onChangeEntries} entries={entries} onSearch={onSearch} />
          {/*          
                  <div className="flex items-center space-x-8 transform -translate-y-3">
            <div
              className={`flex items-center space-x-2 text-gray-600 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
            >
              <span>{t('Show')} </span>
              <select className="border rounded w-20 py-2 px-1" onChange={onChangeEntries} defaultValue={entries}>
                <option value="10">10 </option>
                <option value="20">20 </option>
                <option value="30">30 </option>
              </select>
              <span>{t('entries')}</span>
            </div>

            <div
              className={`flex items-center space-x-4 text-gray-600 ${
                i18n.language === 'en' ? '' : 'space-x-reverse '
              }`}
            >
              <span className={i18n.language === 'en' ? '' : 'mr-2'}>{t('Search')} </span>
              <input onBlur={onSearch} className="border rounded w-64 py-2 px-1" />
            </div>
          </div>
          */}
          <div className="flex transform  relative z-10 ml-auto ">
            <LanguageList languages={languages.data?.data} languageId={languageId} setLanguageId={setLanguageId} />
          </div>
          <hr className="border w-full absolute top-0 left-0 z-0 mt-16" />
        </div>
        {isLoading && (
          <div className="relative bg-white h-72">
            <Loading />
          </div>
        )}
        {isError && 'loading'}
        {data && (
          <div className="flex overflow-hidden overflow-x-auto flex-col">
            <div className="-my-2  sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className=" overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y-2 divide-gray-200">
                    <thead className=" text-sm font-bold text-black">
                      <tr>
                        {tabltHeader.map((e) => {
                          return (
                            <th
                              key={e.title}
                              scope="col"
                              className={`px-6 py-3 ${
                                i18n.language === 'en' ? 'text-left' : 'text-right'
                              }   uppercase  ${e.className} `}
                            >
                              {t(e.title)}
                              {e?.filter && (
                                <TableFilter
                                  onFilter={(text) => {
                                    setFilterSearch(text);
                                  }}
                                  reset={onSearch}
                                  languageId={languageId}
                                />
                              )}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal">
                      {data &&
                        data.data &&
                        data?.data?.map((page) => {
                          return (
                            <tr key={page.id} className="">
                              <td className="px-6 py-4 whitespace-nowrap">
                                {page.pgPageLngs.map((x) => {
                                  return <span key={x.id}>{languageId === x.languageId && <> {x.title} </>}</span>;
                                })}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {page?.pgPageCategory?.pgPageCategoryLngs?.map((x) => {
                                  return <span key={x.id}>{languageId === x.languageId && <> {x.name} </>}</span>;
                                })}
                              </td>

                              <td
                                className={`px-6 py-4 whitespace-nowrap flex items-center space-x-2 ${
                                  i18n.language === 'en' ? '' : 'space-x-reverse'
                                }`}
                              >
                                {/* <EditRole id={user.id} name={user.name} /> */}
                                {/* <DeleteRole id={user.id} /> */}

                                <a
                                  href={`./pages/${page.id}`}
                                  className="bg-green-100 text-green-800 hover:bg-green-200  p-1 rounded transition duration-300 ease-in-out"
                                >
                                  <PecnilAlt className="w-5 h-5" />
                                </a>
                                <DeletePage id={page.id} />
                                {/* <ChangePageStatus id={page.id} statusId={page.statusId}  categoryId/> */}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <TablePagination
                    data={data}
                    paginationCount={paginationCount}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
