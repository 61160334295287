import PecnilAlt from '../../../SharedComponents/Svg/PencilAlt';

import { Field, FieldArray, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import Modal from '../../../SharedComponents/Modal';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Loading from '../../../SharedComponents/Loading';
import { editMenuItem, getLanguages } from './ApiMenu';
import PagesList from './PagesList';
import Notification from '../../../SharedComponents/Notification';
import { useTranslation } from 'react-i18next';
import LanguageList from '../../../SharedComponents/LanguageList';

export default function EditMenuItem(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { id } = props.id ? props : 1;
  const { typeId, activeMenu, menuItem } = props;
  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-green-100 text-green-800 hover:bg-green-200  p-1 rounded transition duration-300 ease-in-out"
      >
        <PecnilAlt className="w-5 h-5" />
      </button>

      {isOpen && (
        <ModalContainer
          id={id}
          typeId={typeId}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          activeMenu={activeMenu}
          menuItem={menuItem}
        />
      )}
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, menuItem } = props;
  const [languageId, setLanguageId] = useState(1);
  const { t, i18n } = useTranslation();

  // Query Data To Fetch Languages
  const { data, isLoading, isError } = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  useEffect(() => {
    setLanguageId(data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [data]);

  const [activePage, setActivePage] = useState();
  // create a loop for localisation
  const muMenuItemLngs = data?.data?.map((language) => ({
    languageId: language.id,
    title: '',
    description: '',
  }));
  const initialValues = menuItem;
  console.log('menuItem', menuItem);

  const queryClient = useQueryClient();
  // Mutate Data to Create New Page
  const mutation = useMutation(editMenuItem, {
    onSuccess: async (data) => {
      if (data.isDone) {
        // queryClient.invalidateQueries(['Menus']);
        queryClient.invalidateQueries(['Menu']);
        queryClient.invalidateQueries(['menuByParent']);

        setIsOpen(false);
      }
    },
  });

  const onSubmit = async (e) => {
    if (e.parentId === 0) {
      delete e.parentId;
    }

    // const newData = {
    //   parentId: e.parentId === 0 ? null : e.parentId,
    //   ...e,
    // };
    try {
      await mutation.mutate(e);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {}, [activePage]);
  return (
    <>
      {mutation && mutation.data && mutation.data.data && mutation.data.data.isDone === true && (
        <Notification message={t('Menu Have Been Moved Successfully')} />
      )}
      {mutation && mutation.data && mutation.data.data && mutation.data.data.isDone === false && (
        <Notification error={true} message={t('Please Select Menu To Move')} />
      )}
      {data && data.data && (
        <Formik onSubmit={onSubmit} validateOnBlur={false} validateOnChange={false} initialValues={initialValues}>
          {({ values, setFieldValue, errors }) => {
            return (
              <Form>
                <Modal
                  className="sm:max-w-3xl"
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  onSubmit={onSubmit}
                  title={t('Add Item')}
                  isLoading={mutation.isLoading}
                  add={true}
                >
                  {isLoading && <Loading />}
                  {isError && 'error'}
                  {data && (
                    <LanguageList
                      className="flex transform translate-y-1 relative z-10 mb-4"
                      languages={data?.data}
                      languageId={languageId}
                      setLanguageId={setLanguageId}
                    >
                      <hr className="border w-full absolute bottom-0 left-0 z-0" />
                    </LanguageList>
                  )}

                  {/* Submit Form */}
                  <div className="grid grid-cols-3 mb-2">
                    <label className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
                      <Field type="radio" name="isMegaMenu" value="false" className="w-4 h-4" />
                      <span>{t('Normal')}</span>
                    </label>
                    <label className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
                      <Field type="radio" name="isMegaMenu" value="true" className="w-4 h-4" />
                      <span>{t('Mega')}</span>
                    </label>
                  </div>
                  <div className="grid grid-cols-2 gap-6">
                    <div>
                      <FieldArray
                        name="muMenuItemLngs"
                        render={(arrayHelpers) => (
                          <>
                            {values.muMenuItemLngs.map((asset, index) => {
                              return (
                                <>
                                  {languageId === asset.languageId && (
                                    <>
                                      <label>
                                        <b
                                          className={`font-semibold block text-gray-700 ${
                                            i18n.language === 'en' ? '' : 'text-right'
                                          }`}
                                        >
                                          {t('Title')}
                                        </b>
                                        <Field
                                          className="border rounded  w-full mb-4 p-1"
                                          type="text"
                                          name={`muMenuItemLngs[${index}].title`}
                                        />
                                      </label>
                                    </>
                                  )}
                                </>
                              );
                            })}
                          </>
                        )}
                      />

                      {/* <SelectMenuParent
                        languageId={languageId}
                        activeMenu={activeMenu}
                        FieldName='parentId'
                      /> */}
                      <label>
                        <b
                          className={`font-semibold block text-gray-700 ${i18n.language === 'en' ? '' : 'text-right'}`}
                        >
                          {t('Url')}
                        </b>
                        <Field className="border rounded  w-full mb-4 p-1" type="text" name="title" name={`url`} />
                      </label>
                      <label>
                        <b
                          className={`font-semibold block text-gray-700 ${i18n.language === 'en' ? '' : 'text-right'}`}
                        >
                          {t('Order Number')}
                        </b>
                        <Field className="border rounded  w-full mb-4 p-1" type="number" name="orderNum" />
                      </label>
                    </div>
                    <div>
                      <PagesList
                        setFieldValue={setFieldValue}
                        activePage={activePage}
                        setActivePage={setActivePage}
                        languageId={languageId}
                      />
                    </div>
                  </div>
                </Modal>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};
