import React, { useState, useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';

const RichTextEditor = (props) => {
  const { fieldName, fieldValue, setFieldValue } = props;
  const editor = useRef(null);
  const [content, setContent] = useState('');

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    uploader: {
      insertImageAsBase64URI: true,
    },
    defaultMode: '1',
    autofocus: false,
  };
  // this is a workarround to solve value not string
  const onBlur = (event) => {
    const editorValue = event;
    // setContent(editorValue);
    setFieldValue(fieldName, editor.current.value);
  };
  return useMemo(() => {
    return (
      <>
        <span className="text-white break-words bg-red-500 rounded"></span>
        <JoditEditor
          ref={editor}
          value={fieldValue}
          config={config}
          tabIndex={1} // tabIndex of textarea
          onBlur={onBlur} // preferred to use only this option to update the content for performance reasons
          //   onChange={(newContent) => {}}
        />
      </>
    );
  }, []);
};

export default RichTextEditor;
