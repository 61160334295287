import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import Loading from '../../../SharedComponents/Loading';
import Users from '../../../SharedComponents/Svg/Users';
import { getUsersCountByRole } from '../ApiDashboard';

export default function UserCountByRoleCount(props) {
  const { data, isLoading, isError, isSuccess } = useQuery(['UserCountByRole'], getUsersCountByRole, {
    staleTime: 90000,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });
  const { t } = useTranslation();

  if (isLoading)
    return (
      <div className="h-64 relative rounded shadow border bg-white">
        <Loading />
      </div>
    );
  if (isError) return 'error';
  const totalCount = data.data.reduce(function (previous, current) {
    return previous + current.usersCount;
  }, 0);
  return (
    <>
      <div className="bg-orange text-white grid grid-cols-3 gap-6 flex items-center p-4 rounded  shadow mb-4  justify-between">
        <div className="col-span-2">
          <span className="font-bold"> {totalCount}</span>
          <h3 className=" text-white">{t('Users Count By Role')}</h3>
        </div>
        <div className="flex justify-center">
          <Users className="h-24" />
        </div>
      </div>
    </>
  );
}
