import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../SharedComponents/Modal';
import Eye from '../../../SharedComponents/Svg/Eye';

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, image } = props;
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} title={t('View Asset')} add={true} hideSubmit={true}>
      <div className="text-center">
        <img className="inline-block" src={image} alt="imagex" />
      </div>
    </Modal>
  );
};

export default function ViewAsset(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const { image, withText } = props;
  return (
    <>
      {withText ? (
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={`bg-orange hover:bg-orangehover flex items-center ${
            i18n.language === 'en' ? '' : 'space-x-reverse'
          } space-x-2 text-xs transition duration-500 text-white rounded p-1`}
        >
          <Eye className="w-4 h-4" /> <span>{t('Preview')}</span>
        </button>
      ) : (
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="bg-orange hover:bg-orangehover transition duration-500 text-white rounded p-1"
        >
          <Eye className="w-5 h-5" />
        </button>
      )}

      {isOpen && <ModalContainer image={image} isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
}
