import { Field, FieldArray, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import Modal from '../../../../SharedComponents/Modal';
import PlusCircle from '../../../../SharedComponents/Svg/PlusCircle';
import React from 'react';
import { useQuery } from 'react-query';
import Loading from '../../../../SharedComponents/Loading';
import { getLanguages } from '../ApiSections';
import ListPagesList from './ListPagesList';
import RichTextEditor from '../../Pages/RichTextEditor';
import { useTranslation } from 'react-i18next';
import LanguageList from '../../../../SharedComponents/LanguageList';

export default function AddListParent(props) {
  const { id } = props.id ? props : 1;
  const { typeId, activeMenu, orderNum, addListMutation, pgSectionListId } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="flex justify-end">
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className={`bg-lightgreen mt-4 space-x-1 ${
            i18n.language === 'en' ? '' : 'space-x-reverse'
          } text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
        >
          <PlusCircle className="w-5 h-5" /> <span>{t('Add New List')}</span>
        </button>
      </div>

      {isOpen && (
        <ModalContainer
          id={id}
          typeId={typeId}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          activeMenu={activeMenu}
          orderNum={orderNum}
          addListMutation={addListMutation}
          pgSectionListId={pgSectionListId}
        />
      )}
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, addListMutation, pgSectionListId } = props;
  const [languageId, setLanguageId] = useState(1);
  const { t, i18n } = useTranslation();
  // Query Data To Fetch Languages
  const { data, isLoading, isError } = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  useEffect(() => {
    setLanguageId(data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [data]);

  const [activePage, setActivePage] = useState();
  // create a loop for localisation
  const pgSectionListParentLngs = data?.data?.map((language) => ({
    languageId: language.id,
    title: '',
    description: '',
    buttonTitle: '',
  }));
  const initialValues = {
    isExternalLink: 'true',
    toLinkUrl: '',
    imageUrl: '',
    pgSectionListId: pgSectionListId,
    pgSectionListItems: [],
    pgSectionListParentLngs: pgSectionListParentLngs,
  };

  const onSubmit = async (e) => {
    const newData = {
      ...e,
      isExternalLink: e.isExternalLink === 'true' || e.isExternalLink === true ? true : false,
    };
    try {
      await addListMutation.mutate(newData);
      setIsOpen(false);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {}, [activePage]);
  return (
    <>
      {data && data.data && (
        <Formik validateOnBlur={false} validateOnChange={false} initialValues={initialValues}>
          {({ values, setFieldValue, errors }) => {
            return (
              <Form>
                <Modal
                  className="sm:max-w-3xl"
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  onSubmit={() => onSubmit(values)}
                  title={t('Add Item')}
                  isLoading={addListMutation.isLoading}
                  add={true}
                  submitType="button"
                >
                  {isLoading && <Loading />}
                  {isError && 'error'}
                  {data && (
                    <LanguageList
                      className="flex transform translate-y-1 relative z-10 mb-4"
                      languages={data?.data}
                      languageId={languageId}
                      setLanguageId={setLanguageId}
                    >
                      <hr className="border w-full absolute bottom-0 left-0 z-0" />
                    </LanguageList>
                  )}

                  {/* Submit Form */}
                  <div className="grid grid-cols-2 gap-6">
                    <div className="col-span-2">
                      <div className="mb-2">
                        {values &&
                          values.pgSectionListParentLngs &&
                          values?.pgSectionListParentLngs.map((pgSectionCollapseItemsLng, index) => (
                            <FieldArray
                              key={index}
                              name="pgSectionListParentLngs"
                              render={(arrayHelpers) => (
                                <>
                                  {languageId === pgSectionCollapseItemsLng.languageId && (
                                    <>
                                      <label>
                                        <Field
                                          className="border rounded  w-full  p-2"
                                          type="text"
                                          placeholder={t('Add Title')}
                                          name={`pgSectionListParentLngs[${index}].title`}
                                        />
                                      </label>
                                    </>
                                  )}
                                </>
                              )}
                            />
                          ))}
                      </div>
                      <div className="mb-2">
                        {values &&
                          values.pgSectionListParentLngs &&
                          values?.pgSectionListParentLngs.map((pgSectionCollapseItemsLng, index) => (
                            <FieldArray
                              key={index}
                              name="pgSectionListParentLngs"
                              render={(arrayHelpers) => (
                                <>
                                  {languageId === pgSectionCollapseItemsLng.languageId && (
                                    <>
                                      <label>
                                        <RichTextEditor
                                          fieldName={`pgSectionListParentLngs[${index}].description`}
                                          fieldValue={values.pgSectionListParentLngs[index].description}
                                          setFieldValue={setFieldValue}
                                        />
                                      </label>
                                    </>
                                  )}
                                </>
                              )}
                            />
                          ))}
                      </div>

                      {/* waiting to check if we will add image  */}
                      {/* <div className="h-52 focus:outline-none group rounded border-dashed border-2 mb-2 relative">
                        {(values.imageUrl && (
                          <>
                            <div className="flex items-center justify-center h-full">
                              <img
                                className=""
                                style={{
                                  maxWidth: 32,
                                  maxHeight: 32,
                                }}
                                alt="img"
                                src={`${window.env.REACT_APP_IMAGE_PATH}${values.imageUrl}`}
                              />{' '}
                            </div>
                            <div className="absolute group-hover:opacity-100 opacity-0 inset-0 flex items-center justify-center">
                              <AddCollapseImage edit={true} setField={setFieldValue} fieldName="imageUrl" />
                            </div>
                          </>
                        )) || (
                          <div className="absolute inset-0 flex items-center justify-center">
                            <AddCollapseImage fieldName="imageUrl" setField={setFieldValue} />
                          </div>
                        )}
                      </div> */}

                      <div className="grid grid-cols-2 gap-6 flex items-center">
                        <div>
                          {values &&
                            values.pgSectionListParentLngs &&
                            values?.pgSectionListParentLngs.map((pgSectionCollapseItemsLng, index) => (
                              <FieldArray
                                key={index}
                                name="pgSectionListParentLngs"
                                render={(arrayHelpers) => (
                                  <>
                                    {languageId === pgSectionCollapseItemsLng.languageId && (
                                      <>
                                        <label>
                                          <Field
                                            className="border rounded  w-full  p-2"
                                            type="text"
                                            placeholder={t('Button Title')}
                                            name={`pgSectionListParentLngs[${index}].buttonTitle`}
                                          />
                                        </label>
                                      </>
                                    )}
                                  </>
                                )}
                              />
                            ))}
                        </div>
                        <div>
                          <div className="grid grid-cols-2 space-x-2  ">
                            <label
                              className={`flex items-center space-x-2 ${
                                i18n.language === 'en' ? '' : 'space-x-reverse'
                              }`}
                            >
                              <Field type="radio" name="isExternalLink" value="true" className="w-4 h-4" />
                              <span>{t('External Link')}</span>
                            </label>
                            <label
                              className={`flex items-center space-x-2 ${
                                i18n.language === 'en' ? '' : 'space-x-reverse'
                              }`}
                            >
                              <Field type="radio" name="isExternalLink" value="false" className="w-4 h-4" />
                              <span>{t('Pages')}</span>
                            </label>
                          </div>
                        </div>

                        <div className="relative">
                          <ListPagesList
                            isExternalLink={values.isExternalLink}
                            setFieldValue={setFieldValue}
                            languageId={languageId}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};
