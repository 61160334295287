import { FastField, Field } from 'formik';
import { useState } from 'react';

export default function CustomFormField(props) {
  const { name, setFieldValue, label, as, fieldValue, className, values, changeSlug } = props;
  const [value, setValue] = useState(fieldValue);
  return (
    <label>
      {label}
      {as ? (
        <Field
          style={{ textIndent: '10px' }}
          className={className ? className : 'border rounded  w-full mb-8 p-1'}
          name={name}
          value={value}
          as={as}
          type="text"
          onChange={(e) => {
            setValue(e.target.value);
          }}
          onBlur={(e) => {
            setFieldValue(name, value);
          }}
        />
      ) : (
        <Field
          style={{ textIndent: '10px' }}
          className={className ? className : 'border rounded  w-full mb-8 p-1'}
          name={name}
          value={value}
          type="text"
          onChange={(e) => {
            setValue(e.target.value);
          }}
          onBlur={(e) => {
            setFieldValue(name, value);
          }}
        />
      )}
    </label>
  );
}
