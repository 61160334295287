import { useTranslation } from 'react-i18next';
import LoadingCircle from '../../SharedComponents/Svg/LoadingCircle';

export default function DefaultLanguage(props) {
  const { language, editLangMutation } = props;
  const { t, i18n } = useTranslation();
  return (
    <>
      {language.defaultLang && (
        <button
          type="button"
          className={`text-sm border rounded px-4 space-x-2 ${
            i18n.language === 'en' ? '' : 'space-x-reverse'
          } py-1 bg-green-500 hover:bg-green-600 text-white`}
          onClick={() => {
            editLangMutation.mutate({ ...language, defaultLang: false });
          }}
        >
          <span>{t('Remove Default Language')}</span> {editLangMutation.isLoading && <LoadingCircle />}
        </button>
      )}
      {(language.defaultLang === false || language.defaultLang === null) && (
        <button
          className={`text-sm border rounded px-4 space-x-2 ${
            i18n.language === 'en' ? '' : 'space-x-reverse'
          } py-1 bg-green-500 hover:bg-green-600 text-white`}
          type="button"
          onClick={() => {
            editLangMutation.mutate({ ...language, defaultLang: true });
          }}
        >
          <span> {t('set Default Language')} </span> {editLangMutation.isLoading && <LoadingCircle />}
        </button>
      )}
    </>
  );
}
