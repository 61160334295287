// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Requests: 'طلبات',
  'Create New Request': 'إنشاء طلب جديد',
  'Request Created Successfully': 'تم إضافة طلب بنجاح',
  'Is Request?': 'خاص بالطلبات',
  'Thank You Request Is Completed': 'شكرا لك اكتمال طلب',
  'Next Step': 'الخطوة التالية',
  "WE Are Sorry You Don't Have The Permisssion to see this Form": 'نأسف لعدم الحصول على إذن لرؤية هذا النموذج',
  "We're sorry, you don't have permission": 'نحن آسفون، ليس لديك صلاحيه',
  Read: 'رؤية',
  Create: 'إنشاء',
  'Form Id': 'ID النموذج',
  'Edit FormRoles': 'تحرير صلاحيات النموذج',
  'Delete FormRole': 'حذف صلاحيات النموذج',
  'Are you sure you want to delete this FormRoles? All the data will be permanently removed from our servers forever. This action cannot be undone':
    'هل أنت متأكد أنك تريد حذف صلاحيات النموذج هذا؟سيتم إزالتها بشكل دائم من خوادمنا إلى الأبد.لا يمكن التراجع عن هذا الإجراء',
  'The FormRole has been modified successfully': 'تم تعديل صلاحيات النموذج بنجاح',
  'The FormRole has been deleted successfully': 'تم حذف صلاحيات النموذج بنجاح',
};
