import React from 'react';

import { useEffect, useState } from 'react';
import './App.css';
import Login from './Login';
import Layout from './Layout';
import useToken from './useToken';
import { BrowserRouter } from 'react-router-dom';
import './Api/AxioxConfig'; // this line for axios interceptors
import { useQuery, useQueryClient } from 'react-query';
import { getCurrentUser } from './Api/Authenticate';
import Loading from './SharedComponents/Loading';
import { useTranslation } from 'react-i18next';
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import { fetchToken, onMessageListener } from './firebaseConfig';
import Notification from './SharedComponents/Notification';

function App() {
  const { setToken } = useToken();
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [isTokenFound, setTokenFound] = useState(false);
  const [firebaseToken, setFirebaseToken] = useState('');
  fetchToken(setTokenFound, setFirebaseToken);
  const queryClient = useQueryClient();
  onMessageListener()
    .then((payload) => {
      setNotification({ title: payload.notification.title, body: payload.notification.body });
      setShow(true);
      const refetchQueries = async () => {
        await queryClient.invalidateQueries('ConsultingSpecialist');
        await queryClient.invalidateQueries('notificationsCounter');
      };
      refetchQueries();

      console.log('payload', payload);
    })
    .catch((err) => console.log('failed: ', err));

  const onShowNotificationClicked = () => {
    setNotification({ title: 'Notification', body: 'This is a test notification' });
    setShow(true);
  };
  const { data, isLoading, isError, error } = useQuery(['currentUser'], getCurrentUser, {
    retry: false,
    refetchOnMount: true,
  });

  const { i18n, t } = useTranslation();

  useEffect(() => {
    document.dir = i18n.dir();
  }, [i18n, i18n.language]);

  if (isLoading) return <Loading />;
  if (isError) return <Login firebaseToken={firebaseToken} setToken={setToken} />;
  if (!localStorage.getItem('token')) return <Login setToken={setToken} />;

  return (
    <>
      {data && (
        <BrowserRouter>
          <Toaster />
          <Layout />
        </BrowserRouter>
      )}
    </>
  );
}

export default App;
