import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import BreadCrumbs from '../../../SharedComponents/BreadCrumbs';
import LanguageList from '../../../SharedComponents/LanguageList';
import Loading from '../../../SharedComponents/Loading';
import PaperClip from '../../../SharedComponents/Svg/PaperClip';
import { getLanguages } from '../ApiForms';
import { GetFormAnswer, getFormQuestions } from './ApiFormAnswer';
import UserDetails from './UserDetails';
export default function SingleFormAnswer() {
  const { formId, submitId } = useParams();

  const [languageId, setLanguageId] = useState(1);
  const { t, i18n } = useTranslation();
  const { data, isLoading, isError } = useQuery(['formAnswer', formId], GetFormAnswer);

  const body = {
    formId: formId,
    paginationFilter: {
      pageNumber: 0,
      pageSize: 0,
      numberOfPages: 0,
      isPaging: false,
      isFilter: false,
      langId: 0,
      isSearch: false,
      newsnumber: 0,
      parentId: 0,
    },
  };

  const formQuestions = useQuery(['formQuestions', body], getFormQuestions);

  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  useEffect(() => {
    setLanguageId(languages.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [languages.data]);

  if (isLoading || formQuestions.isLoading) return <Loading />;
  if (isError || formQuestions.isError) return 'error';
  const tabltHeader = [
    ...formQuestions.data.data,

    {
      id: 'date',
      frmQuestionLngs: [
        {
          description: '',
          frmQuestionId: 1017,
          id: 0,
          languageId: 1,
          title: 'التاريخ',
          valMessage: null,
        },
        {
          title: 'Date',
          frmQuestionId: 1017,
          id: 0,
          languageId: 2,
          valMessage: null,
        },
        {
          description: '',
          frmQuestionId: 1017,
          id: 0,
          languageId: 3,
          title: '',
          valMessage: null,
        },
      ],
    },
    {
      id: 0,
      frmQuestionLngs: [
        {
          description: '',
          frmQuestionId: 1017,
          id: 0,
          languageId: 1,
          title: 'بيانات المستخدم',
          valMessage: null,
        },
        {
          title: 'User Details',
          frmQuestionId: 1017,
          id: 0,
          languageId: 2,
          valMessage: null,
        },
        {
          description: '',
          frmQuestionId: 1017,
          id: 0,
          languageId: 3,
          title: '',
          valMessage: null,
        },
      ],
    },
  ];
  const uniqBySubmitId = (data, key) => {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  };
  return (
    <>
      <header className="flex justify-between items-center">
        <BreadCrumbs
          to="/forms"
          name={t('Forms')}
          morePages={[
            { name: data?.data[0]?.formName, to: `/formAnswer/${formId}` },
            {
              name: submitId,
              to: `/formAnswer/${formId}/${submitId}`,
            },
          ]}
          backTo={`/formAnswer/${formId}`}
        />
      </header>
      <div className="my-6">
        <h2 className="text-2xl font-bold">{data?.data[0]?.formName}</h2>
      </div>

      {/* Table */}
      <div className="bg-white rounded border shadow-md ">
        <div className="flex justify-between relative items-center mb-4 p-6">
          <div className="flex transform  relative z-10 ">
            <LanguageList languages={languages.data?.data} languageId={languageId} setLanguageId={setLanguageId} />
          </div>
          <hr className="border w-full absolute top-0 left-0 z-0 mt-16" />
        </div>

        <div className="flex flex-col overflow-hidden">
          <div className="-my-2 overflow-hidden sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className=" overflow-hidden sm:rounded-lg">
                {uniqBySubmitId(data.data, (it) => it.submitId)
                  .filter((e) => e.submitId === +submitId)
                  .map((item, index) => (
                    <div key={index}>
                      {tabltHeader.map((th, index) => (
                        <div
                          key={th.id}
                          className={`px-6 py-4 whitespace-nowrap flex ${
                            index + 1 !== tabltHeader.length ? 'border-b border-[#e6e7e9]' : ''
                          } `}
                        >
                          {th.id !== item.userId && (
                            <h2
                              className={`${
                                i18n.language === 'en' ? 'pr-10' : 'pl-10'
                              } w-1/4 font-bold text-black uppercase text-sm`}
                            >
                              {th.frmQuestionLngs.filter((e) => e.languageId === languageId)[0].title}
                            </h2>
                          )}
                          <div className="flex text-[#999999] text-sm font-semibold text-gray-700">
                            {th.id === 0 ? (
                              item.userId !== 0 && <UserDetails userId={item.userId} />
                            ) : th.id === 'date' ? (
                              DateTime.fromISO(item?.createDate).toISODate()
                            ) : (
                              <>
                                {item?.answer?.filter((e) => e.questionId === th.id)[0]?.question.qtypeId === 4 ? (
                                  <a
                                    href={`${window.env.REACT_APP_IMAGE_PATH}${
                                      item?.answer?.filter((e) => e.questionId === th.id)[0]?.answer
                                    }`}
                                    download={item?.answer?.filter((e) => e.questionId === th.id)[0]?.answer}
                                    target="_blank"
                                    className="bg-green-100 text-green-800 hover:bg-green-200 transition duration-500 text-white rounded p-1"
                                  >
                                    <PaperClip className="w-5 h-5" />
                                  </a>
                                ) : (
                                  item?.answer?.filter((e) => e.questionId === th.id)[0]?.answer
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
