import LoadingSpinner from './LoadingSpinner';
/**
 *
 *
 * @export
 * @param {*} { type , text , loading , className, onClick,noText }
 * @returns
 */
export default function Button(props) {
  const type = props.type ? props.type : 'button';
  const text = props.text ? props.text : 'حفظ';
  const loading = props.loading ? props.loading : false;
  const className = props.className ? props.className : '';
  const icon = props.icon;
  const noText = props.noText;
  const disabled = props.disabled ? true : false;

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
      className={` flex items-center w-full justify-center text-white py-2 space-x-2 space-x-reverse relative rounded bg-blue-500 hover:bg-blue-600 transition-all ease-in-out duration-300 ${className} ${
        disabled ? 'bg-gray-300 text-gray-500 hover:bg-gray-400 cursor-not-allowed' : ''
      }`}
    >
      {icon}
      {!noText && <span className=" whitespace-nowrap mr-2">{text}</span>}
      {loading && <LoadingSpinner className={''} />}
    </button>
  );
}
