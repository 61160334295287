import { useState } from 'react';
import ChevronRight from '../../../../SharedComponents/Svg/ChevronRight';
import AddCategoryAgenda from './AddCategoryAgenda';
import CategoryAgenda from './CategoryAgenda';
import DeleteAgendaParent from './DeleteAgendaParent';

export default function CategoryAgenaParent(props) {
  const { initValues, languageId, languages } = props;
  const [isOpen, setIsOpen] = useState(false);
  console.log('initValues', initValues);
  return (
    <div className="border mb-4 ">
      <div className=" py-2 px-4 flex justify-between items-center shadow relative ">
        <div className="flex items-center space-x-2 ">
          <button
            type="button"
            className="focus:outline-none "
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <ChevronRight
              className={`w-4 h-4 transform ${isOpen ? 'rotate-90' : 'rotate-0'}  transition-all duration-500`}
            />
          </button>
          <span>
            {initValues?.pgSectionCategoryAgendaParentLngs?.filter((e) => e.languageId === languageId)[0].title}
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <AddCategoryAgenda
            languageId={languageId}
            languages={languages}
            pgSectionCategoryAgendaParentId={initValues.id} 
            pgSectionCategoryId={initValues.pgSectionCategoryId}
          />
          <DeleteAgendaParent id={initValues.id} />
        </div>
      </div>
      <div className={`${isOpen ? '' : 'hidden'}`}>
        {initValues.pgSectionCategoryAgendas?.map((item, index) => {
          console.log('item', item);
          return <CategoryAgenda languages={languages} languageId={languageId} initValues={item} key={index} />;
        })}
      </div>
    </div>
  );
}
