import axios from 'axios';
import { Fragment } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import OutsideClickHandler from '../../../../SharedComponents/OutsideClickHandler';

export default function InstructorsList(props) {
  //TODO
  //   Error while searching by name
  const { languageId, setFieldValue, instructorId } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [searchParam, setSearchParam] = useState('');
  const { t } = useTranslation();
  const onSearchParam = (e) => {
    setSearchParam(e.target.value);
  };
  const PagesinitialData = {
    pageNumber: 1,
    pageSize: 1,
    isPaging: false,
    langId: languageId,
  };
  // Query Data To Fetch Languages
  const { data, isLoading, isError } = useQuery(['Instructors', PagesinitialData], getInstructors, {
    staleTime: 360000000,
  });
  if (isLoading) return '';
  if (isError) return 'error';
  console.log('data', data);
  const onClick = (data) => {
    setFieldValue('instructorId', data.id);
    setIsOpen(false);
  };
  let defaultValue = data.data.find((e) => e.id === instructorId);
  return (
    <div className="relative mb-4">
      <label>
        <span>{t('Instructor')}</span>
        <input
          onFocus={() => {
            setIsOpen(true);
          }}
          onChange={onSearchParam}
          className="border rounded  w-full mb-0 p-1"
          value={defaultValue?.instructorLngs[0].name}
        />
      </label>

      {isOpen && (
        <OutsideClickHandler setIsOpen={setIsOpen}>
          <div className="absolute bottom-0 z-50 left-0 bg-white w-full transform translate-y-full pl-0 block ">
            <ul className="border rounded divide-y overflow-y-scroll max-h-64 mt-2">
              {data.data
                .filter((e) => e.instructorLngs[0]?.name.toLowerCase().includes(searchParam.toLowerCase()))
                .map((e) => {
                  return (
                    <Fragment key={e.id}>
                      <button
                        onClick={(x) => {
                          onClick(e);
                        }}
                        type="button"
                        className={`${
                          instructorId === e.id ? 'bg-darkbluehover text-white' : ''
                        } w-full text-left block px-2 py-1 hover:bg-darkbluehover hover:text-white`}
                      >
                        {e.instructorLngs.map((x) => {
                          return <>{languageId === x.languageId && x.name}</>;
                        })}
                      </button>
                    </Fragment>
                  );
                })}
            </ul>
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
}

export const getInstructors = async (e) => {
  const query = await axios.post('/InstractorMobile/GetAll', e.queryKey[1]);
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};
